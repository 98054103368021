<template>
    <div>
        <v-menu offset-y left>
            <template v-slot:activator="{ on }">
                <v-btn icon v-on="on">
                    <v-avatar size="28" class="">
                        <img alt="gravatar-user-icon" :src="userIcon" />
                    </v-avatar>
                </v-btn>
            </template>
            <v-card>
                <v-list id="user-menu" expand>
                    <v-list-item :three-line="authUser.getBusinessEntity() != null" :two-line="authUser.getBusinessEntity() == null">
                        <v-list-item-avatar>
                            <img alt="gravatar-user-icon" :src="userIcon" />
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title>{{ authUser.email }}</v-list-item-title>
                            <v-list-item-subtitle class="grey--text">{{ authUser.getDisplayName() }}</v-list-item-subtitle>
                            <v-list-item-subtitle v-if="authUser.getBusinessEntity()" class="grey--text">{{
                                authUser.getBusinessEntity().name
                            }}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>

                    <v-divider class="mb-5"></v-divider>

                    <v-list-item @click="darkMode = !darkMode">
                        <v-list-item-avatar width="50px">
                            <v-switch v-model="darkMode" :ripple="false" @click="darkMode = !darkMode"></v-switch>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title class="mb-1"
                                ><v-icon v-if="darkMode === false" class="mr-2" small>$vuetify.icons.sun</v-icon>
                                <v-icon v-if="darkMode" class="mr-2" small>$vuetify.icons.moon</v-icon
                                >{{ darkMode ? 'Dark Theme' : 'Light Theme' }}</v-list-item-title
                            >
                            <v-list-item-subtitle class="grey--text">Change the current app theme</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item to="/users/profile#tab-general" :nuxt="true" :class="/^\/users\/.*$/g.test($route.path) ? 'v-list-item--active' : ''">
                        <v-list-item-action>
                            <v-icon small class="mr-2">$vuetify.icons.idCard</v-icon>
                        </v-list-item-action>
                        <v-list-item-title>Profile</v-list-item-title>
                    </v-list-item>
                    <v-divider class="mt-3 elevation-1"></v-divider>
                    <v-list-item class="logout" @click="logout()">
                        <v-list-item-action>
                            <v-icon small class="mr-2">$vuetify.icons.rightFromBracket</v-icon>
                        </v-list-item-action>
                        <v-list-item-title>Logout</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-card>
        </v-menu>
    </div>
</template>

<script>
import rbacMeta from '@/mixins/rbac-meta';
import Gravatar from '@/helpers/gravatar';

export default {
    name: 'UserMenu',
    mixins: [rbacMeta],
    computed: {
        userIcon() {
            // get a Gravatar icon, if not found will return blank image
            const gravatar = new Gravatar(this.authUser.email);

            return gravatar.getImageUrl();
        },
        darkMode: {
            get() {
                return this.$store.state.darkMode;
            },
            set(value) {
                this.$store.commit('setDarkMode', value);
            },
        },
    },
    watch: {
        darkMode(value) {
            setTimeout(() => (this.$vuetify.theme.dark = value), 0);
        },
    },
    methods: {
        async logout() {
            await this.$router.push('/logout');
        },
    },
};
</script>

<style scoped lang="scss">
#user-menu {
    padding-bottom: 0 !important;
}

.logout {
    background-color: #9ca6bd !important;
    color: whitesmoke !important;

    * {
        background-color: #9ca6bd !important;
        color: whitesmoke !important;
    }
}
</style>
