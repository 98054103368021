import checkTypes from 'check-types';
import MapSelection from '@/helpers/map-selection';
import newMapInstance from '@/helpers/new-map-instance';

export const state = () => ({
    activeSelection: null,
    selections: new Map(),
});

export const mutations = {
    clear(state) {
        state.activeSelection = null;
        state.selections = new Map();
    },
    /**
     * @param {Object} state
     * @param {MapSelection|null} value
     */
    setActiveSelection(state, value) {
        if (value != null) checkTypes.assert.nonEmptyObject(value);
        state.activeSelection = value;
    },
    /**
     * @param {Object} state
     * @param {MapSelection} value
     */
    upsertSelection(state, value) {
        checkTypes.assert.instance(value, MapSelection);
        // upsert selection
        state.selections.set(value.getId(), value);
        // create a new map and set new property value to trigger state update
        state.selections = newMapInstance(state.selections);
    },
    /**
     * @param {Object} state
     * @param {MapSelection} value
     */
    deleteSelection(state, value) {
        checkTypes.assert.nonEmptyObject(value);
        state.selections.delete(value.getId());
        // create a new map and set new property value to trigger state update
        state.selections = newMapInstance(state.selections);
    },

    /**
     * Updates properties of an existing selected feature. All selections are searched for the update candidate.
     * If no feature is found, the method does nothing.
     *
     * @param {Object} state
     * @param {Point} updatedFeature
     */
    updateFeature(state, updatedFeature) {
        checkTypes.assert.nonEmptyObject(value);
        let updateSelections = [];
        // get all selection that include the updated feature
        for (const selection of state.selections.values()) {
            if (selection.hasFeature(updatedFeature)) updateSelections.push(selection);
        }
        // update feature properties in all selections
        for (const [key, selection] of updateSelections.entries()) {
            state.selections.set(key, selection.updateSelectedFeature(updatedFeature));
        }
        // create a new map and set new property value to trigger state update
        state.selections = newMapInstance(state.selections);
    },
};
