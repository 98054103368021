import UserRolesMetadataLoader from '@/helpers/user-roles-metadata-loader';
import checkTypes from 'check-types';
import { LIST_ITEMS_PER_PAGE_DEFAULT } from '@/globals';
import cleanDeep from 'clean-deep';
import * as valueTransform from '@/helpers/value-transform';

export const state = () => ({
    choices: {
        businessEntities: [],
        roles: [],
        permissions: [],
    },
    items: new Map(),
    page: 1,
    totalItems: 0,
    limit: LIST_ITEMS_PER_PAGE_DEFAULT,
    sortBy: null,
    sortDesc: null,
    filterSearch: null,
    filterSearchOptLwc: false,
    filterBusinessEntity: null,
    filterRoles: [],
    filterPermissions: [],
    filterBypassResourceAccessChecks: false,
    filterServiceAccount: false,
    filterOrphaned: false,
    filterDisabled: false,
});

export const getters = {
    getItems: (state) => {
        // IMPORTANT make sure to return an array calling values which will only return list of values and not a "keyed valued"
        return Array.from(state.items.values());
    },
    getQueryFilters: (state) => {
        return {
            page: state.page,
            limit: state.limit,
            sortBy: state.sortBy,
            sortDesc: state.sortDesc,
            search: state.filterSearch,
            searchOptLwc: state.filterSearchOptLwc,
            businessEntity: state.filterBusinessEntity,
            roles: state.filterRoles,
            permissions: state.filterPermissions,
            bypassResourceAccessChecks: state.filterBypassResourceAccessChecks,
            serviceAccount: state.filterServiceAccount,
            orphaned: state.filterOrphaned,
            disabled: state.filterDisabled,
        };
    },
    hasSpecialFilters: (state) => {
        return (
            Object.values(
                cleanDeep({
                    businessEntity: state.filterBusinessEntity,
                    roles: state.filterRoles,
                    permissions: state.filterPermissions,
                    bypassResourceAccessChecks: state.filterBypassResourceAccessChecks || undefined,
                    serviceAccount: state.filterServiceAccount || undefined,
                    orphaned: state.filterOrphaned || undefined,
                    disabled: state.filterDisabled || undefined,
                })
            ).length > 0
        );
    },
};

export const mutations = {
    clearSpecialFilters(state) {
        state.filterBusinessEntity = null;
        state.filterRoles = [];
        state.filterPermissions = [];
        state.filterBypassResourceAccessChecks = false;
        state.filterServiceAccount = false;
        state.filterOrphaned = false;
        state.filterDisabled = false;
    },
    clear(state) {
        state.choices = {
            businessEntities: [],
            roles: [],
            permissions: [],
        };
        state.items = new Map();
        state.page = 1;
        state.limit = LIST_ITEMS_PER_PAGE_DEFAULT;
        state.totalItems = 0;
        state.sortBy = null;
        state.sortDesc = null;
        state.filterSearch = null;
        state.filterSearchOptLwc = null;
        state.filterBusinessEntity = null;
        state.filterRoles = [];
        state.filterPermissions = [];
        state.filterBypassResourceAccessChecks = false;
        state.filterServiceAccount = false;
        state.filterOrphaned = false;
        state.filterDisabled = false;
    },
    setChoices(state, value) {
        checkTypes.assert.object(value);
        state.choices = value;
    },
    updateChoices(state, value) {
        checkTypes.assert.object(value);
        state.choices = { ...state.choices, ...value };
    },
    setPage(state, value) {
        state.page = valueTransform.toInteger(value, { nullable: false });
    },
    setLimit(state, value) {
        state.limit = valueTransform.toInteger(value, { nullable: false });
    },
    setSortBy(state, value) {
        state.sortBy = valueTransform.toString(value);
    },
    setSortDesc(state, value) {
        state.sortDesc = valueTransform.toBoolean(value, { nullable: true });
    },
    setFilterSearch(state, value) {
        state.filterSearch = valueTransform.toString(value);
    },
    setFilterSearchOptLwc(state, value) {
        state.filterSearchOptLwc = valueTransform.toBoolean(value, { nullable: true });
    },
    setFilterBusinessEntity(state, value) {
        state.filterBusinessEntity = valueTransform.toString(value);
    },
    setFilterRoles(state, value) {
        if (value != null) checkTypes.assert.array.of.nonEmptyString(value);
        state.filterRoles = value;
    },
    setFilterPermissions(state, value) {
        if (value != null) checkTypes.assert.array.of.nonEmptyString(value);
        state.filterPermissions = value;
    },
    setFilterBypassResourceAccessChecks(state, value) {
        state.filterBypassResourceAccessChecks = valueTransform.toBoolean(value, { nullable: true });
    },
    setFilterServiceAccount(state, value) {
        state.filterServiceAccount = valueTransform.toBoolean(value, { nullable: true });
    },
    setFilterDisabled(state, value) {
        state.filterDisabled = valueTransform.toBoolean(value, { nullable: true });
    },
    setFilterOrphaned(state, value) {
        state.filterOrphaned = valueTransform.toBoolean(value, { nullable: true });
    },
    setTotalItems(state, value) {
        state.totalItems = valueTransform.toInteger(value, { nullable: false });
    },
    setItems(state, value) {
        value = value || [];
        state.items = new Map();
        value.forEach(function (item) {
            state.items.set(item.uid, item);
        });
        // IMPORTANT change state by setting new Map since simply calling "set" directly won't propagate state change
        state.items = new Map(state.items.entries());
    },
    removeItem(state, value) {
        checkTypes.assert.nonEmptyString(value);
        state.items.delete(value);
        // IMPORTANT change state by setting new Map since simply calling "set" directly won't propagate state change
        state.items = new Map(state.items.entries());
    },
};

export const actions = {
    async load({ commit, state }) {
        const rolesMetadataLoader = new UserRolesMetadataLoader(this.$axios);

        const { items, totalCount } = await this.$axios.$get('/api/iam/users', {
            params: {
                page: state.page,
                limit: state.limit,
                sortBy: state.sortBy,
                sortDesc: state.sortDesc,
                search: state.filterSearch,
                searchOptLwc: state.filterSearchOptLwc,
                businessEntity: state.filterBusinessEntity,
                roles: state.filterRoles,
                permissions: state.filterPermissions,
                serviceAccount: state.filterServiceAccount,
                bypassResourceAccessChecks: state.filterBypassResourceAccessChecks,
                orphaned: state.filterOrphaned,
                disabled: state.filterDisabled,
            },
        });

        // replace user's list of roles with roles metadata for more in-depth info on the roles
        const preparedItems = [];
        for (const item of items) {
            const roleMetadata = [];
            // handle users with no assigned roles
            if (item?.roles == null) continue;
            // process user roles
            for (const role of item.roles) {
                const roleMeta = await rolesMetadataLoader.getUserRoleMetadata(role);
                roleMetadata.push(roleMeta);
            }
            item.roles = cleanDeep(roleMetadata);
            preparedItems.push(item);
        }

        commit('setTotalItems', totalCount);
        commit('setItems', preparedItems);
    },
    async delete({ state }, id) {
        checkTypes.assert.nonEmptyString(id);
        await this.$axios.$delete(`/api/iam/users/${id}`);
    },
};
