import { LIST_ITEMS_PER_PAGE_DEFAULT } from '@/globals';
import checkTypes from 'check-types';
import cleanDeep from 'clean-deep';
import * as valueTransform from '@/helpers/value-transform';
import { TypeEnum } from '@/helpers/choices/atm/type-enum';

const choices = {
    type: TypeEnum.getFormChoices(),
};

export const state = () => ({
    choices,
    items: new Map(),
    page: 1,
    totalItems: 0,
    limit: LIST_ITEMS_PER_PAGE_DEFAULT,
    sortBy: null,
    sortDesc: null,
    filterSearch: null,
    filterSearchOptLwc: false,
    filterType: null,
});

export const getters = {
    getItems: (state) => {
        // IMPORTANT make sure to return an array calling values which will only return list of values and not a "keyed valued"
        return Array.from(state.items.values());
    },
    getQueryFilters: (state) => {
        return {
            page: state.page,
            limit: state.limit,
            sortBy: state.sortBy,
            sortDesc: state.sortDesc,
            search: state.filterSearch,
            searchOptLwc: state.filterSearchOptLwc,
            type: state.filterType,
        };
    },
    hasSpecialFilters: (state) => {
        return (
            Object.values(
                cleanDeep({
                    type: state.filterType,
                })
            ).length > 0
        );
    },
};

export const mutations = {
    clearSpecialFilters(state) {
        state.filterType = null;
    },
    clear(state) {
        state.choices = choices;
        state.items = new Map();
        state.page = 1;
        state.limit = LIST_ITEMS_PER_PAGE_DEFAULT;
        state.totalItems = 0;
        state.sortBy = null;
        state.sortDesc = null;
        state.filterSearch = null;
        state.filterSearchOptLwc = null;
        state.filterType = null;
    },
    setChoices(state, value) {
        checkTypes.assert.object(value);
        state.choices = value;
    },
    updateChoices(state, value) {
        checkTypes.assert.object(value);
        state.choices = { ...state.choices, ...value };
    },
    setPage(state, value) {
        state.page = valueTransform.toInteger(value, { nullable: false });
    },
    setLimit(state, value) {
        state.limit = valueTransform.toInteger(value, { nullable: false });
    },
    setSortBy(state, value) {
        state.sortBy = valueTransform.toString(value);
    },
    setSortDesc(state, value) {
        state.sortDesc = valueTransform.toBoolean(value, { nullable: true });
    },
    setFilterSearch(state, value) {
        state.filterSearch = valueTransform.toString(value);
    },
    setFilterSearchOptLwc(state, value) {
        state.filterSearchOptLwc = valueTransform.toBoolean(value, { nullable: true });
    },
    setFilterType(state, value) {
        if (value != null) checkTypes.assert.array.of.nonEmptyString(value);
        state.filterType = value;
    },
    setTotalItems(state, value) {
        state.totalItems = valueTransform.toInteger(value, { nullable: false });
    },
    setItems(state, value) {
        value = value || [];
        state.items = new Map();
        value.forEach(function (item) {
            state.items.set(item._id, item);
        });
        // IMPORTANT change state by setting new Map since simply calling "set" directly won't propagate state change
        state.items = new Map(state.items.entries());
    },
    removeItem(state, value) {
        checkTypes.assert.nonEmptyString(value);
        state.items.delete(value);
        // IMPORTANT change state by setting new Map since simply calling "set" directly won't propagate state change
        state.items = new Map(state.items.entries());
    },
};

export const actions = {
    async load({ commit, state }) {
        // append query parameters defined by selected filter values
        const { items, totalCount } = await this.$axios.$get('/api/operating/atm', {
            params: {
                page: state.page,
                limit: state.limit,
                sortBy: state.sortBy,
                sortDesc: state.sortDesc,
                search: state.filterSearch,
                searchOptLwc: state.filterSearchOptLwc,
                type: state.filterType,
                fields: '_id,kind,readOnly,serialNumber,partNumber,buildDate',
            },
        });

        commit('setTotalItems', totalCount);
        commit('setItems', items);
    },
    async delete({ state }, id) {
        await this.$axios.$delete(`/api/operating/atm/${id}`);
    },
    async lock({ state }, ids) {
        return this.$axios.$post('/api/operating/atm/lock', ids);
    },
    async unlock({ state }, ids) {
        return this.$axios.$post('/api/operating/atm/unlock', ids);
    },
};
