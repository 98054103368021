<template>
    <v-footer id="footer" height="auto">
        <v-row justify="center">
            <v-col class="text-center" cols="auto">
                &copy; {{ new Date().getFullYear() }} —
                <strong>
                    <a href="https://www.lanthan-safe-sky.com/" title="Lanthan Safe Sky GmbH">Lanthan Safe Sky GmbH</a>
                </strong>
            </v-col>
            <v-spacer />
            <v-col cols="auto" class="app-version">v{{ $config.APP_VERSION }}</v-col>
        </v-row>
    </v-footer>
</template>

<script>
export default {
    name: 'PageFooter',
};
</script>

<style scoped lang="scss">
@import 'assets/scss/_variables';

#footer {
    margin-top: 20em;
    a {
        text-decoration: none;
        font-weight: lighter;

        &:hover {
            text-decoration: underline;
        }
    }
    .app-version {
        font-size: 0.9em;
        color: $subtle-gray;
    }
}
</style>
