import { Enum } from '@/helpers/choices/enum';

export class LightingSystemInterfaceConceptEnum extends Enum {}

LightingSystemInterfaceConceptEnum.values = {
    ENERCON_SCADA: 0,
    ENERCON_DIGITAL_IO: 1,
    REETEC_MODBUS_TCP_DECENTRAL_ATS: 2,
    REETEC_MODBUS_TCP_CENTRAL_ATS: 3,
    ALI_PRO_CANDELA_DECENTRAL_ATS: 4,
    ALI_PRO_CANDELA_CENTRAL_ATS: 5,
    QUANTEC_SIGNALS_QLS_MODBUS_TCP_DECENTRAL_ATS: 6,
    QUANTEC_SIGNALS_QLS_MODBUS_TCP_CENTRAL_ATS: 7,
    QUANTEC_SIGNALS_QRT_MODBUS_TCP_CENTRAL_ATS: 8,
    QUANTEC_SIGNALS_QLS_DIGITAL_IO: 9,
    QUANTEC_SIGNALS_QRT_DIGITAL_IO: 10,
    ORGA_CIP400_DECENTRAL_ATS: 11,
    ORGA_CIP400_CENTRAL_ATS: 12,
    ORGA_AEI402_DECENTRAL_ATS: 13,
    ORGA_AEI402_CENTRAL_ATS: 14,
    VESTAS_3RD_PARTY_ADLS_MODBUS_TCP: 15,
    NORDEX_LCU: 16,
    VESTAS_LCU: 17,
    SABIK_PMS: 18,
    LANTHAN_OLS2000_MODBUS_TCP_PROCESSING_INTERFACE: 19,
    LANTHAN_OLS2000_DIGITAL_IO_PROCESSING_INTERFACE: 20,
    LANTHAN_OLS2000_MODBUS_TCP_TPLC_PROCESSING_INTERFACE: 21,
    LANTHAN_OLS2000_DIGITAL_IO_TPLC_PROCESSING_INTERFACE: 22,
    LANTHAN_OLS2000_MODBUS_TCP_DECENTRAL_ATS: 23,
    LANTHAN_OLS2000_MODBUS_TCP_CENTRAL_ATS: 24,
    LANTHAN_OLS2000_DIGITAL_IO_DECENTRAL_ATS: 25,
    LANTHAN_OLS2000_MODBUS_TCP_TPLC_DECENTRAL_ATS: 26,
    LANTHAN_OLS2000_MODBUS_TCP_TPLC_CENTRAL_ATS: 27,
    LANTHAN_OLS2000_DIGITAL_IO_TPLC_DECENTRAL_ATS: 28,
    DWT_BNK_BOX_PROCESSING_INTERFACE: 29,
    DWT_BNK_BOX_DECENTRAL_ATS: 30,
    DWT_BNK_BOX_CENTRAL_ATS: 31,
    OBELUX_COMV3_MODBUS_TCP_DECENTRAL_ATS: 32,
    OBELUX_COMV3_MODBUS_TCP_CENTRAL_ATS: 33,
    ATS_MODBUS_TCP_SERVER_WITH_LIGHTING_SYSTEM_FEEBDACK: 34,
    ATS_MODBUS_TCP_SERVER_WITHOUT_LIGHTING_SYSTEM_FEEBDACK: 35,
};

LightingSystemInterfaceConceptEnum.names = {
    0: '1 - ENERCON SCADA',
    1: '2 - ENERCON DIGITAL IO',
    2: '3.A - REETEC Modbus/TCP, decentral ATS',
    3: '3.B - REETEC Modbus/TCP, central ATS',
    4: '4.A - ALI proCandela, decentral ATS',
    5: '4.B - ALI proCandela, central ATS',
    6: '5.A - Quantec Signals QLS Modbus/TCP, decentral ATS',
    7: '5.B - Quantec Signals QLS Modbus/TCP, central ATS',
    8: '6 - Quantec Signals QRT Modbus/TCP, central ATS',
    9: '7 - Quantec Signals QLS Digital I/O',
    10: '8 - Quantec Signals QRT Digital I/O',
    11: '9.A - ORGA CIP400, decentral ATS',
    12: '9.B - ORGA CIP400, central ATS',
    13: '10.A - ORGA AEI402, decentral ATS',
    14: '10.B - ORGA AEI402, central ATS',
    15: '11 - Vestas 3rd Party ADLS Modbus/TCP',
    16: '12 - Nordex LCU',
    17: '13 - Vestas LCU',
    18: '14 - SABIK PMS',
    19: '15.A - LANTHAN OLS2000 Modbus/TCP Interface, processing interface',
    20: '15.B - LANTHAN OLS2000 DIGITAL IO, processing interface',
    21: '15.C - LANTHAN OLS2000 Modbus/TCP Interface with 3rd party LC, processing interface',
    22: '15.D - LANTHAN OLS2000 DIGITAL IO with 3rd party LC, processing interface',
    23: '16.A - LANTHAN OLS2000 Modbus/TCP Interface, decentral ATS',
    24: '16.B - LANTHAN OLS2000 Modbus/TCP Interface, central ATS',
    25: '16.C - LANTHAN OLS2000 DIGITAL IO, decentral ATS',
    26: '16.D - LANTHAN OLS2000 Modbus/TCP Interface with 3rd party LC, decentral ATS',
    27: '16.E - LANTHAN OLS2000 Modbus/TCP Interface with 3rd party LC, central ATS',
    28: '16.F - LANTHAN OLS2000 DIGITAL IO with 3rd party LC, decentral ATS',
    29: '17 - DWT BNK-BOX, processing interface',
    30: '18.A - DWT BNK-BOX, decentral ATS',
    31: '18.B - DWT BNK-BOX, central ATS',
    32: '19.A - OBELUX COMv3 Modbus/TCP Interface, decentral ATS',
    33: '19.B - OBELUX COMv3 Modbus/TCP Interface, central ATS',
    34: '20.A - ATS Modbus/TCP Server with lighting system feedback',
    35: '20.B - ATS Modbus/TCP Server without lighting system feedback',
};

Object.freeze(LightingSystemInterfaceConceptEnum);
