import * as valueTransform from '@/helpers/value-transform';
import checkTypes from 'check-types';

export const state = () => ({
    choices: [],
    filterType: false,
    search: null,
    selected: null,
    showOnlyFiltered: false,
});

export const mutations = {
    clear(state) {
        state.choices = [];
        state.filterType = null;
        state.search = null;
        state.selected = null;
        state.showOnlyFiltered = false;
    },
    setChoices(state, value) {
        checkTypes.assert.array(value);
        state.choices = value;
    },
    setSearch(state, value) {
        state.search = valueTransform.toString(value, { nullable: true });
    },
    setSelected(state, value) {
        state.selected = valueTransform.toStringList(value, { nullable: true });
    },
    setFilterType(state, value) {
        state.filterType = valueTransform.toString(value, { nullable: true });
    },
    setShowOnlyFiltered(state, value) {
        state.showOnlyFiltered = valueTransform.toBoolean(value);
    },
};
