import { Enum } from '@/helpers/choices/enum';

export class TypeEnum extends Enum {}

TypeEnum.values = {
    ATS_3: 'ats3',
    ATS4: 'ats4',
};

TypeEnum.names = {
    ats3: 'ATS-3',
    ats4: 'ATS-4',
};

Object.freeze(TypeEnum);
