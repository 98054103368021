import { Enum } from '@/helpers/choices/enum';

export class StateEnum extends Enum {}

StateEnum.values = {
    CREATED: 'CREATED',
    PENDING: 'PENDING',
    STARTED: 'STARTED',
    CANCELED: 'CANCELED',
    INSTALLED: 'INSTALLED',
    TEST: 'TEST',
    PRODUCTION: 'PRODUCTION',
};

StateEnum.names = {
    CREATED: 'created',
    PENDING: 'pending',
    STARTED: 'started',
    CANCELED: 'canceled',
    INSTALLED: 'installed',
    TEST: 'test',
    PRODUCTION: 'production',
};

Object.freeze(StateEnum);
