import checkTypes from 'check-types';
import cleanDeep from 'clean-deep';
import momentTimezone from 'moment-timezone';
import * as valueTransform from '@/helpers/value-transform';

const choices = { timezones: momentTimezone.tz.names() };

export const state = () => ({
    choices,
    id: null,
    date: null,
    timezone: momentTimezone.tz.guess(),
    description: null,
    executedBy: null,
});

export const mutations = {
    clear(state) {
        state.choices = choices;
        state.id = null;
        state.date = null;
        state.timezone = momentTimezone.tz.guess();
        state.description = null;
        state.executedBy = null;
    },
    setChoices(state, value) {
        state.choices = value;
    },
    setId(state, value) {
        state.id = valueTransform.toString(value, { nullable: false });
    },
    setDate(state, value) {
        state.date = valueTransform.toString(value);
    },
    setTimezone(state, value) {
        state.timezone = valueTransform.toString(value);
    },
    setDescription(state, value) {
        state.description = valueTransform.toString(value);
    },
    setExecutedBy(state, value) {
        state.executedBy = valueTransform.toString(value);
    },
};

export const actions = {
    async load({ commit }, { atsInstallationId, maintenanceActionId }) {
        checkTypes.assert.nonEmptyString(atsInstallationId);
        checkTypes.assert.nonEmptyString(maintenanceActionId);

        const { _id, date, timezone, description, executedBy } = await this.$axios.$get(
            `/api/operating/ats-installations/${atsInstallationId}/maintenance-actions/${maintenanceActionId}`
        );

        commit('setId', _id);
        commit('setDate', date);
        commit('setTimezone', timezone);
        commit('setDescription', description);
        commit('setExecutedBy', executedBy);
    },
    async new({ state }, atsInstallationId) {
        let denormalized = _denormalize({ state });
        await this.$axios.$post(`/api/operating/ats-installations/${atsInstallationId}/maintenance-actions`, cleanDeep(denormalized));
    },
    async edit({ state }, atsInstallationId) {
        const denormalized = _denormalize({ state });

        await this.$axios.$patch(`/api/operating/ats-installations/${atsInstallationId}/maintenance-actions/${state.id}`, denormalized);
    },
};

/**
 * @param {object} state
 * @returns {{}}
 * @private
 */
function _denormalize({ state }) {
    checkTypes.assert.object(state);

    return {
        date: state.date,
        timezone: state.timezone,
        description: state.description,
        executedBy: state.executedBy,
    };
}
