import checkTypes from 'check-types';

export const state = () => ({
    commissioningProcess: null,
});

export const mutations = {
    clear(state) {
        state.commissioningProcess = null;
    },
    setCommissioningProcess(state, value) {
        checkTypes.assert.nonEmptyObject(value);
        state.commissioningProcess = value;
    },
};

export const actions = {
    async load({ commit }, id) {
        checkTypes.assert.nonEmptyString(id);
        const commissioningProcess = await this.$axios.$get(`/api/operating/commissioning-processes/${id}`, {
            params: {
                project: id,
                fields: [
                    '_id',
                    'projectName',
                    'windParkName',
                    'atsInstallationName',
                    'atsSerialNumber',
                    'processor',
                    'issueUri',
                    'state',
                    'logs',
                    'createdAt',
                    'createdBy',
                ],
            },
        });
        commit('setCommissioningProcess', commissioningProcess);
    },
};
