import { ManufacturerEnum } from '@/helpers/choices/wind-turbine/manufacturer-enum';
import checkTypes from 'check-types';
import { point as createPoint, featureCollection as createFeatureCollection } from '@turf/turf';

export const state = () => ({
    accessToken: null,
    project: null,
    windTurbines: [],
    atsInstallations: [],
});

export const getters = {
    getFeatures: (state) => {
        const features = [];
        state.atsInstallations.forEach((value) => {
            const { _id, ats, trafficReceiver } = value;
            const { serialNumber, kind } = ats || {};

            features.push(
                createPoint(value.geometry.coordinates, {
                    id: _id,
                    kind,
                    type: 'atsInstallation',
                    atsSerialNumber: serialNumber,
                    trafficReceiver,
                })
            );
        });
        state.windTurbines.forEach((value) => {
            const { _id, manufacturer, serialNumber } = value;

            const name = `${ManufacturerEnum.getName(manufacturer)}-${serialNumber}`;

            features.push(
                createPoint(value.geometry.coordinates, {
                    id: _id,
                    type: 'windTurbine',
                    name,
                })
            );
        });

        return createFeatureCollection(features);
    },
};

export const mutations = {
    clear(state) {
        state.accessToken = null;
        state.project = null;
        state.windTurbines = [];
        state.atsInstallations = [];
    },
    setAccessToken(state, value) {
        if (value) checkTypes.assert.nonEmptyString(value);
        state.accessToken = value;
    },
    setProject(state, value) {
        if (value) checkTypes.assert.nonEmptyObject(value);
        state.project = value;
    },
    setWindTurbines(state, value) {
        checkTypes.assert.array.of.nonEmptyObject(value);
        state.windTurbines = value;
    },
    setAtsInstallations(state, value) {
        checkTypes.assert.array.of.nonEmptyObject(value);
        state.atsInstallations = value;
    },
};

export const actions = {
    async load({ state, commit }, { id, accessToken }) {
        checkTypes.assert.nonEmptyString(id);
        checkTypes.assert.nonEmptyString(accessToken);

        const projectMetadata = await this.$axios.$get(`/api/operating/projects/${id}/signed-access/project-information`, {
            headers: { authorization: `Bearer ${accessToken}` },
        });

        const { project, windTurbines } = projectMetadata;

        // extract ats installations from wind turbines
        const projectAtsInstallations = new Map();

        windTurbines.forEach((value) => {
            const { ctrlAtsInstallation, sensorAtsInstallation } = value;
            if (ctrlAtsInstallation) {
                projectAtsInstallations.set(ctrlAtsInstallation._id, ctrlAtsInstallation);
            }
            if (sensorAtsInstallation && sensorAtsInstallation?.length > 0) {
                for (const sensorAtsInst of sensorAtsInstallation) {
                    projectAtsInstallations.set(sensorAtsInst._id, sensorAtsInst);
                }
            }
        });

        commit('setProject', project);
        commit('setWindTurbines', windTurbines);
        commit('setAtsInstallations', Array.from(projectAtsInstallations.values()));
    },
};
