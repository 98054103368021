import checkTypes from 'check-types';
import cleanDeep from 'clean-deep';
import Merkator from 'openaip-merkator';
import { OperationModeEnum } from '@/helpers/choices/ats-installation/operation-mode-enum';
import * as valueTransform from '@/helpers/value-transform';
import { GENERIC_PROVIDER_PLACEHOLDER } from '@/globals';
import { MobileRouterEnum } from '@/helpers/choices/ats-installation/mobile-router-enum';
import { AntennaTypeLteEnum } from '@/helpers/choices/ats-installation/antenna-type-lte-enum';
import { getFormChoices as countryChoices } from '@/helpers/choices/countries';
import { AntennaTypeFlarmEnum } from '@/helpers/choices/ats-installation/antenna-type-flarm-enum';
import { AntennaTypeTrxEnum } from '@/helpers/choices/ats-installation/antenna-type-trx-enum';
import { AntennaTypeGpsEnum } from '@/helpers/choices/ats-installation/antenna-type-gps-enum';

const choices = {
    operationMode: OperationModeEnum.getFormChoices(),
    country: countryChoices(),
    antennaTypeLte: AntennaTypeLteEnum.getFormChoices(),
    antennaTypeFlarm: AntennaTypeFlarmEnum.getFormChoices(),
    antennaTypeTrx: AntennaTypeTrxEnum.getFormChoices(),
    antennaTypeGps: AntennaTypeGpsEnum.getFormChoices(),
};

export const state = () => ({
    // TODO remove property when not neede anymore (see edit page)
    isOperationModeEditable: false,
    choices,
    id: null,
    coverageExtension: false,
    readOnly: false,
    name: null,
    country: null,
    // "INOPERATIVE" is default for all new values (cannot be changed via UI)
    operationMode: OperationModeEnum.values.INOPERATIVE,
    trafficReceiver: false,
    availableForSbv: false,
    ats: null,
    atsInstalled: false,
    antennaTypeLte: AntennaTypeLteEnum.values.GENERATION_1,
    antennaTypeFlarm: null,
    antennaTypeTrx: null,
    antennaTypeGps: null,
    geometry: null,
    coordinate: null,
    remarks: null,
    hasBroadband: false,
    mobileProvider: GENERIC_PROVIDER_PLACEHOLDER,
    mobilePreferredRouter: MobileRouterEnum.values.ROUTER_2,
    broadbandProvider: null,
    broadbandDownload: null,
    broadbandUpload: null,
    broadbandLanPort: null,
    broadbandIpConfig: null,
    broadbandRouter1IpAddress: null,
    broadbandRouter2IpAddress: null,
    extendedConfig: null,
    broadbandGateway: null,
});

export const mutations = {
    clear(state) {
        // TODO remove if not needed anymore
        state.isOperationModeEditable = false;
        state.choices = choices;
        state.coverageExtension = false;
        state.id = null;
        state.readOnly = false;
        state.name = null;
        state.country = null;
        state.operationMode = OperationModeEnum.values.INOPERATIVE;
        state.trafficReceiver = false;
        state.availableForSbv = false;
        state.ats = null;
        state.atsInstalled = false;
        state.antennaTypeLte = AntennaTypeLteEnum.values.GENERATION_1;
        state.antennaTypeFlarm = null;
        state.antennaTypeTrx = null;
        state.antennaTypeGps = null;
        state.geometry = null;
        // additional property that is required in view
        state.coordinate = null;
        state.remarks = null;
        state.hasBroadband = false;
        state.mobileProvider = GENERIC_PROVIDER_PLACEHOLDER;
        state.mobilePreferredRouter = MobileRouterEnum.values.ROUTER_2;
        state.broadbandProvider = null;
        state.broadbandDownload = null;
        state.broadbandUpload = null;
        state.broadbandLanPort = null;
        state.broadbandIpConfig = null;
        state.broadbandRouter1IpAddress = null;
        state.broadbandRouter2IpAddress = null;
        state.broadbandGateway = null;
        state.extendedConfig = null;
    },
    setIsOperationModeEditable(state, value) {
        state.isOperationModeEditable = valueTransform.toBoolean(value);
    },
    setChoices(state, value) {
        checkTypes.assert.object(value);
        state.choices = value;
    },
    updateChoices(state, value) {
        checkTypes.assert.object(value);
        state.choices = { ...state.choices, ...value };
    },
    setCoverageExtension(state, value) {
        state.coverageExtension = valueTransform.toBoolean(value);
    },
    setId(state, value) {
        state.id = valueTransform.toString(value, { nullable: false });
    },
    setReadOnly(state, value) {
        state.readOnly = valueTransform.toBoolean(value);
    },
    setName(state, value) {
        state.name = valueTransform.toString(value);
    },
    setCountry(state, value) {
        state.country = valueTransform.toString(value);
    },
    setOperationMode(state, value) {
        state.operationMode = valueTransform.toInteger(value, { nullable: false });
    },
    setTrafficReceiver(state, value) {
        state.trafficReceiver = valueTransform.toBoolean(value);
    },
    setAvailableForSbv(state, value) {
        state.availableForSbv = valueTransform.toBoolean(value);
    },
    setAts(state, value) {
        state.ats = valueTransform.toString(value);
    },
    setAtsInstalled(state, value) {
        state.atsInstalled = valueTransform.toBoolean(value);
    },
    setAntennaTypeLte(state, value) {
        state.antennaTypeLte = valueTransform.toInteger(value, { nullable: false });
    },
    setAntennaTypeFlarm(state, value) {
        state.antennaTypeFlarm = valueTransform.toInteger(value);
    },
    setAntennaTypeTrx(state, value) {
        state.antennaTypeTrx = valueTransform.toInteger(value);
    },
    setAntennaTypeGps(state, value) {
        state.antennaTypeGps = valueTransform.toInteger(value);
    },
    setGeometry(state, value) {
        if (value != null) checkTypes.assert.nonEmptyObject(value);
        state.geometry = value;
    },
    setCoordinate(state, value) {
        checkTypes.assert.nonEmptyString(value);
        state.coordinate = valueTransform.toString(value);
    },
    setExtendedConfig(state, value) {
        value = value == null || value?.length === 0 ? null : value;
        state.extendedConfig = value;
    },
    setRemarks(state, value) {
        state.remarks = valueTransform.toString(value);
    },
    setHasBroadband(state, value) {
        state.hasBroadband = valueTransform.toBoolean(value);
    },
    setMobileProvider(state, value) {
        state.mobileProvider = valueTransform.toString(value);
    },
    setMobilePreferredRouter(state, value) {
        state.mobilePreferredRouter = valueTransform.toInteger(value, { nullable: false });
    },
    setBroadbandProvider(state, value) {
        state.broadbandProvider = valueTransform.toString(value);
    },
    setBroadbandDownload(state, value) {
        state.broadbandDownload = valueTransform.toFloat(value);
    },
    setBroadbandUpload(state, value) {
        state.broadbandUpload = valueTransform.toFloat(value);
    },
    setBroadbandLanPort(state, value) {
        state.broadbandLanPort = valueTransform.toInteger(value);
    },
    setBroadbandIpConfig(state, value) {
        state.broadbandIpConfig = valueTransform.toInteger(value);
    },
    setBroadbandRouter1IpAddress(state, value) {
        state.broadbandRouter1IpAddress = valueTransform.toString(value);
    },
    setBroadbandRouter2IpAddress(state, value) {
        state.broadbandRouter2IpAddress = valueTransform.toString(value);
    },
    setBroadbandGateway(state, value) {
        state.broadbandGateway = valueTransform.toString(value);
    },
};

export const actions = {
    async load({ commit }, id) {
        checkTypes.assert.nonEmptyString(id);

        const {
            _id,
            coverageExtension,
            readOnly,
            name,
            country,
            operationMode,
            antennas,
            trafficReceiver,
            availableForSbv,
            ats,
            atsInstalled,
            geometry,
            extendedConfig,
            remarks,
            internetConnection,
        } = await this.$axios.$get(`/api/operating/ats-installations/${id}`);

        const { lte: antennaTypeLte, flarm: antennaTypeFlarm, trx: antennaTypeTrx, gps: antennaTypeGps } = antennas;

        commit('setId', _id);
        commit('setReadOnly', readOnly);
        commit('setCoverageExtension', coverageExtension);
        commit('setName', name);
        commit('setCountry', country);
        commit('setOperationMode', operationMode);
        commit('setAntennaTypeLte', antennaTypeLte);
        commit('setAntennaTypeTrx', antennaTypeTrx);
        commit('setAntennaTypeFlarm', antennaTypeFlarm);
        commit('setAntennaTypeGps', antennaTypeGps);
        commit('setTrafficReceiver', trafficReceiver);
        commit('setAvailableForSbv', availableForSbv);
        commit('setAts', ats);
        commit('setAtsInstalled', atsInstalled);
        // location - set store values
        commit('setGeometry', geometry);
        // calculate coordinates
        // coordinates is only required in views and is not
        // related to the incoming model but must be set during normalization
        let merkator = new Merkator();
        merkator.readCoord(geometry.coordinates[0], geometry.coordinates[1]);
        commit('setCoordinate', merkator.toDecimal());
        commit('setExtendedConfig', extendedConfig == null ? extendedConfig : JSON.stringify(extendedConfig, null, 4));
        commit('setRemarks', remarks);

        // optional values
        const { mobile, broadband } = internetConnection || {};
        const { provider: mobileProvider, preferredRouter: mobilePreferredRouter } = mobile || {};
        const {
            provider: broadbandProvider,
            download: broadbandDownload,
            upload: broadbandUpload,
            lanPort: broadbandLanPort,
            ipConfig: broadbandIpConfig,
            router1IpAddress: broadbandRouter1IpAddress,
            router2IpAddress: broadbandRouter2IpAddress,
            gateway: broadbandGateway,
        } = broadband || {};

        commit('setHasBroadband', broadbandProvider != null);
        commit('setMobileProvider', mobileProvider);
        commit('setMobilePreferredRouter', mobilePreferredRouter);
        commit('setBroadbandProvider', broadbandProvider);
        commit('setBroadbandDownload', broadbandDownload);
        commit('setBroadbandUpload', broadbandUpload);
        commit('setBroadbandLanPort', broadbandLanPort);
        commit('setBroadbandIpConfig', broadbandIpConfig);
        commit('setBroadbandRouter1IpAddress', broadbandRouter1IpAddress);
        commit('setBroadbandRouter2IpAddress', broadbandRouter2IpAddress);
        commit('setBroadbandGateway', broadbandGateway);

        // TODO remove if not longer needed -> all new ats installations should have a linked commissioning process
        const { items } = await this.$axios.$get('/api/operating/commissioning-processes', { params: { atsInstallation: id } });

        if (items?.length === 0 && (operationMode === OperationModeEnum.values.INOPERATIVE || operationMode === OperationModeEnum.values.TEST)) {
            commit('setIsOperationModeEditable', true);
        }
    },
    async new({ state }) {
        let denormalized = _denormalize({ state });

        return this.$axios.$post('/api/operating/ats-installations', cleanDeep(denormalized));
    },
    async edit({ state }) {
        let denormalized = _denormalize({ state });

        return this.$axios.$patch(`/api/operating/ats-installations/${state.id}`, denormalized);
    },
    async lock({ state }, ids) {
        return this.$axios.$post(`/api/operating/ats-installations/lock`, ids);
    },
    async unlock({ state }, ids) {
        return this.$axios.$post(`/api/operating/ats-installations/unlock`, ids);
    },
};

function _denormalize({ state }) {
    checkTypes.assert.object(state);

    let denormalized = {
        coverageExtension: state.coverageExtension,
        name: state.name,
        country: state.country,
        operationMode: state.operationMode,
        trafficReceiver: state.trafficReceiver,
        availableForSbv: state.availableForSbv,
        ats: state.ats,
        atsInstalled: state.atsInstalled,
        antennas: {
            lte: state.antennaTypeLte,
            flarm: state.antennaTypeFlarm,
            trx: state.antennaTypeTrx,
            gps: state.antennaTypeGps,
        },
        geometry: {
            type: state.geometry.type,
            coordinates: state.geometry.coordinates,
        },
        extendedConfig: state.extendedConfig == null ? state.extendedConfig : JSON.parse(state.extendedConfig),
        remarks: state.remarks,
    };

    const internetConnection = {
        mobile: {
            provider: state.mobileProvider,
            preferredRouter: state.mobilePreferredRouter,
        },
        broadband: null,
    };
    // handle optional broadband connection configuration
    if (state.hasBroadband) {
        internetConnection.broadband = {};
        internetConnection.broadband.provider = state.broadbandProvider;
        internetConnection.broadband.download = state.broadbandDownload;
        internetConnection.broadband.upload = state.broadbandUpload;
        internetConnection.broadband.lanPort = state.broadbandLanPort;
        internetConnection.broadband.ipConfig = state.broadbandIpConfig;
        internetConnection.broadband.router1IpAddress = state.broadbandRouter1IpAddress;
        internetConnection.broadband.router2IpAddress = state.broadbandRouter2IpAddress;
        internetConnection.broadband.gateway = state.broadbandGateway;
    }
    // add internet connection metadata to denormalized object
    denormalized.internetConnection = internetConnection;

    return denormalized;
}
