export default class UserRolesMetadataLoader {
    constructor(axios) {
        this.axios = axios;
        /**
         * @type {Object[]}
         */
        this.cachedRolesMetadata = null;
    }

    /**
     * Loads role metadata from backend for a given role ID. Returns null
     * if role is not found.
     *
     * @param {string} role
     * @return {Promise<Object|null>}
     */
    async getUserRoleMetadata(role) {
        if (this.cachedRolesMetadata == null) {
            const { roles } = await this.axios.$get('/api/iam/rbac/roles');
            this.cachedRolesMetadata = roles;
        }

        return this.cachedRolesMetadata[role] || null;
    }
}
