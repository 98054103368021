import cleanDeep from 'clean-deep';
import { point as createPoint, featureCollection as createFeatureCollection } from '@turf/turf';
import * as uuid from 'uuid';
import getWindTurbineName from '@/helpers/get-wind-turbine-name';
import checkTypes from 'check-types';
import * as valueTransform from '@/helpers/value-transform';

export const state = () => ({
    centerOfMass: null,
    items: new Map(),
    uploadedOn: null,
    uploadedFilename: null,
    prepareErrors: [],
});

export const getters = {
    getItems: (state) => {
        // IMPORTANT make sure to return an array calling values which will only return list of values and not a "keyed valued"
        return Array.from(state.items.values());
    },
    getFeatureCollection: (state) => {
        const items = Array.from(state.items.values());

        let features = [];
        for (const item of items) {
            features.push(createPoint(item.geometry));
        }

        return createFeatureCollection(features);
    },
    isReadyToMigrate: (state) => {
        // checks that the items have the required parameters set and if true, returns true
        // check that required operator is set, all other values are optional
        const windTurbines = Array.from(state.items.values());

        let isReadyToMigrate = false;
        for (const windTurbine of windTurbines) {
            // if operator is not set, not able to import
            if (!windTurbine.operator) {
                isReadyToMigrate = false;
                break;
            }
            if (!windTurbine.importId) {
                isReadyToMigrate = false;
                break;
            }
            // otherwise, check if we have prepare errors
            isReadyToMigrate = state.prepareErrors.length === 0;
        }

        return isReadyToMigrate;
    },
    getCenterOfMassCoordinates: (state) => {
        return state.centerOfMass ? state.centerOfMass.geometry.coordinates : [0, 0];
    },
};

export const mutations = {
    clear(state) {
        state.centerOfMass = null;
        state.items = new Map();
        state.uploadedOn = null;
        state.uploadedFilename = null;
        state.prepareErrors = [];
    },
    setCenterOfMass(state, value) {
        if (value != null) checkTypes.assert.nonEmptyObject(value);
        state.centerOfMass = value;
    },
    setItems(state, value) {
        value = value || [];
        state.items = new Map();
        value.forEach(function (item) {
            // generate key that is required to identify a single item in the list
            const key = uuid.v4().toString();
            const name = getWindTurbineName({
                id: key,
                manufacturer: item.manufacturer,
                serialNumber: item.serialNumber,
                operatorIdent: item.operatorIdent,
            });
            // inject into object so that it is available in the data list
            item.itemKey = key;
            item.name = name;
            state.items.set(key, item);
        });
        // IMPORTANT change state by setting new Map since simply calling "set" directly won't propagate state change
        state.items = new Map(state.items.entries());
    },
    setItem(state, { key, value }) {
        checkTypes.assert.nonEmptyObject(value);
        state.items.set(key, value);
        // IMPORTANT change state by setting new Map since simply calling "set" directly won't propagate state change
        state.items = new Map(state.items.entries());
    },
    removeItem(state, value) {
        checkTypes.assert.nonEmptyString(value);
        state.items.delete(value);
        // IMPORTANT change state by setting new Map since simply calling "set" directly won't propagate state change
        state.items = new Map(state.items.entries());
    },
    setUploadedOn(state, value) {
        checkTypes.assert.date(value);
        state.uploadedOn = value;
    },
    setUploadedFilename(state, value) {
        state.uploadedFilename = valueTransform.toString(value);
    },
    setPrepareErrors(state, value) {
        if (value != null) checkTypes.assert.array.of.nonEmptyObject(value);
        state.prepareErrors = value || [];
    },
    removePrepareError(state, itemId) {
        state.prepareErrors = state.prepareErrors.filter((value) => value.itemId !== itemId);
    },
};

export const actions = {
    async prepareData({ state, commit }, { formData, filename }) {
        const { preparedData, centerOfMass, errors } = await this.$axios.$post('/api/operating/import/drafts/wind-turbines/prepare', formData, {
            // depending on the uploaded items, this request can take a long time to finish
            timeout: 300 * 1000,
        });

        commit('setUploadedFilename', filename);
        commit('setUploadedOn', new Date());
        commit('setItems', preparedData);
        commit('setCenterOfMass', centerOfMass);
        commit('setPrepareErrors', errors);
    },
    async importData({ state }) {
        const importDataset = Array.from(state.items.values());
        // prepare dataset for insertion and post each item to the default CRUD post endpoint
        let importCount = 0;
        let items = [];
        for (const importData of importDataset) {
            let denormalized = {
                importId: importData.importId,
                offerId: importData.offerId,
                projectId: importData.projectId,
                manufacturer: importData.manufacturer,
                serialNumber: importData.serialNumber,
                type: importData.type,
                windPark: importData.windPark,
                geometry: importData.geometry,
                hubHeight: importData.hubHeight,
                rotorLength: importData.rotorLength,
                elevation: importData.elevation,
                commissioning: importData.commissioning,
                operatorIdent: importData?.operatorIdent?.toString(),
                country: importData.country,
                activationSpace: importData.activationSpace,
                operator: importData.operator,
                trafficReceiver: importData.trafficReceiver,
            };
            items.push(cleanDeep(denormalized));
            importCount++;
        }
        await this.$axios.$post('/api/operating/import/drafts/wind-turbines/import', { items });

        return { totalCount: importDataset.length, importCount };
    },
};
