import { getFormChoices as countryChoices } from '@/helpers/choices/countries';
import { getFormChoices as stateChoices } from '@/helpers/choices/states';
import checkTypes from 'check-types';
import * as valueTransform from '@/helpers/value-transform';
import cleanDeep from 'clean-deep';

const choices = {
    country: countryChoices(),
    state: stateChoices(),
};

export const state = () => ({
    choices,
    id: null,
    readOnly: false,
    projectId: null,
    country: null,
    state: null,
    remarks: null,
    accessRemarks: null,
    technicalRemarks: null,
    customerContactPoint: null,
});

export const mutations = {
    clear(state) {
        state.choices = choices;
        state.id = null;
        state.readOnly = false;
        state.projectId = null;
        state.country = null;
        state.state = null;
        state.remarks = null;
        state.accessRemarks = null;
        state.technicalRemarks = null;
        state.customerContactPoint = null;
    },
    setChoices(state, choices) {
        state.choices = choices;
    },
    updateChoices(state, value) {
        checkTypes.assert.object(value);
        state.choices = { ...state.choices, ...value };
    },
    setId(state, value) {
        state.id = valueTransform.toString(value, { nullable: false });
    },
    setReadOnly(state, value) {
        state.readOnly = valueTransform.toBoolean(value);
    },
    setProjectId(state, value) {
        state.projectId = valueTransform.toString(value);
    },
    setCountry(state, value) {
        state.country = valueTransform.toString(value);
    },
    setState(state, value) {
        state.state = valueTransform.toString(value);
    },
    setRemarks(state, value) {
        state.remarks = valueTransform.toString(value);
    },
    setAccessRemarks(state, value) {
        state.accessRemarks = valueTransform.toString(value);
    },
    setTechnicalRemarks(state, value) {
        state.technicalRemarks = valueTransform.toString(value);
    },
    setCustomerContactPoint(state, value) {
        state.customerContactPoint = valueTransform.toString(value);
    },
};

export const actions = {
    async load({ commit }, id) {
        checkTypes.assert.nonEmptyString(id);

        const { _id, readOnly, projectId, country, state, remarks, accessRemarks, technicalRemarks, customerContactPoint } = await this.$axios.$get(
            `/api/operating/projects/${id}`
        );

        commit('setId', _id);
        commit('setReadOnly', readOnly);
        commit('setProjectId', projectId);
        commit('setCountry', country);
        commit('setState', state);
        commit('setRemarks', remarks);
        commit('setAccessRemarks', accessRemarks);
        commit('setTechnicalRemarks', technicalRemarks);
        commit('setCustomerContactPoint', customerContactPoint);

        if (country) {
            commit('updateChoices', { state: stateChoices(country) });
        }
    },
    async new({ state }) {
        return this.$axios.$post(
            '/api/operating/projects',
            cleanDeep({
                projectId: state.projectId,
                country: state.country,
                state: state.state,
                remarks: state.remarks,
                accessRemarks: state.accessRemarks,
                technicalRemarks: state.technicalRemarks,
                customerContactPoint: state.customerContactPoint,
            })
        );
    },
    async edit({ state }) {
        return this.$axios.$patch(`/api/operating/projects/${state.id}`, {
            country: state.country,
            state: state.state,
            remarks: state.remarks,
            accessRemarks: state.accessRemarks,
            technicalRemarks: state.technicalRemarks,
            customerContactPoint: state.customerContactPoint,
        });
    },
};
