import cleanDeep from 'clean-deep';
import getWindTurbineName from '@/helpers/get-wind-turbine-name';
import checkTypes from 'check-types';

export const state = () => ({
    choices: {
        windPark: [],
        businessEntity: [],
        atsInstallation: [],
    },
    uploadItems: null,
    items: new Map(),
    prepareErrors: [],
    cfgCreateSensorAtsInstallation: true,
});

export const getters = {
    getItems: (state) => {
        // IMPORTANT make sure to return an array calling values which will only return list of values and not a "keyed valued"
        return Array.from(state.items.values());
    },
    isReadyToMigrate: (state) => {
        const hasErrors = state.prepareErrors.length > 0;
        // check if responsibility is set for each migrate item
        let hasResponsibilities = true;
        for (const item of state.items.values()) {
            if (!item.responsibilities || item.responsibilities?.length === 0) hasResponsibilities = false;
        }

        return hasErrors === false && hasResponsibilities === true;
    },
    setUploadItems(state, value) {
        // convert wind parks input lit into list of distinct wind park names
        state.uploadItems = [...new Set(value)];
    },
};

export const mutations = {
    clear(state) {
        state.choices = {
            windPark: [],
            businessEntity: [],
            atsInstallation: [],
        };
        state.uploadItems = null;
        state.items = new Map();
        state.prepareErrors = [];
        state.cfgCreateSensorAtsInstallation = true;
    },
    setChoices(state, value) {
        checkTypes.assert.object(value);
        state.choices = value;
    },
    updateChoices(state, value) {
        checkTypes.assert.object(value);
        state.choices = { ...state.choices, ...value };
    },
    setItems(state, value) {
        // each item gets a listKey which is a sequential numbering
        value = value || [];
        state.items = new Map();
        value.forEach(function (item) {
            // generate key that is required to identify a single item in the list and
            // inject into object so that it is available in the data list
            item.name = getWindTurbineName({
                id: item._id,
                manufacturer: item.manufacturer,
                serialNumber: item.serialNumber,
                operatorIdent: item.operatorIdent,
            });
            // remap injected draft properties that are used to enhance UX
            item.draftWindPark = item.windPark;
            item.draftOperator = item.operator;
            // remove injected properties as they are used to create the actual documents later
            delete item.windPark;
            delete item.operator;

            state.items.set(item._id, item);
        });
        // IMPORTANT change state by setting new Map since simply calling "set" directly won't propagate state change
        state.items = new Map(state.items.entries());
    },
    setItem(state, { key, value }) {
        checkTypes.assert.nonEmptyString(key);
        checkTypes.assert.nonEmptyObject(value);
        state.items.set(key, value);
        // IMPORTANT change state by setting new Map since simply calling "set" directly won't propagate state change
        state.items = new Map(state.items.entries());
    },
    removeItem(state, value) {
        checkTypes.assert.nonEmptyString(value);
        state.items.delete(value);
        // IMPORTANT change state by setting new Map since simply calling "set" directly won't propagate state change
        state.items = new Map(state.items.entries());
    },
    setUploadItems(state, value) {
        checkTypes.assert.nonEmptyArray(value);
        // convert wind parks input lit into list of distinct wind park names
        state.uploadItems = [...new Set(value)];
    },
    setPrepareErrors(state, value) {
        if (value != null) checkTypes.assert.array.of.nonEmptyObject(value);
        state.prepareErrors = value || [];
    },
    removePrepareError(state, itemId) {
        state.prepareErrors = state.prepareErrors.filter((value) => value.itemId !== itemId);
    },
    setCfgCreateSensorAtsInstallation(state, value) {
        checkTypes.assert.boolean(value);
        state.cfgCreateSensorAtsInstallation = value;
    },
};

export const actions = {
    async prepareData({ state, commit }) {
        const { preparedData, errors } = await this.$axios.$post('/api/operating/import/wind-turbines/prepare', state.uploadItems, {
            timeout: 300 * 1000,
        });
        commit('setItems', preparedData);
        commit('setPrepareErrors', errors);
    },
    async migrateData({ state }) {
        const importDataset = Array.from(state.items.values());
        // prepare dataset for insertion and post each item to the default CRUD post endpoint
        let migrateCount = 0;
        let items = [];
        for (const importData of importDataset) {
            let denormalized = {
                manufacturer: importData.manufacturer,
                serialNumber: importData.serialNumber,
                trafficReceiver: importData.trafficReceiver,
                type: importData.type,
                project: importData.project?._id,
                windPark: importData?.windPark?._id,
                geometry: importData.geometry,
                hubHeight: parseInt(importData.hubHeight),
                rotorLength: parseInt(importData.rotorLength),
                elevation: importData.elevation,
                commissioning: importData.commissioning,
                operatorIdent: importData?.operatorIdent?.toString(),
                country: importData.country,
                activationSpace: importData.activationSpace,
                responsibilities: _denormalizeResponsibilities(importData.responsibilities),
            };
            items.push(cleanDeep(denormalized));
            migrateCount++;
        }
        await this.$axios.$post('/api/operating/import/wind-turbines/import', {
            items,
            config: { createSensorAtsInstallation: state.cfgCreateSensorAtsInstallation },
        });

        return { totalCount: importDataset.length, migrateCount };
    },
};

function _denormalizeResponsibilities(value) {
    let responsibilities = [];
    for (const resp of value) {
        responsibilities.push({
            responsibility: parseInt(resp.responsibility.value),
            businessEntity: resp.businessEntity._id,
        });
    }

    return responsibilities;
}
