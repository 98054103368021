import checkTypes from 'check-types';

export const state = () => ({
    atm: {},
});

export const getters = {};

export const mutations = {
    clear(state) {
        state.atm = null;
    },
    setAtm(state, value) {
        checkTypes.assert.nonEmptyObject(value);
        state.atm = value;
    },
};

export const actions = {
    async load({ commit }, id) {
        checkTypes.assert.nonEmptyString(id);

        const atm = await this.$axios.$get(`/api/operating/atm/${id}`);

        commit('setAtm', atm);
    },
    async delete({ state }, id) {
        await this.$axios.$delete(`/api/operating/atm/${id}`);
    },
};
