import { Enum } from '@/helpers/choices/enum';

export class DigitalInputFunctionsEnum extends Enum {}

DigitalInputFunctionsEnum.values = {
    IR_ON: 0,
    VISUAL_LIGHTS_ON: 1,
    IR_NO_ERROR: 2,
    VISUAL_LIGHTS_NO_ERROR: 3,
    GENERIC_INPUT: 4,
};

DigitalInputFunctionsEnum.names = {
    0: 'IR On',
    1: 'Visual Lights On',
    2: 'IR No Error',
    3: 'Visual Lights No Error',
    4: 'Generic Input',
};

Object.freeze(DigitalInputFunctionsEnum);
