import { Enum } from '@/helpers/choices/enum';

export class AntennaTypeGpsEnum extends Enum {}

AntennaTypeGpsEnum.values = {
    GENERATION_1: 0,
};

AntennaTypeGpsEnum.names = {
    0: 'Generation 1',
};

Object.freeze(AntennaTypeGpsEnum);
