import { Enum } from '@/helpers/choices/enum';

export class AntennaTypeTrxEnum extends Enum {}

AntennaTypeTrxEnum.values = {
    GENERATION_1: 0,
    GENERATION_2: 1,
};

AntennaTypeTrxEnum.names = {
    0: 'Generation 1',
    1: 'Generation 2',
};

Object.freeze(AntennaTypeTrxEnum);
