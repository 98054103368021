import checkTypes from 'check-types';
import cleanDeep from 'clean-deep';
import { DateTime } from 'luxon';
import { DATE_FORMAT } from '@/globals';
import { macToIp } from '@/helpers/mac-to-ip';
import * as valueTransform from '@/helpers/value-transform';

export const state = () => ({
    id: null,
    kind: null,
    readOnly: false,
    serialNumber: null,
    partNumber: null,
    builderName: null,
    buildDate: new Date().toISOString().substring(0, 10),
    housing: null,
    expansionBoard: null,
    baseBoard: null,
    orangepiSerialNumber: null,
    orangepiCpuId: null,
    orangepiEtherMac: null,
    orangepiIpAddress: null,
    remarks: null,
});

export const mutations = {
    clear(state) {
        state.id = null;
        state.kind = null;
        state.readOnly = false;
        state.serialNumber = null;
        state.partNumber = null;
        state.builderName = null;
        state.buildDate = new Date().toISOString().substring(0, 10);
        state.housing = null;
        state.baseBoard = null;
        state.expansionBoard = null;
        state.orangepiSerialNumber = null;
        state.orangepiCpuId = null;
        state.orangepiEtherMac = null;
        state.orangepiIpAddress = null;
        state.remarks = null;
    },
    setId(state, value) {
        state.id = valueTransform.toString(value, { nullable: false });
    },
    setKind(state, value) {
        state.kind = valueTransform.toString(value, { nullable: false });
    },
    setReadOnly(state, value) {
        state.readOnly = valueTransform.toBoolean(value);
    },
    setBuilderName(state, value) {
        state.builderName = valueTransform.toString(value);
    },
    setBuildDate(state, value) {
        state.buildDate = valueTransform.toString(value);
    },
    setSerialNumber(state, value) {
        state.serialNumber = valueTransform.toString(value);
    },
    setPartNumber(state, value) {
        state.partNumber = valueTransform.toString(value);
    },
    setHousing(state, value) {
        state.housing = valueTransform.toString(value);
    },
    setBaseBoard(state, value) {
        state.baseBoard = valueTransform.toString(value);
    },
    setExpansionBoard(state, value) {
        state.expansionBoard = valueTransform.toString(value);
    },
    setOrangepiSerialNumber(state, value) {
        state.orangepiSerialNumber = valueTransform.toString(value);
    },
    setOrangepiCpuId(state, value) {
        state.orangepiCpuId = valueTransform.toString(value);
    },
    setOrangepiEtherMac(state, value) {
        const macAddress = valueTransform.toString(value);
        state.orangepiEtherMac = macAddress;
        // also calculate resulting IPv4 address
        state.orangepiIpAddress = macToIp(macAddress);
    },
    setRemarks(state, value) {
        state.remarks = valueTransform.toString(value);
    },
};

export const actions = {
    async load({ commit }, id) {
        checkTypes.assert.nonEmptyString(id);

        const {
            _id,
            kind,
            readOnly,
            builderName,
            buildDate,
            serialNumber,
            partNumber,
            housing,
            expansionBoard,
            baseBoard,
            orangepiSerialNumber,
            orangepiCpuId,
            orangepiEtherMac,
            orangepiIpAddress,
            remarks,
        } = await this.$axios.$get(`/api/operating/atm/${id}`);

        commit('setId', _id);
        commit('setKind', kind);
        commit('setReadOnly', readOnly);
        commit('setSerialNumber', serialNumber);
        commit('setPartNumber', partNumber);
        commit('setBuilderName', builderName);
        commit('setBuildDate', buildDate ? DateTime.fromISO(buildDate).toFormat(DATE_FORMAT) : null);
        commit('setHousing', housing);
        commit('setExpansionBoard', expansionBoard);
        commit('setBaseBoard', baseBoard);
        commit('setOrangepiSerialNumber', orangepiSerialNumber);
        commit('setOrangepiCpuId', orangepiCpuId);
        commit('setOrangepiEtherMac', orangepiEtherMac);
        commit('setBaseBoard', orangepiIpAddress);
        commit('setRemarks', remarks);
    },
    async new({ state }) {
        let denormalized = _denormalize(state);

        return this.$axios.$post('/api/operating/atm/atm4', cleanDeep(denormalized));
    },
    async edit({ state }) {
        let denormalized = _denormalize(state);
        // IMPORTANT serial number cannot be edited anymore
        delete denormalized.serialNumber;

        return this.$axios.$patch(`/api/operating/atm/atm4/${state.id}`, denormalized);
    },
};

function _denormalize(state) {
    return {
        serialNumber: state.serialNumber,
        partNumber: state.partNumber,
        builderName: state.builderName,
        buildDate: state.buildDate ? DateTime.fromFormat(state.buildDate, DATE_FORMAT, { zone: 'UTC' }).toJSDate().toISOString() : null,
        housing: state.housing,
        expansionBoard: state.expansionBoard,
        baseBoard: state.baseBoard,
        orangepiSerialNumber: state.orangepiSerialNumber,
        orangepiCpuId: state.orangepiCpuId,
        orangepiEtherMac: state.orangepiEtherMac,
        orangepiIpAddress: state.orangepiIpAddress,
        remarks: state.remarks,
    };
}
