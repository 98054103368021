import { DateTime } from 'luxon';
import { DATE_FORMAT } from '@/globals';
import { macToIp } from '@/helpers/mac-to-ip';
import * as valueTransform from '@/helpers/value-transform';

export const _state = {
    id: null,
    kind: null,
    readOnly: false,
    serialNumber: null,
    partNumber: null,
    deviceId: null,
    builderName: null,
    buildDate: null,
    housing: null,
    mountingPlate: null,
    powerSupply: null,
    heating: null,
    relays: null,
    lvl2TermBlock: null,
    lvl3TermBlock: null,
    circuitBreaker: null,
    vpu: null,
    termBlockBlue: null,
    termBlockGrey: null,
    earthLeadTerm: null,
    connector30Pin: null,
    connector10Pin: null,
    rj45Coupler: null,
    antennaCableLte: null,
    flangePlate: null,
    router1: null,
    router2: null,
    cpuModule: null,
    ioModule: null,
    dsub3WayCable: null,
    dsubCableGreen: null,
    rj45CableOrange025m: null,
    rj45CableRed025m: null,
    rj45CableBlue025m: null,
    rj45CableRed05m: null,
    rj45CableBlue05m: null,
    remarks: null,
};

export const _mutations = {
    setId(state, value) {
        state.id = valueTransform.toString(value, { nullable: false });
    },
    setKind(state, value) {
        state.kind = valueTransform.toString(value, { nullable: false });
    },
    setReadOnly(state, value) {
        state.readOnly = valueTransform.toBoolean(value);
    },
    setSerialNumber(state, value) {
        state.serialNumber = valueTransform.toString(value, {
            postProcess: (value) => {
                return value.toUpperCase();
            },
        });
    },
    setPartNumber(state, value) {
        state.partNumber = valueTransform.toString(value, {
            postProcess: (value) => {
                return value.toUpperCase();
            },
        });
    },
    setHousing(state, value) {
        state.housing = valueTransform.toString(value);
    },
    setDeviceId(state, value) {
        state.deviceId = valueTransform.toString(value);
    },
    setBuilderName(state, value) {
        state.builderName = valueTransform.toString(value);
    },
    setBuildDate(state, value) {
        state.buildDate = valueTransform.toString(value);
    },
    setMountingPlate(state, value) {
        state.mountingPlate = valueTransform.toString(value);
    },
    setPowerSupply(state, value) {
        state.powerSupply = valueTransform.toString(value);
    },
    setHeating(state, value) {
        state.heating = valueTransform.toString(value);
    },
    setRelays(state, value) {
        state.relays = valueTransform.toString(value);
    },
    setLvl2TermBlock(state, value) {
        state.lvl2TermBlock = valueTransform.toString(value);
    },
    setLvl3TermBlock(state, value) {
        state.lvl3TermBlock = valueTransform.toString(value);
    },
    setCircuitBreaker(state, value) {
        state.circuitBreaker = valueTransform.toString(value);
    },
    setVpu(state, value) {
        state.vpu = valueTransform.toString(value);
    },
    setTermBlockBlue(state, value) {
        state.termBlockBlue = valueTransform.toString(value);
    },
    setTermBlockGrey(state, value) {
        state.termBlockGrey = valueTransform.toString(value);
    },
    setEarthLeadTerm(state, value) {
        state.earthLeadTerm = valueTransform.toString(value);
    },
    setConnector30Pin(state, value) {
        state.connector30Pin = valueTransform.toString(value);
    },
    setConnector10Pin(state, value) {
        state.connector10Pin = valueTransform.toString(value);
    },
    setRj45Coupler(state, value) {
        state.rj45Coupler = valueTransform.toString(value);
    },
    setAntennaCableLte(state, value) {
        state.antennaCableLte = valueTransform.toString(value);
    },
    setFlangePlate(state, value) {
        state.flangePlate = valueTransform.toString(value);
    },
    setRouter1(state, value) {
        state.router1 = valueTransform.toString(value);
    },
    setRouter2(state, value) {
        state.router2 = valueTransform.toString(value);
    },
    setCpuModule(state, value) {
        state.cpuModule = valueTransform.toString(value);
    },
    setIoModule(state, value) {
        state.ioModule = valueTransform.toString(value);
    },
    setDsub3WayCable(state, value) {
        state.dsub3WayCable = valueTransform.toString(value);
    },
    setDsubCableGreen(state, value) {
        state.dsubCableGreen = valueTransform.toString(value);
    },
    setRj45CableOrange025m(state, value) {
        state.rj45CableOrange025m = valueTransform.toString(value);
    },
    setRj45CableRed025m(state, value) {
        state.rj45CableRed025m = valueTransform.toString(value);
    },
    setRj45CableBlue025m(state, value) {
        state.rj45CableBlue025m = valueTransform.toString(value);
    },
    setRj45CableRed05m(state, value) {
        state.rj45CableRed05m = valueTransform.toString(value);
    },
    setRj45CableBlue05m(state, value) {
        state.rj45CableBlue05m = valueTransform.toString(value);
    },
    setRemarks(state, value) {
        state.remarks = valueTransform.toString(value);
    },
};

export const _actions = {
    async calculateDeviceId({ commit }, cpuModuleId) {
        // only calculate device id if cpu module is selected (is optional and can be null)
        let deviceId = null;
        if (cpuModuleId != null) {
            // load the CPU module from backend and calculate the deviceId
            // based on the orangepiEtherMac value
            const { orangepiEtherMac } = await this.$axios.$get(`/api/operating/atm/${cpuModuleId}`);
            const ip = macToIp(orangepiEtherMac);
            deviceId = ip.replace(/:/g, '');
        }
        commit('setDeviceId', deviceId);
    },
};

export function _denormalize(state) {
    return {
        serialNumber: state.serialNumber,
        partNumber: state.partNumber,
        deviceId: state.deviceId,
        builderName: state.builderName,
        buildDate: DateTime.fromFormat(state.buildDate, DATE_FORMAT, { zone: 'UTC' }).toJSDate().toISOString(),
        housing: state.housing,
        mountingPlate: state.mountingPlate,
        powerSupply: state.powerSupply,
        heating: state.heating,
        relays: state.relays,
        lvl2TermBlock: state.lvl2TermBlock,
        lvl3TermBlock: state.lvl3TermBlock,
        circuitBreaker: state.circuitBreaker,
        vpu: state.vpu,
        termBlockBlue: state.termBlockBlue,
        termBlockGrey: state.termBlockGrey,
        earthLeadTerm: state.earthLeadTerm,
        connector30Pin: state.connector30Pin,
        connector10Pin: state.connector10Pin,
        rj45Coupler: state.rj45Coupler,
        antennaCableLte: state.antennaCableLte,
        flangePlate: state.flangePlate,
        router1: state.router1,
        router2: state.router2,
        cpuModule: state.cpuModule,
        ioModule: state.ioModule,
        dsub3WayCable: state.dsub3WayCable,
        dsubCableGreen: state.dsubCableGreen,
        rj45CableOrange025m: state.rj45CableOrange025m,
        rj45CableRed025m: state.rj45CableRed025m,
        rj45CableBlue025m: state.rj45CableBlue025m,
        rj45CableRed05m: state.rj45CableRed05m,
        rj45CableBlue05m: state.rj45CableBlue05m,
        remarks: state.remarks,
    };
}
