<template>
    <v-menu id="notifications-bell" offset-y left :close-on-content-click="false" min-width="400px">
        <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
                <v-badge :content="messages.length" :value="messages.length" color="green" overlap left>
                    <v-icon small>$vuetify.icons.bell</v-icon>
                </v-badge>
            </v-btn>
        </template>

        <v-list id="notifications-list">
            <v-list-item id="read-messages">
                <v-list-item-avatar />
                <v-list-item-content>
                    <v-list-item-subtitle></v-list-item-subtitle>
                    <v-list-item-title>{{ `${readMessages.length} Read messages` }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action v-if="readMessages.length > 0">
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn icon v-on="on" @click="markAllAsUnread">
                                <v-icon small>$vuetify.icons.envelope</v-icon>
                            </v-btn>
                        </template>
                        <span>Mark all as unread</span>
                    </v-tooltip>
                </v-list-item-action>
            </v-list-item>

            <v-divider v-if="messages.length > 0" class="mb-5" />

            <v-list-item v-for="(item, index) in messages" id="notification-list" :key="index" ripple>
                <v-list-item-avatar>
                    <v-icon small class="mr-2" :color="getNotificationStateColor(item)">{{ getNotificationStateIcon(item) }} </v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-subtitle>{{ item.createdAt }}</v-list-item-subtitle>
                    <v-list-item-title>{{ item.message }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn icon v-on="on" @click="markAsRead(item)">
                                <v-icon small>$vuetify.icons.envelopeOpen</v-icon>
                            </v-btn>
                        </template>
                        <span>Mark as read</span>
                    </v-tooltip>
                </v-list-item-action>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
import { StateEnum } from '@/helpers/choices/notification/state-enum';
import rbacMeta from '@/mixins/rbac-meta';
import userPrefs from '@/mixins/user-prefs';

export default {
    name: 'NotificationList',
    mixins: [rbacMeta, userPrefs],
    data() {
        return {
            // the user's unread messages
            messages: [],
            readMessages: [],
        };
    },
    computed: {
        notifications() {
            return this.$store.state.notifications;
        },
    },
    watch: {
        notifications(value) {
            this.updateNotifications();
        },
        readMessages(value) {
            this.updateNotifications();
        },
    },
    async mounted() {
        // user carries read notifications that are stored in localStorage to be retained throughout multiple
        // browser sessions
        this.readMessages = this.userPrefs.notifications;
    },
    methods: {
        updateNotifications() {
            let filtered = [];
            for (const item of this.notifications) {
                if (!this.readMessages.includes(item._id)) {
                    filtered.push(item);
                }
            }
            this.messages = filtered;
        },
        markAllAsRead() {
            if (process.browser) {
                this.userPrefs.addNotifications(this.notifications.map((value) => value._id));
                this.readMessages = this.userPrefs.notifications;
                this.userPrefs.persistToLocalStorage();
            }
        },
        markAllAsUnread() {
            if (process.browser) {
                this.userPrefs.clearNotifications();
                this.readMessages = this.userPrefs.notifications;
                this.userPrefs.persistToLocalStorage();
            }
        },
        markAsRead(value) {
            if (process.browser) {
                this.userPrefs.addNotifications(value._id);
                this.readMessages = this.userPrefs.notifications;
                this.userPrefs.persistToLocalStorage();
            }
        },
        // TODO fix this => must return correct item
        getNotificationStateIcon(value) {
            switch (value.state) {
                case StateEnum.values.SUCCESS:
                    return '$vuetify.icons.circleCheck';
                case StateEnum.values.ERROR:
                    return '$vuetify.icons.triangleExclamation';
                default:
                    return '$vuetify.icons.circleInfo';
            }
        },
        getNotificationStateColor(value) {
            switch (value.state) {
                case StateEnum.values.SUCCESS:
                    return 'success';
                case StateEnum.values.ERROR:
                    return 'error';
                default:
                    return 'info';
            }
        },
    },
};
</script>

<style scoped lang="scss">
@import 'assets/scss/_variables';

#notifications-list {
    max-height: 600px;
    overflow-y: auto;
}

#read-messages {
    color: darken($subtle-gray, 40) !important;
}
</style>
