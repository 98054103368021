import ProjectChoicesLoader from '@/helpers/choices/loaders/project-choices-loader';
import checkTypes from 'check-types';
import * as valueTransform from '@/helpers/value-transform';
import cleanDeep from 'clean-deep';

export const state = () => ({
    choices: {},
    id: null,
    readOnly: false,
    scadaId: null,
    plainPkcs12Password: null,
    pkcs12File: null,
    commCaCertFile: null,
    scadaCaCertFile: null,
    pkcs12FileData: null,
    commCaCertFileData: null,
    scadaCaCertFileData: null,
});

export const mutations = {
    clear(state) {
        state.choices = {};
        state.id = null;
        state.readOnly = false;
        state.scadaId = null;
        state.project = [];
        state.plainPkcs12Password = null;
        state.pkcs12File = null;
        state.commCaCertFile = null;
        state.scadaCaCertFile = null;
        state.pkcs12FileData = null;
        state.commCaCertFileData = null;
        state.scadaCaCertFileData = null;
    },
    setChoices(state, value) {
        checkTypes.assert.object(value);
        state.choices = value;
    },
    updateChoices(state, value) {
        checkTypes.assert.object(value);
        state.choices = { ...state.choices, ...value };
    },
    setId(state, value) {
        state.id = valueTransform.toString(value, { nullable: false });
    },
    setReadOnly(state, value) {
        state.readOnly = valueTransform.toBoolean(value);
    },
    setScadaId(state, value) {
        state.scadaId = valueTransform.toString(value);
    },
    setPlainPkcs12Password(state, value) {
        state.plainPkcs12Password = valueTransform.toString(value);
    },
    setPkcs12File(state, value) {
        state.pkcs12File = valueTransform.toString(value);
    },
    setCommCaCertFile(state, value) {
        state.commCaCertFile = valueTransform.toString(value);
    },
    setScadaCaCertFile(state, value) {
        state.scadaCaCertFile = valueTransform.toString(value);
    },
    setPkcs12FileData(state, value) {
        state.pkcs12FileData = valueTransform.toString(value);
    },
    setCommCaCertFileData(state, value) {
        state.commCaCertFileData = valueTransform.toString(value);
    },
    setScadaCaCertFileData(state, value) {
        state.scadaCaCertFileData = valueTransform.toString(value);
    },
};

export const actions = {
    async load({ commit }, id) {
        checkTypes.assert.nonEmptyString(id);

        const { _id, readOnly, scadaId, project, plainPkcs12Password, pkcs12File, commCaCertFile, scadaCaCertFile } = await this.$axios.$get(
            `/api/operating/certificates/enercon/scada/${id}`
        );

        commit('setId', _id);
        commit('setReadOnly', readOnly);
        commit('setScadaId', scadaId);
        // load values from backend -> required for form component that expects
        // a list of objects for a multi-select autocomplete select field
        const projectChoicesLoader = new ProjectChoicesLoader(this.$axios);
        const projects = await projectChoicesLoader.loadChoicesById(project);
        commit('setPlainPkcs12Password', plainPkcs12Password);
        commit('setPkcs12File', pkcs12File);
        commit('setCommCaCertFile', commCaCertFile);
        commit('setScadaCaCertFile', scadaCaCertFile);
    },
    async new({ state }) {
        return this.$axios.$post(
            '/api/operating/certificates/enercon/scada',
            cleanDeep({
                scadaId: state.scadaId,
                plainPkcs12Password: state.plainPkcs12Password,
                pkcs12FileData: state.pkcs12FileData,
                commCaCertFileData: state.commCaCertFileData,
                scadaCaCertFileData: state.scadaCaCertFileData,
            })
        );
    },
    async edit({ state }) {
        return this.$axios.$patch(
            `/api/operating/certificates/enercon/scada/${state.id}`,
            cleanDeep({
                plainPkcs12Password: state.plainPkcs12Password,
                pkcs12FileData: state.pkcs12FileData,
                commCaCertFileData: state.commCaCertFileData,
                scadaCaCertFileData: state.scadaCaCertFileData,
            })
        );
    },
};
