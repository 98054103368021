import AtsInstallationChoicesLoader from '@/helpers/choices/loaders/ats-installation-choices-loader';
import { IrBeaconTypeEnum } from '@/helpers/choices/wind-turbine/ir-beacon-type-enum';
import { LightingSystemInterfaceConceptEnum } from '@/helpers/choices/wind-turbine/lighting-system-interface-concept-enum';
import Merkator from 'openaip-merkator';
import checkTypes from 'check-types';
import cleanDeep from 'clean-deep';
import * as uuid from 'uuid';
import * as valueTransform from '@/helpers/value-transform';
import { ManufacturerEnum } from '@/helpers/choices/wind-turbine/manufacturer-enum';
import { getFormChoices as countryChoices } from '@/helpers/choices/countries';

const choices = {
    manufacturer: ManufacturerEnum.getFormChoices(),
    country: countryChoices(),
    lightingSystemInterfaceConcept: LightingSystemInterfaceConceptEnum.getFormChoices({ sort: false }),
    irBeaconType: IrBeaconTypeEnum.getFormChoices({ sort: false }),
    // below choices items are loaded dynamically and set with "updatedChoices" setter
    windPark: [],
    ctrlAtsInstallation: [],
    sensorAtsInstallation: [],
    operator: [],
    technicalContact: [],
    serviceCompany: [],
};

export const state = () => ({
    choices,
    id: null,
    readOnly: false,
    manufacturer: null,
    serialNumber: null,
    type: null,
    operatorIdent: null,
    commissioning: null,
    project: null,
    windPark: null,
    adlsGroups: {},
    country: null,
    actvtnSpaceRadius: null,
    actvtnSpaceHeightAboveObstacle: null,
    actvtnSpaceTopographyElevationMinHae: null,
    actvtnSpaceTopographyElevationMinMsl: null,
    actvtnSpaceTopographyElevationMaxHae: null,
    actvtnSpaceTopographyElevationMaxMsl: null,
    geometry: null,
    coordinate: null,
    hubHeight: null,
    rotorLength: null,
    elevationMsl: null,
    elevationHae: null,
    ctrlAtsInstallation: null,
    sensorAtsInstallation: null,
    configuration: null,
    intersectsNlfs: false,
    lightingSystemLatency: 4550,
    lightingSystemInterfaceConcept: null,
    irBeaconType: null,
    // to be able to add, update and delete items in the map, each item MUST have a unique key, i.e. a UUID v4
    responsibilities: {},
    remarks: null,
    extendedConfig: null,
});

export const mutations = {
    clear(state) {
        state.choices = choices;
        state.id = null;
        state.readOnly = false;
        state.manufacturer = null;
        state.serialNumber = null;
        state.type = null;
        state.commissioning = null;
        state.operatorIdent = null;
        state.project = null;
        state.windPark = null;
        state.adlsGroups = {};
        state.country = null;
        state.actvtnSpaceRadius = null;
        state.actvtnSpaceHeightAboveObstacle = null;
        state.geometry = null;
        // additional property that is required in view
        state.coordinate = null;
        state.hubHeight = null;
        state.rotorLength = null;
        state.elevationMsl = null;
        state.elevationHae = null;
        state.ctrlAtsInstallation = null;
        state.sensorAtsInstallation = null;
        state.configuration = null;
        state.intersectsNlfs = false;
        state.lightingSystemLatency = 4550;
        state.lightingSystemInterfaceConcept = null;
        state.irBeaconType = null;
        state.responsibilities = {};
        state.remarks = null;
    },
    setChoices(state, value) {
        checkTypes.assert.object(value);
        state.choices = value;
    },
    updateChoices(state, value) {
        checkTypes.assert.object(value);
        state.choices = { ...state.choices, ...value };
    },
    setId(state, value) {
        state.id = valueTransform.toString(value, { nullable: false });
    },
    setReadOnly(state, value) {
        state.readOnly = valueTransform.toBoolean(value);
    },
    setManufacturer(state, value) {
        state.manufacturer = valueTransform.toInteger(value);
    },
    setSerialNumber(state, value) {
        state.serialNumber = valueTransform.toString(value);
    },
    setProject(state, value) {
        state.project = valueTransform.toString(value);
    },
    setType(state, value) {
        state.type = valueTransform.toString(value);
    },
    setOperatorIdent(state, value) {
        state.operatorIdent = valueTransform.toString(value);
    },
    setCommissioning(state, value) {
        state.commissioning = valueTransform.toInteger(value);
    },
    setWindPark(state, value) {
        state.windPark = valueTransform.toString(value);
    },
    /**
     * @param {object} state
     * @param {[{name: string}]} value
     */
    setAdlsGroups(state, value) {
        value = value || [];
        state.adlsGroups = {};
        for (const item of value) {
            // create a unique key for each item in the list
            const key = uuid.v4();
            state.adlsGroups[key] = { key, value: item };
        }
    },
    /**
     * @param {object} state
     * @param {[{name: string}]} value
     */
    updateAdlsGroup(state, value) {
        const { key } = value;
        const updated = state.adlsGroups;
        updated[key] = value;
        state.adlsGroups = { ...updated };
    },
    /**
     * @param {object} state
     * @param {[{name: string}]} value
     */
    deleteAdlsGroup(state, value) {
        const { key } = value;
        const updated = state.adlsGroups;
        delete updated[key];
        state.adlsGroups = { ...updated };
    },
    setCountry(state, value) {
        state.country = valueTransform.toString(value);
    },
    setActvtnSpaceRadius(state, value) {
        state.actvtnSpaceRadius = valueTransform.toInteger(value);
    },
    setActvtnSpaceHeightAboveObstacle(state, value) {
        state.actvtnSpaceHeightAboveObstacle = valueTransform.toInteger(value);
    },
    setActvtnSpaceTopographyElevationMinMsl(state, value) {
        state.actvtnSpaceTopographyElevationMinMsl = valueTransform.toInteger(value);
    },
    setActvtnSpaceTopographyElevationMinHae(state, value) {
        state.actvtnSpaceTopographyElevationMinHae = valueTransform.toInteger(value);
    },
    setActvtnSpaceTopographyElevationMaxMsl(state, value) {
        state.actvtnSpaceTopographyElevationMaxMsl = valueTransform.toInteger(value);
    },
    setActvtnSpaceTopographyElevationMaxHae(state, value) {
        state.actvtnSpaceTopographyElevationMaxHae = valueTransform.toInteger(value);
    },
    setGeometry(state, value) {
        if (value != null) checkTypes.assert.nonEmptyObject(value);
        state.geometry = value;
    },
    setElevationMsl(state, value) {
        state.elevationMsl = valueTransform.toInteger(value);
    },
    setElevationHae(state, value) {
        state.elevationHae = valueTransform.toInteger(value);
    },
    setCoordinate(state, value) {
        state.coordinate = valueTransform.toString(value);
    },
    setHubHeight(state, value) {
        state.hubHeight = valueTransform.toInteger(value);
    },
    setRotorLength(state, value) {
        state.rotorLength = valueTransform.toInteger(value);
    },
    setCtrlAtsInstallation(state, value) {
        state.ctrlAtsInstallation = valueTransform.toString(value);
    },
    setSensorAtsInstallation(state, value) {
        if (value != null) checkTypes.assert.array.of.nonEmptyObject(value);
        state.sensorAtsInstallation = value;
    },
    setConfiguration(state, value) {
        if (value != null) checkTypes.assert.nonEmptyObject(value);
        state.configuration = value;
    },
    setIntersectsNlfs(state, value) {
        state.intersectsNlfs = valueTransform.toBoolean(value);
    },
    setLightingSystemLatency(state, value) {
        state.lightingSystemLatency = valueTransform.toInteger(value);
    },
    setLightingSystemInterfaceConcept(state, value) {
        state.lightingSystemInterfaceConcept = valueTransform.toInteger(value);
    },
    setIrBeaconType(state, value) {
        state.irBeaconType = valueTransform.toInteger(value);
    },
    setExtendedConfig(state, value) {
        value = value == null || value?.length === 0 ? null : value;
        state.extendedConfig = value;
    },
    /**
     * @param {object} state
     * @param {[{businessEntity: string, responsibility: integer}]} value
     */
    setResponsibilities(state, value) {
        value = value || [];
        state.responsibilities = {};
        for (const item of value) {
            // create a unique key for each item in the list
            const key = uuid.v4();
            state.responsibilities[key] = { key, value: item };
        }
    },
    /**
     * @param {object} state
     * @param {{key: string, value: {businessEntity: string, responsibility: integer}}} value
     */
    updateResponsibility(state, value) {
        const { key } = value;
        const updated = state.responsibilities;
        updated[key] = value;
        state.responsibilities = { ...updated };
    },
    /**
     * @param {object} state
     * @param {{key: string, value: {businessEntity: string, responsibility: integer}}} value
     */
    deleteResponsibility(state, value) {
        const { key } = value;
        const updated = state.responsibilities;
        delete updated[key];
        state.responsibilities = { ...updated };
    },
    setRemarks(state, value) {
        state.remarks = valueTransform.toString(value);
    },
};

export const actions = {
    async load({ commit }, id) {
        checkTypes.assert.nonEmptyString(id);

        const {
            _id,
            readOnly,
            manufacturer,
            serialNumber,
            type,
            commissioning,
            operatorIdent,
            project,
            windPark,
            adlsGroups,
            country,
            activationSpace: { radius: actvtnSpaceRadius, heightAboveObstacle: actvtnSpaceHeightAboveObstacle, topography },
            geometry,
            elevation: { msl, hae },
            hubHeight,
            rotorLength,
            ctrlAtsInstallation,
            sensorAtsInstallation,
            configuration,
            intersectsNlfs,
            lightingSystemLatency,
            irBeaconType,
            lightingSystemInterfaceConcept,
            responsibilities,
            remarks,
            extendedConfig,
        } = await this.$axios.$get(`/api/operating/wind-turbines/${id}`);

        const { elevationMin, elevationMax } = topography || {};
        const { msl: topographyElevationMinMsl, hae: topographyElevationMinHae } = elevationMin || {};
        const { msl: topographyElevationMaxMsl, hae: topographyElevationMaxHae } = elevationMax || {};

        commit('setId', _id);
        commit('setReadOnly', readOnly);
        commit('setManufacturer', manufacturer);
        commit('setSerialNumber', serialNumber);
        commit('setType', type);
        commit('setProject', project);
        commit('setWindPark', windPark);
        commit('setAdlsGroups', adlsGroups || []);
        commit('setOperatorIdent', operatorIdent);
        commit('setCommissioning', commissioning);
        commit('setCountry', country);
        commit('setCtrlAtsInstallation', ctrlAtsInstallation);

        // load values from backend -> required for form component that expects
        // a list of objects for a multi-select autocomplete select field
        const atsInstallationChoicesLoader = new AtsInstallationChoicesLoader(this.$axios);
        const sensorAtsInstallations = await atsInstallationChoicesLoader.loadChoicesById(sensorAtsInstallation);
        commit('setSensorAtsInstallation', sensorAtsInstallations);
        commit('setActvtnSpaceRadius', actvtnSpaceRadius);
        commit('setActvtnSpaceHeightAboveObstacle', actvtnSpaceHeightAboveObstacle);
        commit('setActvtnSpaceTopographyElevationMinMsl', topographyElevationMinMsl);
        commit('setActvtnSpaceTopographyElevationMinHae', topographyElevationMinHae);
        commit('setActvtnSpaceTopographyElevationMaxMsl', topographyElevationMaxMsl);
        commit('setActvtnSpaceTopographyElevationMaxHae', topographyElevationMaxHae);
        // location - set store values
        commit('setGeometry', geometry);
        commit('setElevationMsl', msl);
        commit('setElevationHae', hae);
        // calculate coordinates
        // coordinates is only required in views and is not
        // related to the incoming model but must be set during normalization
        let merkator = new Merkator();
        merkator.readCoord(geometry.coordinates[0], geometry.coordinates[1]);
        commit('setCoordinate', merkator.toDecimal());
        commit('setHubHeight', hubHeight);
        commit('setRotorLength', rotorLength);
        commit('setConfiguration', configuration);
        commit('setIntersectsNlfs', intersectsNlfs);
        commit('setLightingSystemLatency', lightingSystemLatency);
        commit('setLightingSystemInterfaceConcept', lightingSystemInterfaceConcept);
        commit('setIrBeaconType', irBeaconType);
        commit('setResponsibilities', responsibilities || []);
        commit('setRemarks', remarks);
        commit('setExtendedConfig', extendedConfig == null ? extendedConfig : JSON.stringify(extendedConfig, null, 4));
    },
    async new({ getters, state }) {
        return this.$axios.$post('/api/operating/wind-turbines', cleanDeep(_denormalize({ state, getters })));
    },
    async edit({ getters, state }) {
        return this.$axios.$patch(`/api/operating/wind-turbines/${state.id}`, _denormalize({ getters, state }));
    },
};

function _denormalize({ getters, state }) {
    // IMPORTANT base denormalization does not handle "name" property" which is only used for "new" but is not allowed in "edit" context
    return {
        project: state.project,
        manufacturer: state.manufacturer,
        serialNumber: state.serialNumber,
        type: state.type,
        operatorIdent: state.operatorIdent,
        commissioning: state.commissioning,
        windPark: state.windPark,
        adlsGroups: _denormalizeAdlsGroups(state.adlsGroups),
        country: state.country,
        activationSpace: {
            radius: state.actvtnSpaceRadius,
            heightAboveObstacle: state.actvtnSpaceHeightAboveObstacle,
            topography: {
                elevationMin: {
                    msl: state.actvtnSpaceTopographyElevationMinMsl,
                    hae: state.actvtnSpaceTopographyElevationMinHae,
                },
                elevationMax: {
                    msl: state.actvtnSpaceTopographyElevationMaxMsl,
                    hae: state.actvtnSpaceTopographyElevationMaxHae,
                },
            },
        },
        geometry: {
            type: state.geometry.type,
            coordinates: state.geometry.coordinates,
        },
        elevation: { msl: state.elevationMsl, hae: state.elevationHae },
        hubHeight: state.hubHeight,
        rotorLength: state.rotorLength,
        ctrlAtsInstallation: state.ctrlAtsInstallation,
        // endpoint expects either null or array of document IDs
        sensorAtsInstallation: state.sensorAtsInstallation == null ? null : state.sensorAtsInstallation.map((value) => value._id),
        intersectsNlfs: state.intersectsNlfs,
        lightingSystemLatency: state.lightingSystemLatency,
        lightingSystemInterfaceConcept: state.lightingSystemInterfaceConcept,
        irBeaconType: state.irBeaconType,
        responsibilities: _denormalizeResponsibilities(state.responsibilities),
        remarks: state.remarks,
        extendedConfig: state.extendedConfig == null ? state.extendedConfig : JSON.parse(state.extendedConfig),
    };
}

/**
 * Transforms internally used responsibilities structure into a list of responsibility objects.
 *
 * @param {object} responsibilities
 * @returns {array}
 * @private
 */
function _denormalizeResponsibilities(responsibilities) {
    if (responsibilities == null) return null;

    return Object.values(responsibilities).map((value) => value.value);
}

/**
 * Transforms internally used ADLS groups structure into a list of ADLS group objects.
 *
 * @param {object} adlsGroups
 * @returns {array}
 * @private
 */
function _denormalizeAdlsGroups(adlsGroups) {
    if (adlsGroups == null) return null;

    return Object.values(adlsGroups).map((value) => value.value);
}
