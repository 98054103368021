import checkTypes from 'check-types';

export const state = () => ({
    ats: {},
});

export const mutations = {
    clear(state) {
        state.ats = {};
    },
    setAts(state, value) {
        state.ats = value;
    },
};

export const actions = {
    async load({ commit }, id) {
        checkTypes.assert.nonEmptyString(id);

        const ats = await this.$axios.$get(`/api/operating/ats/${id}`, {
            params: {
                fields: [
                    '_id',
                    'kind',
                    'readOnly',
                    'deviceId',
                    'partNumber',
                    'serialNumber',
                    'builderName',
                    'buildDate',
                    'router1._id',
                    'router1.serialNumber',
                    'router2._id',
                    'router2.serialNumber',
                    'cpuModule._id',
                    'cpuModule.serialNumber',
                    'ioModule._id',
                    'ioModule.serialNumber',
                    'housing',
                    'mountingPlate',
                    'powerSupply',
                    'heating',
                    'relays',
                    'lvl2TermBlock',
                    'lvl3TermBlock',
                    'circuitBreaker',
                    'vpu',
                    'termBlockBlue',
                    'termBlockGrey',
                    'earthLeadTerm',
                    'connector30Pin',
                    'connector10Pin',
                    'rj45Coupler',
                    'flangePlate',
                    'antennaCableLte',
                    'dsub3WayCable',
                    'dsubCableGreen',
                    'rj45CableOrange025m',
                    'rj45CableRed025m',
                    'rj45CableBlue025m',
                    'rj45CableRed05m',
                    'rj45CableBlue05m',
                    'remarks',
                    'createdAt',
                    'updatedAt',
                    'createdBy',
                    'updatedBy',
                ].join(','),
                populateRefs: true,
            },
        });

        commit('setAts', ats);
    },
    async delete({ state }, id) {
        await this.$axios.$delete(`/api/operating/ats/${id}`);
    },
};
