/**
 * Simply creates a new instance of a Javascript Map instance.
 *
 * @param {Map} mapInstance
 * @return {Map}
 */
export default function newMapInstance(mapInstance) {
    // create a new map and set new property value to trigger state update
    const updatedMap = new Map();
    for (const [key, val] of mapInstance.entries()) {
        updatedMap.set(key, val);
    }

    return updatedMap;
}
