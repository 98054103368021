import checkTypes from 'check-types';
import { LIST_ITEMS_PER_PAGE_DEFAULT } from '@/globals';
import * as valueTransform from '@/helpers/value-transform';
import cleanDeep from 'clean-deep';

const choices = {
    country: [{ text: 'Germany', value: 'DE' }],
};

export const state = () => ({
    choices,
    items: new Map(),
    totalItems: 0,
    page: 1,
    limit: LIST_ITEMS_PER_PAGE_DEFAULT,
    sortBy: null,
    sortDesc: null,
    filterCountry: null,
    filterPending: null,
});

export const getters = {
    getItem: (state) => (id) => {
        return state.items.get(id);
    },
    getItems: (state) => {
        // IMPORTANT make sure to return an array calling values which will only return list of values and not a "keyed valued"
        return Array.from(state.items.values());
    },
    hasSpecialFilters: (state) => {
        return (
            Object.values(
                cleanDeep({
                    country: state.filterCountry,
                    pending: state.filterPending || undefined,
                })
            ).length > 0
        );
    },
};

export const mutations = {
    clear(state) {
        state.choices = choices;
        state.items = new Map();
        state.page = 1;
        state.limit = LIST_ITEMS_PER_PAGE_DEFAULT;
        state.sortBy = null;
        state.sortDesc = null;
        state.filterCountry = null;
        state.filterPending = null;
    },
    setChoices(state, value) {
        checkTypes.assert.object(value);
        state.choices = value;
    },
    updateChoices(state, value) {
        checkTypes.assert.object(value);
        const choices = { ...state.choices };
        state.choices = Object.assign(choices, value);
    },
    setPage(state, value) {
        state.page = valueTransform.toInteger(value, { nullable: false });
    },
    setLimit(state, value) {
        state.limit = valueTransform.toInteger(value, { nullable: false });
    },
    setSortBy(state, value) {
        state.sortBy = valueTransform.toString(value);
    },
    setSortDesc(state, value) {
        state.sortDesc = valueTransform.toBoolean(value, { nullable: true });
    },
    setFilterCountry(state, value) {
        state.filterCountry = valueTransform.toStringList(value);
    },
    setFilterPending(state, value) {
        state.filterPending = valueTransform.toBoolean(value, { nullable: true });
    },
    setTotalItems(state, value) {
        state.totalItems = valueTransform.toInteger(value, { nullable: false });
    },
    setItems(state, value) {
        if (value != null) checkTypes.assert.array.of.nonEmptyObject(value);
        value = value || [];
        state.items = new Map();
        value.forEach(function (item) {
            state.items.set(item._id, item);
        });
        // IMPORTANT change state by setting new Map since simply calling "set" directly won't propagate state change
        state.items = new Map(state.items.entries());
    },
    removeItem(state, value) {
        checkTypes.assert.nonEmptyString(value);
        state.items.delete(value);
        // IMPORTANT change state by setting new Map since simply calling "set" directly won't propagate state change
        state.items = new Map(state.items.entries());
    },
    clearSpecialFilters(state) {
        state.filterCountry = null;
        state.filterPending = null;
    },
};

export const actions = {
    async load({ commit, state }) {
        // append query parameters defined by selected filter values
        const { items, totalCount } = await this.$axios.$get('/api/tiles/data/mobile-coverages', {
            params: {
                page: state.page,
                limit: state.limit,
                sortBy: state.sortBy,
                sortDesc: state.sortDesc,
                country: state.filterCountry,
                pending: state.filterPending,
                fields: '_id,country,part,filename,success,pending,importedItemCount,importErrors,createdAt,finishedAt',
            },
        });
        commit('setTotalItems', totalCount);
        commit('setItems', items);
    },
    async delete({ state }, id) {
        await this.$axios.$delete(`/api/tiles/data/mobile-coverages/${id}`);
    },
};
