<template>
    <v-menu id="app-switcher" offset-y left :close-on-content-click="false">
        <template v-slot:activator="{ on }">
            <v-btn id="app-switcher-btn" v-on="on">
                <v-icon small :class="$vuetify.breakpoint.smAndUp ? 'mr-4' : 'mr-0'">$vuetify.icons.fluxCapacitor</v-icon>
                <span v-show="$vuetify.breakpoint.smAndUp">{{ appName }}</span>
            </v-btn>
        </template>
        <v-list v-if="availableApps.length > 0" id="app-list">
            <v-list-item v-for="app in availableApps" :key="app.name" :link="true" :href="app.url">
                <v-list-item-avatar>
                    <v-icon small class="mr-4">{{ app.icon }}</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title>{{ app.name }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
import rbacMeta from '@/mixins/rbac-meta';
import { APP_NAME } from '@/globals';

export default {
    name: 'AppSwitcher',
    mixins: [rbacMeta],
    computed: {
        appName() {
            return APP_NAME;
        },
        availableApps() {
            const apps = [
                {
                    name: 'Monitoring',
                    url: this.$config.MONITORING_WEBAPP_URL,
                    reqPermissions: [this.permissions.MONITORING_APP_ACCESS],
                    icon: '$vuetify.icons.monitorWaveform',
                },
                {
                    name: 'API Documentation',
                    url: this.$config.DOCUMENTATION_WEBAPP_URL,
                    reqPermissions: [],
                    icon: '$vuetify.icons.book',
                },
            ];

            return apps.filter((value) => this.authUser.hasPermission(value.reqPermissions));
        },
    },
};
</script>

<style scoped lang="scss">
#app-switcher-btn {
    background-color: #002925 !important;
}

#app-list {
    margin-top: 6px;
}
</style>
