import checkTypes from 'check-types';
import { ManufacturerEnum } from '@/helpers/choices/wind-turbine/manufacturer-enum';

/**
 * @param {{id: string, manufacturer: integer, serialNumber: string, operatorIdent: string}} meta
 * @return {string|null}
 */

export default function getWindTurbineName(meta) {
    const { id, manufacturer, serialNumber, operatorIdent } = meta || {};

    if (id) checkTypes.assert.nonEmptyString(id);
    if (manufacturer) checkTypes.assert.integer(manufacturer);
    if (serialNumber) checkTypes.assert.nonEmptyString(serialNumber);
    if (operatorIdent) checkTypes.assert.nonEmptyString(operatorIdent);

    let name = '';
    let manufacturerName = ManufacturerEnum.getName(manufacturer);

    if (operatorIdent) {
        name += operatorIdent;
        if (manufacturerName != null && serialNumber != null) {
            name += ` (${manufacturerName}-${serialNumber})`;
        }
    } else {
        if (manufacturerName != null && serialNumber != null) {
            name = manufacturerName + '-' + serialNumber;
        } else if (id != null) {
            name = id.substring(0, 7);
        }
    }

    return name;
}
