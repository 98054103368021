import checkTypes from 'check-types';
import getWindTurbineName from '@/helpers/get-wind-turbine-name';
import { isEmpty } from 'lodash';

export const state = () => ({
    atsInstallation: {},
    windTurbines: [],
    mobileCoverage: {},
});

export const mutations = {
    clear(state) {
        state.atsInstallation = {};
        state.windTurbines = [];
        state.mobileCoverage = {};
    },
    setAtsInstallation(state, value) {
        checkTypes.assert.nonEmptyObject(value);
        state.atsInstallation = value;
    },
    setWindTurbines(state, value) {
        checkTypes.assert.array.of.nonEmptyObject(value);
        state.windTurbines = value;
    },
    setMobileCoverage(state, value) {
        if (value != null) checkTypes.assert.nonEmptyObject(value);
        state.mobileCoverage = value;
    },
};

export const actions = {
    async loadMetadata({ commit }, id) {
        checkTypes.assert.nonEmptyString(id);

        const atsInstallation = await this.$axios.$get(`/api/operating/ats-installations/${id}`, {
            params: {
                fields: ['_id', 'name', 'ats._id', 'ats.serialNumber', 'ats.deviceId', 'geometry', 'elevation', 'operationMode'].join(','),
                populateRefs: true,
            },
        });
        // load wind turbines attached to ATS installation
        const { items: windTurbines } = await this.$axios.$get('/api/operating/wind-turbines', {
            params: {
                fields: ['_id', 'manufacturer', 'serialNumber', 'operatorIdent', 'type', 'windPark._id', 'windPark.name', 'geometry', 'elevation'].join(','),
                atsInstallation: id,
                populateRefs: true,
            },
        });

        // inject a custom wind turbine name for viewing purposes
        windTurbines.forEach((value) => {
            value.name = getWindTurbineName({
                id: value._id,
                manufacturer: value.manufacturer,
                serialNumber: value.serialNumber,
                operatorIdent: value.operatorIdent,
                type: value.type,
            });
        });

        const [lon, lat] = atsInstallation.geometry.coordinates;
        const mobileCoverage = await this.$axios.$get(`/api/operating/utils/mobile-coverage`, {
            params: {
                coordinates: `${lat},${lon}`,
            },
        });

        commit('setAtsInstallation', atsInstallation);
        commit('setWindTurbines', windTurbines);
        commit('setMobileCoverage', isEmpty(mobileCoverage) ? null : mobileCoverage);
    },
};
