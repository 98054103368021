import { Enum } from '@/helpers/choices/enum';

export class IrBeaconTypeEnum extends Enum {}

IrBeaconTypeEnum.values = {
    ENERTRAG_PRO100XS_IR_AVV_LS219: 0,
    ROBURWIND_REETEC_RE_LED2_IR: 1,
    LANTHAN_TWE_R100IR25R0_G4: 2,
    LANTHAN_TWE_R100IR25R0_MB: 3,
    LANTHAN_TWE_R100IR25R1_G4: 4,
    LANTHAN_TWE_R100IR25R1_MB: 5,
    OBELUX_LIT10AC230_LI_10_DCW_F: 6,
    ORGA_L240_GFW_IRG_G: 7,
    QUANTEC_MAGO_NERI_IR: 8,
    QUANTEC_SIRIUS_R4: 9,
    QUANTEC_QFW3IR_TRAFFILOWIR_LS218: 10,
    QUANTEC_W_ROT_TRAFI_LOWIR_MESH: 11,
    BALLASCHK_AOL_306_2016_W_ES: 12,
    OBELUX_LI_WES_DC_GAM: 13,
    ENERTRAG_PROIR_AVV_RETROFIT_KIT: 14,
    BALLASCHK_AOL303_ICAO_A_WROT_ES_IR25: 15,
    ORGA_L550_GFW_ES_IRG_G: 16,
};

IrBeaconTypeEnum.names = {
    0: 'Enertrag-pro100xs IR AVV-LS219',
    1: 'RoburWind-Reetec-RE-LED2-IR',
    2: 'Lanthan-TWE-R100IR25r0-G4',
    3: 'Lanthan-TWE-R100IR25r0-MB',
    4: 'Lanthan-TWE-R100IR25r1-G4',
    5: 'Lanthan-TWE-R100IR25r1-MB',
    6: "Obelux - LIT 10-AC230 - LI-10-DCW-F (DEPRECATED/DON'T USE)",
    7: 'ORGA-L240-GFW-IRG-G',
    8: 'Quantec-Mago_Neri-IR',
    9: 'Quantec-Sirius_R4',
    10: 'Quantec-QFW3IR-TRAFFI low IR-LS218',
    11: 'Quantec-W,rot ES, Trafi low IR, Mesh',
    12: 'Ballaschk-AOL 306.2016-W ES/IR/H',
    13: 'Obelux LI-WES-DC-GAM',
    14: 'Enertrag - proIR AVV Retrofit Kit',
    15: 'Ballaschk-AOL 303 ICAO A_Wrot ES_IR25',
    16: 'Orga-L550-GFW-ES-IRG-G',
};

Object.freeze(IrBeaconTypeEnum);
