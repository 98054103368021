import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const VUEX_PROPERTIES = ['state', 'getters', 'actions', 'mutations']

let store = {};

(function updateModules () {
  store = normalizeRoot(require('../store/index.js'), 'store/index.js')

  // If store is an exported method = classic mode (deprecated)

  // Enforce store modules
  store.modules = store.modules || {}

  resolveStoreModules(require('../store/adlsGroup/edit.js'), 'adlsGroup/edit.js')
  resolveStoreModules(require('../store/adlsGroup/list.js'), 'adlsGroup/list.js')
  resolveStoreModules(require('../store/atm/list.js'), 'atm/list.js')
  resolveStoreModules(require('../store/atm/view.js'), 'atm/view.js')
  resolveStoreModules(require('../store/ats/list.js'), 'ats/list.js')
  resolveStoreModules(require('../store/ats/status.js'), 'ats/status.js')
  resolveStoreModules(require('../store/atsInstallation/edit.js'), 'atsInstallation/edit.js')
  resolveStoreModules(require('../store/atsInstallation/linkedTurbines.js'), 'atsInstallation/linkedTurbines.js')
  resolveStoreModules(require('../store/atsInstallation/list.js'), 'atsInstallation/list.js')
  resolveStoreModules(require('../store/atsInstallation/view.js'), 'atsInstallation/view.js')
  resolveStoreModules(require('../store/businessEntity/edit.js'), 'businessEntity/edit.js')
  resolveStoreModules(require('../store/businessEntity/list.js'), 'businessEntity/list.js')
  resolveStoreModules(require('../store/businessEntity/view.js'), 'businessEntity/view.js')
  resolveStoreModules(require('../store/changeHistory/view.js'), 'changeHistory/view.js')
  resolveStoreModules(require('../store/import/draftWindTurbines.js'), 'import/draftWindTurbines.js')
  resolveStoreModules(require('../store/map/layerFilters.js'), 'map/layerFilters.js')
  resolveStoreModules(require('../store/map/map.js'), 'map/map.js')
  resolveStoreModules(require('../store/map/selection.js'), 'map/selection.js')
  resolveStoreModules(require('../store/map/settings.js'), 'map/settings.js')
  resolveStoreModules(require('../store/project/edit.js'), 'project/edit.js')
  resolveStoreModules(require('../store/project/list.js'), 'project/list.js')
  resolveStoreModules(require('../store/project/view.js'), 'project/view.js')
  resolveStoreModules(require('../store/systemMessage/edit.js'), 'systemMessage/edit.js')
  resolveStoreModules(require('../store/systemMessage/list.js'), 'systemMessage/list.js')
  resolveStoreModules(require('../store/user/settings.js'), 'user/settings.js')
  resolveStoreModules(require('../store/user/usersMetadata.js'), 'user/usersMetadata.js')
  resolveStoreModules(require('../store/windPark/edit.js'), 'windPark/edit.js')
  resolveStoreModules(require('../store/windPark/list.js'), 'windPark/list.js')
  resolveStoreModules(require('../store/windTurbine/edit.js'), 'windTurbine/edit.js')
  resolveStoreModules(require('../store/windTurbine/list.js'), 'windTurbine/list.js')
  resolveStoreModules(require('../store/windTurbine/view.js'), 'windTurbine/view.js')
  resolveStoreModules(require('../store/admin/iam/edit.js'), 'admin/iam/edit.js')
  resolveStoreModules(require('../store/admin/iam/list.js'), 'admin/iam/list.js')
  resolveStoreModules(require('../store/atm/atm1/edit.js'), 'atm/atm1/edit.js')
  resolveStoreModules(require('../store/atm/atm2/edit.js'), 'atm/atm2/edit.js')
  resolveStoreModules(require('../store/atm/atm4/edit.js'), 'atm/atm4/edit.js')
  resolveStoreModules(require('../store/atm/atm5/edit.js'), 'atm/atm5/edit.js')
  resolveStoreModules(require('../store/atm/atm6/edit.js'), 'atm/atm6/edit.js')
  resolveStoreModules(require('../store/ats/ats3/edit.js'), 'ats/ats3/edit.js')
  resolveStoreModules(require('../store/ats/ats3/view.js'), 'ats/ats3/view.js')
  resolveStoreModules(require('../store/ats/ats4/edit.js'), 'ats/ats4/edit.js')
  resolveStoreModules(require('../store/ats/ats4/view.js'), 'ats/ats4/view.js')
  resolveStoreModules(require('../store/atsInstallation/maintenanceAction/edit.js'), 'atsInstallation/maintenanceAction/edit.js')
  resolveStoreModules(require('../store/atsInstallation/maintenanceAction/view.js'), 'atsInstallation/maintenanceAction/view.js')
  resolveStoreModules(require('../store/draft/windTurbine/edit.js'), 'draft/windTurbine/edit.js')
  resolveStoreModules(require('../store/draft/windTurbine/list.js'), 'draft/windTurbine/list.js')
  resolveStoreModules(require('../store/draft/windTurbine/view.js'), 'draft/windTurbine/view.js')
  resolveStoreModules(require('../store/map/poi/atsInstallation.js'), 'map/poi/atsInstallation.js')
  resolveStoreModules(require('../store/map/poi/draftWindTurbine.js'), 'map/poi/draftWindTurbine.js')
  resolveStoreModules(require('../store/map/poi/windPark.js'), 'map/poi/windPark.js')
  resolveStoreModules(require('../store/map/poi/windTurbine.js'), 'map/poi/windTurbine.js')
  resolveStoreModules(require('../store/map/tools/draftTool.js'), 'map/tools/draftTool.js')
  resolveStoreModules(require('../store/map/tools/filterTool.js'), 'map/tools/filterTool.js')
  resolveStoreModules(require('../store/project/commissioning/list.js'), 'project/commissioning/list.js')
  resolveStoreModules(require('../store/project/commissioning/view.js'), 'project/commissioning/view.js')
  resolveStoreModules(require('../store/project/documents/projectCertification.js'), 'project/documents/projectCertification.js')
  resolveStoreModules(require('../store/project/documents/view.js'), 'project/documents/view.js')
  resolveStoreModules(require('../store/project/viewshed/list.js'), 'project/viewshed/list.js')
  resolveStoreModules(require('../store/project/viewshed/new.js'), 'project/viewshed/new.js')
  resolveStoreModules(require('../store/tmp/commissioning/process.js'), 'tmp/commissioning/process.js')
  resolveStoreModules(require('../store/tmp/projectInformation/view.js'), 'tmp/projectInformation/view.js')
  resolveStoreModules(require('../store/user/profile/edit.js'), 'user/profile/edit.js')
  resolveStoreModules(require('../store/user/profile/serviceAccounts.js'), 'user/profile/serviceAccounts.js')
  resolveStoreModules(require('../store/windTurbine/bnkConfiguration/edit.js'), 'windTurbine/bnkConfiguration/edit.js')
  resolveStoreModules(require('../store/windTurbine/turbineConfiguration/edit.js'), 'windTurbine/turbineConfiguration/edit.js')
  resolveStoreModules(require('../store/admin/system/mobileCoverageData/list.js'), 'admin/system/mobileCoverageData/list.js')
  resolveStoreModules(require('../store/certificate/enercon/scada/edit.js'), 'certificate/enercon/scada/edit.js')
  resolveStoreModules(require('../store/certificate/enercon/scada/list.js'), 'certificate/enercon/scada/list.js')
  resolveStoreModules(require('../store/certificate/enercon/scada/view.js'), 'certificate/enercon/scada/view.js')
  resolveStoreModules(require('../store/draft/windTurbine/migrate/businessEntities.js'), 'draft/windTurbine/migrate/businessEntities.js')
  resolveStoreModules(require('../store/draft/windTurbine/migrate/projects.js'), 'draft/windTurbine/migrate/projects.js')
  resolveStoreModules(require('../store/draft/windTurbine/migrate/windParks.js'), 'draft/windTurbine/migrate/windParks.js')
  resolveStoreModules(require('../store/draft/windTurbine/migrate/windTurbines.js'), 'draft/windTurbine/migrate/windTurbines.js')

  // If the environment supports hot reloading...
})()

// createStore
export const createStore = store instanceof Function ? store : () => {
  return new Vuex.Store(Object.assign({
    strict: (process.env.NODE_ENV !== 'production')
  }, store))
}

function normalizeRoot (moduleData, filePath) {
  moduleData = moduleData.default || moduleData

  if (moduleData.commit) {
    throw new Error(`[nuxt] ${filePath} should export a method that returns a Vuex instance.`)
  }

  if (typeof moduleData !== 'function') {
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData)
  }
  return normalizeModule(moduleData, filePath)
}

function normalizeModule (moduleData, filePath) {
  if (moduleData.state && typeof moduleData.state !== 'function') {
    console.warn(`'state' should be a method that returns an object in ${filePath}`)

    const state = Object.assign({}, moduleData.state)
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData, { state: () => state })
  }
  return moduleData
}

function resolveStoreModules (moduleData, filename) {
  moduleData = moduleData.default || moduleData
  // Remove store src + extension (./foo/index.js -> foo/index)
  const namespace = filename.replace(/\.(js|mjs)$/, '')
  const namespaces = namespace.split('/')
  let moduleName = namespaces[namespaces.length - 1]
  const filePath = `store/${filename}`

  moduleData = moduleName === 'state'
    ? normalizeState(moduleData, filePath)
    : normalizeModule(moduleData, filePath)

  // If src is a known Vuex property
  if (VUEX_PROPERTIES.includes(moduleName)) {
    const property = moduleName
    const propertyStoreModule = getStoreModule(store, namespaces, { isProperty: true })

    // Replace state since it's a function
    mergeProperty(propertyStoreModule, moduleData, property)
    return
  }

  // If file is foo/index.js, it should be saved as foo
  const isIndexModule = (moduleName === 'index')
  if (isIndexModule) {
    namespaces.pop()
    moduleName = namespaces[namespaces.length - 1]
  }

  const storeModule = getStoreModule(store, namespaces)

  for (const property of VUEX_PROPERTIES) {
    mergeProperty(storeModule, moduleData[property], property)
  }

  if (moduleData.namespaced === false) {
    delete storeModule.namespaced
  }
}

function normalizeState (moduleData, filePath) {
  if (typeof moduleData !== 'function') {
    console.warn(`${filePath} should export a method that returns an object`)
    const state = Object.assign({}, moduleData)
    return () => state
  }
  return normalizeModule(moduleData, filePath)
}

function getStoreModule (storeModule, namespaces, { isProperty = false } = {}) {
  // If ./mutations.js
  if (!namespaces.length || (isProperty && namespaces.length === 1)) {
    return storeModule
  }

  const namespace = namespaces.shift()

  storeModule.modules[namespace] = storeModule.modules[namespace] || {}
  storeModule.modules[namespace].namespaced = true
  storeModule.modules[namespace].modules = storeModule.modules[namespace].modules || {}

  return getStoreModule(storeModule.modules[namespace], namespaces, { isProperty })
}

function mergeProperty (storeModule, moduleData, property) {
  if (!moduleData) {
    return
  }

  if (property === 'state') {
    storeModule.state = moduleData || storeModule.state
  } else {
    storeModule[property] = Object.assign({}, storeModule[property], moduleData)
  }
}
