<template>
    <v-app>
        <v-main id="error-page" :class="['wallpaper', imageClass]">
            <v-container class="fill-height">
                <v-row align="center" justify="center">
                    <v-container>
                        <v-row align="center" justify="center">
                            <div class="error-status-code">{{ statusCode }}</div>
                        </v-row>
                        <v-row align="center" justify="center">
                            <p class="error-message">
                                {{ statusText }}
                            </p>
                        </v-row>
                        <v-row align="center" justify="center">
                            <v-btn class="primary ma-8" :nuxt="true" to="/">
                                <v-icon small class="mr-2">$vuetify.icons.rocket</v-icon>
                                Take me back home
                            </v-btn>
                            <v-btn class="primary ma-8" @click="logout()">
                                <v-icon small class="mr-2">$vuetify.icons.rightFromBracket</v-icon>
                                Logout
                            </v-btn>
                        </v-row>
                        <v-row align="center" justify="center"
                            ><span
                                >Obviously, something is wrong on our side. If you want, please
                                <a href="mailto:info@lanthan-safe-sky.com">write us an email</a> and tell us what you did when this error happened.</span
                            ></v-row
                        >
                    </v-container>
                </v-row>
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
import { getReasonPhrase } from 'http-status-codes';

export default {
    name: 'Error',
    layout: 'full-canvas',
    props: {
        error: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            imageClass: null,
        };
    },
    computed: {
        statusCode() {
            return parseInt(this.$props.error.statusCode);
        },
        statusText() {
            const { statusCode, statusText } = this.$props.error;

            if (statusText != null) {
                return decodeURIComponent(statusText);
            }

            switch (statusCode) {
                case 404:
                    return 'The page that you are looking for has been removed, deleted or may have never existed.';
                case 403:
                    return 'You are not authorized to access this page';
                default:
                    // default error messages for all other errors
                    return getReasonPhrase(statusCode);
            }
        },
    },
    created() {
        this.setImageClass();
        // keep previous route for consecutive page reloads
    },
    methods: {
        async logout() {
            await this.$router.push('/logout');
        },
        setImageClass() {
            // randomly choosing image will lead to image flickering when loading the
            // error page. To prevent this, rotate images every six minutes
            const now = new Date();
            let min = now.getMinutes();
            min = min === 0 ? 1 : min;
            this.imageClass = 'theme-' + Math.ceil((min * 10) / 60);
        },
    },
};
</script>

<style scoped lang="scss">
#error-page {
    color: white;
}

.wallpaper {
    background-size: cover;
    height: 100%;
}

.error-status-code {
    font-size: 7em;
}

.error-message {
    font-size: 2em;
}

.theme-1 {
    background-image: url('~static/images/canvas_image_1.jpg');
}

.theme-2 {
    background-image: url('~static/images/canvas_image_2.jpg');
}

.theme-3 {
    background-image: url('~static/images/canvas_image_3.jpg');
}

.theme-4 {
    background-image: url('~static/images/canvas_image_4.jpg');
}

.theme-5 {
    background-image: url('~static/images/canvas_image_5.jpg');
}

.theme-6 {
    background-image: url('~static/images/canvas_image_6.jpg');
}

.theme-7 {
    background-image: url('~static/images/canvas_image_7.jpg');
}

.theme-8 {
    background-image: url('~static/images/canvas_image_8.jpg');
}

.theme-9 {
    background-image: url('~static/images/canvas_image_9.jpg');
}

.theme-10 {
    background-image: url('~static/images/canvas_image_10.jpg');
}
</style>
