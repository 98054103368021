import checkTypes from 'check-types';

export const state = () => ({
    project: null,
    items: new Map(),
});

export const getters = {
    getItems: (state) => {
        // IMPORTANT make sure to return an array calling values which will only return list of values and not a "keyed valued"
        return Array.from(state.items.values());
    },
};

export const mutations = {
    clear(state) {
        state.project = null;
        state.items = new Map();
    },
    setProject(state, value) {
        if (value) checkTypes.assert.nonEmptyObject(value);
        state.project = value;
    },
    setItems(state, value) {
        value = value || [];
        state.items = new Map();
        value.forEach(function (item) {
            state.items.set(item._id, item);
        });
        // IMPORTANT change state by setting new Map since simply calling "set" directly won't propagate state change
        state.items = new Map(state.items.entries());
    },
};

export const actions = {
    async load({ commit }, projectId) {
        checkTypes.assert.nonEmptyString(projectId);

        const { items } = await this.$axios.$get(`/api/operating/viewsheds`, {
            params: { project: projectId },
        });
        commit('setItems', items);
    },
    async loadProject({ commit }, id) {
        checkTypes.assert.nonEmptyString(id);

        const project = await this.$axios.$get(`/api/operating/projects/${id}`);
        commit('setProject', project);
    },
    async delete({ state }, id) {
        await this.$axios.$delete(`/api/operating/viewsheds/${id}`);
    },
};
