import checkTypes from 'check-types';
import { DateTime } from 'luxon';
import { DATE_FORMAT } from '@/globals';

export const state = () => ({
    atsInstallation: {},
    maintenanceActions: new Map(),
});

export const getters = {
    getMaintenanceActions: (state) => {
        // IMPORTANT make sure to return an array calling values which will only return list of values and not a "keyed valued"
        let list = Array.from(state.maintenanceActions.values());
        // sort list depending on date and timezone
        list.sort((a, b) => {
            const dateA = DateTime.fromFormat(a.date, DATE_FORMAT, { zone: a.timezone });
            const dateB = DateTime.fromFormat(b.date, DATE_FORMAT, { zone: b.timezone });

            if (dateA < dateB) {
                return -1;
            }
            if (dateA > dateB) {
                return 1;
            }
            return 0;
        });

        return list;
    },
};

export const mutations = {
    clear(state) {
        state.atsInstallation = null;
        state.maintenanceActions = new Map();
    },
    setAtsInstallation(state, value) {
        checkTypes.assert.nonEmptyObject(value);
        state.atsInstallation = value;
    },
    setMaintenanceActions(state, value) {
        value = value || [];
        state.maintenanceActions = new Map();
        value.forEach(function (item) {
            state.maintenanceActions.set(item._id, item);
        });
        // IMPORTANT change state by setting new Map since simply calling "set" directly won't propagate state change
        state.maintenanceActions = new Map(state.maintenanceActions.entries());
    },
    removeMaintenanceAction(state, value) {
        checkTypes.assert.nonEmptyString(value);
        state.maintenanceActions.delete(value);
        // IMPORTANT change state by setting new Map since simply calling "set" directly won't propagate state change
        state.maintenanceActions = new Map(state.maintenanceActions.entries());
    },
};

export const actions = {
    async load({ commit }, id) {
        checkTypes.assert.nonEmptyString(id);

        const atsInstallation = await this.$axios.$get(`/api/operating/ats-installations/${id}`);

        commit('setAtsInstallation', atsInstallation);
        commit('setMaintenanceActions', atsInstallation.maintenanceActions ?? []);
    },
    async delete({ state }, { atsInstallationId, maintenanceActionId }) {
        await this.$axios.$delete(`/api/operating/ats-installations/${atsInstallationId}/maintenance-actions/${maintenanceActionId}`);
    },
};
