<template>
    <v-app-bar app dense dark class="navbar" clipped-left>
        <img :src="`${$config.COMMON_WEB_ASSETS_URL}/logo.png?hl=en`" alt="site-logo" height="30" :title="propBackHomeTitle" />
        <v-spacer />
    </v-app-bar>
</template>

<script>
export default {
    name: 'NavbarPublic',
    props: {
        propBackHomeLink: {
            type: String,
            required: false,
            default: '/',
        },
        propBackHomeTitle: {
            type: String,
            required: false,
            default: 'Back to home',
        },
    },
};
</script>

<style scoped lang="scss">
#navbar-portal-title {
    font-size: 1.2em !important;
    margin-left: 1em !important;
}
</style>
