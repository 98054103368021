/**
 * Loads the default project choices list that can be used with select list inputs.
 */
export default class ProjectChoicesLoader {
    constructor(axios) {
        this.axios = axios;
    }

    /**
     * @param {string|string[]|null} id
     * @returns {Promise<[{_id: string, name: string}]>}
     */
    async loadChoicesById(id) {
        if (id == null) return [];

        // always operate on array
        id = Array.isArray(id) ? id : [id];
        let choices = [];

        for (const loadId of id) {
            const project = await this.axios.$get(`/api/operating/projects/${loadId}`, {
                params: { fields: '_id,projectId' },
            });
            if (project) {
                choices = [{ _id: project._id, name: project.projectId }];
            }
        }

        return choices;
    }

    /**
     * @param {string|null} search
     * @param {Object} [options]
     * @param {boolean} [options.multiple] - If true, handles multi-selection.
     * @param {Object[]} [options.choices] - If given, this choices list with already loaded will be merged with new choices.
     * Only taken into account of "multiple" is set to true.
     * @returns {Promise<[{_id: string, name: string}]>}
     */
    async loadChoicesBySearch(search, options) {
        const defaultOptions = { multiple: false, choices: [] };
        const { multiple, choices: optChoices } = Object.assign(defaultOptions, options);

        const prevChoices = Array.isArray(optChoices) ? optChoices : [];
        let choices = [];

        if (search) {
            const { items } = await this.axios.$get('/api/operating/projects', {
                params: { search, fields: '_id,projectId' },
            });
            if (items) {
                choices = items.map((value) => {
                    return {
                        _id: value._id,
                        name: value.projectId,
                    };
                });
            }
        }

        if (multiple) {
            const mergedChoices = choices.concat(prevChoices);
            choices = [...new Map(mergedChoices.map((item) => [item._id, item])).values()];
        }

        return choices;
    }
}
