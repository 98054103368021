/**
 * @type {Readonly<{}>}
 */
export const roles = Object.freeze({
    ADMINISTRATOR: 'ADMINISTRATOR',
    OPERATING_ADMIN: 'OPERATING_ADMIN',
    MONITORING_ADMIN: 'MONITORING_ADMIN',
    BUSINESSENTITY_USER: 'BUSINESS_ENTITY_USER',
    BNK_PORTAL_CONTENT_ADMIN: 'BNK_PORTAL_CONTENT_ADMIN',
    BNK_PORTAL_EDITOR: 'BNK_PORTAL_EDITOR',
    BNK_PORTAL_VIEWER: 'BNK_PORTAL_VIEWER',
});

/**
 * @type {Readonly<{}>}
 */
export const permissions = Object.freeze({
    GLOBAL_CACHE_CLEAR: 'global:cache:clear',
    IAM_PROFILE_READ: 'iam:profile:read',
    IAM_PROFILE_UPDATE: 'iam:profile:update',
    IAM_USER_CREATE: 'iam:user:create',
    IAM_USER_DELETE: 'iam:user:delete',
    IAM_USER_READ: 'iam:user:read',
    IAM_USER_UPDATE: 'iam:user:update',
    IAM_USER_IMPERSONATE: 'iam:user:impersonate',
    IAM_SIGNED_URL_CREATE: 'operating:utils:signed-url:create',
    IAM_ACCESS_TOKEN_CREATE: 'operating:utils:access-token:create',
    MESSAGING_MESSAGE_CREATE: 'messaging:message:create',
    MONITORING_APP_ACCESS: 'monitoring:app:access',
    NOTIFICATION_NOTIFICATION_READ: 'notification:notification:read',
    OPERATING_APP_ACCESS: 'operating:app:access',
    OPERATING_APP_SETTINGSREAD: 'operating:app:settings-read',
    OPERATING_APP_SETTINGSUPDATE: 'operating:app:settings-update',
    OPERATING_PROJECT_CHANGEHISTORY_READ: 'operating:project:change-history:read',
    OPERATING_PROJECT_CREATE: 'operating:project:create',
    OPERATING_PROJECT_DELETE: 'operating:project:delete',
    OPERATING_PROJECT_READ: 'operating:project:read',
    OPERATING_PROJECT_UPDATE: 'operating:project:update',
    OPERATING_ATM_CREATE: 'operating:atm:create',
    OPERATING_ATM_DELETE: 'operating:atm:delete',
    OPERATING_ATM_READ: 'operating:atm:read',
    OPERATING_ATM_CHANGEHISTORY_READ: 'operating:atm:change-history:read',
    OPERATING_ATM_UPDATE: 'operating:atm:update',
    OPERATING_ATS_CREATE: 'operating:ats:create',
    OPERATING_ATS_DELETE: 'operating:ats:delete',
    OPERATING_ATS_READ: 'operating:ats:read',
    OPERATING_ATS_CHANGEHISTORY_READ: 'operating:ats:change-history:read',
    OPERATING_ATS_UPDATE: 'operating:ats:update',
    OPERATING_ATS_TEST_RUNS_READ: 'operating:ats:test-runs:read',
    OPERATING_ATSINSTALLATION_CREATE: 'operating:ats-installation:create',
    OPERATING_ATSINSTALLATION_DELETE: 'operating:ats-installation:delete',
    OPERATING_ATSINSTALLATION_READ: 'operating:ats-installation:read',
    OPERATING_ATSINSTALLATION_CHANGEHISTORY_READ: 'operating:ats-installation:change-history:read',
    OPERATING_ATSINSTALLATION_UPDATE: 'operating:ats-installation:update',
    OPERATING_BUSINESSENTITY_CREATE: 'operating:business-entity:create',
    OPERATING_BUSINESSENTITY_DELETE: 'operating:business-entity:delete',
    OPERATING_BUSINESSENTITY_READ: 'operating:business-entity:read',
    OPERATING_BUSINESSENTITY_CHANGEHISTORY_READ: 'operating:business-entity:change-history:read',
    OPERATING_BUSINESSENTITY_UPDATE: 'operating:business-entity:update',
    OPERATING_DRAFT_WINDTURBINE_CREATE: 'operating:draft:wind-turbine:create',
    OPERATING_DRAFT_WINDTURBINE_DELETE: 'operating:draft:wind-turbine:delete',
    OPERATING_DRAFT_WINDTURBINE_READ: 'operating:draft:wind-turbine:read',
    OPERATING_DRAFT_WINDTURBINE_CHANGEHISTORY_READ: 'operating:draft:wind-turbine:change-history:read',
    OPERATING_DRAFT_WINDTURBINE_UPDATE: 'operating:draft:wind-turbine:update',
    OPERATING_IMPORT_PROJECTS: 'operating:import:projects',
    OPERATING_IMPORT_BUSINESSENTITIES: 'operating:import:business-entities',
    OPERATING_IMPORT_DRAFT_WINDTURBINES: 'operating:import:draft:wind-turbines',
    OPERATING_IMPORT_WINDPARKS: 'operating:import:wind-parks',
    OPERATING_IMPORT_WINDTURBINES: 'operating:import:wind-turbines',
    OPERATING_MAINTENANCEACTION_CREATE: 'operating:maintenance-action:create',
    OPERATING_MAINTENANCEACTION_DELETE: 'operating:maintenance-action:delete',
    OPERATING_MAINTENANCEACTION_READ: 'operating:maintenance-action:read',
    OPERATING_MAINTENANCEACTION_UPDATE: 'operating:maintenance-action:update',
    OPERATING_READONLY_DISABLE: 'operating:readonly:disable',
    OPERATING_READONLY_ENABLE: 'operating:readonly:enable',
    OPERATING_SYSTEM_STATUS_UPDATE: 'operating:system-status:update',
    OPERATING_UTILSELEVATION_READ: 'operating:utils:read-elevation',
    OPERATING_UTILSGEOCODE_READ: 'operating:utils:read-geocode',
    OPERATING_WINDPARK_CREATE: 'operating:wind-park:create',
    OPERATING_WINDPARK_DELETE: 'operating:wind-park:delete',
    OPERATING_WINDPARK_READ: 'operating:wind-park:read',
    OPERATING_WINDPARK_CHANGEHISTORY_READ: 'operating:wind-park:change-history:read',
    OPERATING_WINDPARK_UPDATE: 'operating:wind-park:update',
    OPERATING_WINDTURBINE_CREATE: 'operating:wind-turbine:create',
    OPERATING_WINDTURBINE_DELETE: 'operating:wind-turbine:delete',
    OPERATING_WINDTURBINE_READ: 'operating:wind-turbine:read',
    OPERATING_WINDTURBINE_CHANGEHISTORY_READ: 'operating:wind-turbine:change-history:read',
    OPERATING_WINDTURBINE_UPDATE: 'operating:wind-turbine:update',
    OPERATING_ADLSGROUP_READ: 'operating:adls-group:read',
    OPERATING_ADLSGROUP_CHANGEHISTORY_READ: 'operating:adls-group:change-history:read',
    OPERATING_ADLSGROUP_CREATE: 'operating:adls-group:create',
    OPERATING_ADLSGROUP_UPDATE: 'operating:adls-group:update',
    OPERATING_ADLSGROUP_DELETE: 'operating:adls-group:delete',
    OPERATING_VIEWSHED_CREATE: 'operating:viewshed:create',
    OPERATING_VIEWSHED_DELETE: 'operating:viewshed:delete',
    OPERATING_VIEWSHED_READ: 'operating:viewshed:read',
    OPERATING_VIEWSHED_UPDATE: 'operating:viewshed:update',
    OPERATING_COMMISSIONINGPROCESS_READ: 'operating:commissioning-process:read',
    OPERATING_COMMISSIONINGPROCESS_CHANGEHISTORY_READ: 'operating:commissioning-process:change-history:read',
    OPERATING_COMMISSIONINGPROCESS_CREATE: 'operating:commissioning-process:create',
    OPERATING_COMMISSIONINGPROCESS_UPDATE: 'operating:commissioning-process:update',
    OPERATING_COMMISSIONINGPROCESS_DELETE: 'operating:commissioning-process:delete',
    OPERATING_ENERCON_SCADA_CERTIFICATE_CHANGEHISTORY_READ: 'operating:certificate:enercon:scada:change-history:read',
    OPERATING_ENERCON_SCADA_CERTIFICATE_CREATE: 'operating:certificate:enercon:scada:create',
    OPERATING_ENERCON_SCADA_CERTIFICATE_DELETE: 'operating:certificate:enercon:scada:delete',
    OPERATING_ENERCON_SCADA_CERTIFICATE_READ: 'operating:certificate:enercon:scada:read',
    OPERATING_ENERCON_SCADA_CERTIFICATE_UPDATE: 'operating:certificate:enercon:scada:update',
    TILES_TILES_CACHE_CLEAR: 'tiles:cache:clear',
    TILES_TILES_BNK_READ: 'tiles:tiles:bnk:read',
    TILES_TILES_MOBILE_COVERAGE_READ: 'tiles:tiles:mobile-coverage:read',
    TILES_TILES_FEATURES_SYNC: 'tiles:features:sync',
    TILES_MOBILE_COVERAGE_IMPORT: 'tiles:mobile-coverage:import',
    TILES_MOBILE_COVERAGE_READ: 'tiles:mobile-coverage:read',
    TILES_MOBILE_COVERAGE_DELETE: 'tiles:mobile-coverage:delete',
    REPORTING_CERTIFICATION_DOCUMENT_READ: 'reporting:certification-document:read',
    REPORTING_CERTIFICATION_DOCUMENT_CREATE: 'reporting:certification-document:create',
    BNK_MEDIATOR_ATS_STATUS_READ: 'bnk-mediator:ats:status:read',
});
