<template>
    <div>
        <v-menu offset-y left>
            <template v-slot:activator="{ on }">
                <v-btn icon v-on="on">
                    <v-icon small>$vuetify.icons.circleInfo</v-icon>
                </v-btn>
            </template>
            <v-card>
                <v-list id="help-menu" expand>
                    <v-list-item @click="showModal.contactSupport = true">
                        <v-list-item-action>
                            <v-icon small class="mr-2">$vuetify.icons.envelopeOpenText</v-icon>
                        </v-list-item-action>
                        <v-list-item-title>Contact Support</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-card>
        </v-menu>
        <contact-support-modal
            :prop-show-dialog="showModal.contactSupport"
            @cancel="showModal.contactSupport = false"
            @close="showModal.contactSupport = false"
        />
    </div>
</template>

<script>
import rbacMeta from '@/mixins/rbac-meta';
import ContactSupportModal from '@/components/ContactSupportModal';

export default {
    name: 'HelpMenu',
    components: { ContactSupportModal },
    mixins: [rbacMeta],
    data() {
        return {
            showModal: {
                contactSupport: false,
            },
        };
    },
    computed: {},
    methods: {},
};
</script>

<style scoped lang="scss">
#help {
    padding-bottom: 0 !important;
}
</style>
