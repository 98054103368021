import checkTypes from 'check-types';
import cleanDeep from 'clean-deep';
import { MESSAGE_ERROR_DATA_LOAD } from '@/globals';
import splitServiceEmail from '@/helpers/split-service-email';
import * as valueTransform from '@/helpers/value-transform';

export const state = () => ({
    choices: {
        businessEntity: [],
        roles: [],
        permissions: [],
    },
    uid: null,
    email: null,
    emailPrefix: null,
    // only for viewing purposes
    emailVerified: false,
    displayName: null,
    roles: [],
    permissions: [],
    businessEntity: null,
    password: null,
    disabled: false,
    // only for viewing purposes
    orphaned: false,
    serviceAccount: false,
    serviceAccountOwner: null,
    bypassResourceAccessChecks: false,
});

export const mutations = {
    clear(state) {
        // keep roles choices during clears
        state.choices = { ...state.choices, ...{ businessEntity: [] } };
        state.id = null;
        state.email = null;
        state.emailPrefix = null;
        // only for viewing purposes
        state.emailVerified = false;
        state.displayName = null;
        state.roles = [];
        state.permissions = [];
        state.password = null;
        state.disabled = false;
        state.businessEntity = null;
        // only for viewing purposes
        state.orphaned = null;
        state.serviceAccount = false;
        state.serviceAccountOwner = null;
        state.bypassResourceAccessChecks = false;
    },
    setChoices(state, value) {
        state.choices = value;
    },
    updateChoices(state, value) {
        const choices = { ...state.choices };
        state.choices = Object.assign(choices, value);
    },
    setUid(state, value) {
        state.uid = valueTransform.toString(value, { nullable: false });
    },
    setEmail(state, value) {
        state.email = valueTransform.toString(value);
    },
    setEmailPrefix(state, value) {
        state.emailPrefix = valueTransform.toString(value);
    },
    setEmailVerified(state, value) {
        state.emailVerified = valueTransform.toBoolean(value);
    },
    setDisplayName(state, value) {
        state.displayName = valueTransform.toString(value);
    },
    setRoles(state, value) {
        checkTypes.assert.array.of.nonEmptyString(value);
        state.roles = value;
    },
    setPermissions(state, value) {
        if (value != null) checkTypes.assert.array.of.nonEmptyString(value);
        state.permissions = value;
    },
    setBusinessEntity(state, value) {
        state.businessEntity = valueTransform.toString(value);
    },
    setPassword(state, value) {
        state.password = valueTransform.toString(value, { nullable: false });
    },
    setDisabled(state, value) {
        state.disabled = valueTransform.toBoolean(value);
    },
    setOrphaned(state, value) {
        state.orphaned = valueTransform.toBoolean(value);
    },
    setServiceAccount(state, value) {
        state.serviceAccount = valueTransform.toBoolean(value);
    },
    setServiceAccountOwner(state, value) {
        state.serviceAccountOwner = valueTransform.toString(value);
    },
    setBypassResourceAccessChecks(state, value) {
        state.bypassResourceAccessChecks = valueTransform.toBoolean(value);
    },
};

export const actions = {
    async load({ commit, state }, uid) {
        checkTypes.assert.nonEmptyString(uid);

        let data;
        try {
            data = await this.$axios.$get(`/api/iam/users/${uid}`);
        } catch (e) {
            await this.$errorHandler.setDefaultMessage(MESSAGE_ERROR_DATA_LOAD).handle(e, { notify: true });
        }

        const {
            uid: userId,
            email,
            emailVerified,
            displayName,
            disabled,
            roles,
            permissions,
            serviceAccount,
            serviceAccountOwner,
            businessEntity,
            bypassResourceAccessChecks,
            orphaned,
        } = data;

        commit('setUid', userId);
        commit('setEmail', email);
        // set email prefix if service account
        const { prefix } = splitServiceEmail(email);
        if (prefix) commit('setEmailPrefix', prefix);
        commit('setEmailVerified', emailVerified);
        commit('setDisplayName', displayName);
        commit('setDisabled', disabled);
        commit('setServiceAccount', serviceAccount);
        commit('setRoles', roles);
        commit('setPermissions', permissions);
        commit('setBusinessEntity', businessEntity);
        commit('setServiceAccountOwner', serviceAccountOwner);
        commit('setBypassResourceAccessChecks', bypassResourceAccessChecks);
        commit('setOrphaned', orphaned);
    },
    //
    async new({ state }) {
        await this.$axios.$post(`/api/iam/users`, cleanDeep(_denormalize({ state })));
    },
    async edit({ state }) {
        await this.$axios.$patch(`/api/iam/users/${state.uid}`, _denormalize({ state }));
    },
    async editRoles({ state }) {
        await this.$axios.$patch(`/api/iam/users/${state.uid}`, { roles: state.roles });
    },
    async editPermissions({ state }) {
        await this.$axios.$patch(`/api/iam/users/${state.uid}`, { permissions: state.permissions });
    },
    async setAccountStatus({ state }, { uid, disabled }) {
        await this.$axios.$patch(`/api/iam/users/${uid}`, { disabled });
    },
    async setPassword({ state }) {
        await this.$axios.$patch(`/api/iam/users/${state.uid}/password`, { password: state.password });
    },
    async delete({ state }) {
        await this.$axios.$delete(`/api/iam/users/${state.id}`);
    },
};

function _denormalize({ state }) {
    return {
        email: state.email,
        disabled: state.disabled,
        serviceAccount: state.serviceAccount,
        serviceAccountOwner: state.serviceAccountOwner,
        bypassResourceAccessChecks: state.bypassResourceAccessChecks,
        displayName: state.displayName,
        businessEntity: state.businessEntity,
        roles: state.roles,
        permissions: state.permissions,
    };
}
