// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../static/images/canvas_image_1.jpg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../static/images/canvas_image_2.jpg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../static/images/canvas_image_3.jpg");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../static/images/canvas_image_4.jpg");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../static/images/canvas_image_5.jpg");
var ___CSS_LOADER_URL_IMPORT_5___ = require("../static/images/canvas_image_6.jpg");
var ___CSS_LOADER_URL_IMPORT_6___ = require("../static/images/canvas_image_7.jpg");
var ___CSS_LOADER_URL_IMPORT_7___ = require("../static/images/canvas_image_8.jpg");
var ___CSS_LOADER_URL_IMPORT_8___ = require("../static/images/canvas_image_9.jpg");
var ___CSS_LOADER_URL_IMPORT_9___ = require("../static/images/canvas_image_10.jpg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
var ___CSS_LOADER_URL_REPLACEMENT_9___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_9___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#error-page[data-v-d132878a]{color:#fff}.wallpaper[data-v-d132878a]{background-size:cover;height:100%}.error-status-code[data-v-d132878a]{font-size:7em}.error-message[data-v-d132878a]{font-size:2em}.theme-1[data-v-d132878a]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.theme-2[data-v-d132878a]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}.theme-3[data-v-d132878a]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ")}.theme-4[data-v-d132878a]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ")}.theme-5[data-v-d132878a]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ")}.theme-6[data-v-d132878a]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ")}.theme-7[data-v-d132878a]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + ")}.theme-8[data-v-d132878a]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_7___ + ")}.theme-9[data-v-d132878a]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_8___ + ")}.theme-10[data-v-d132878a]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_9___ + ")}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
