import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6410996b = () => interopDefault(import('../pages/business-entities/index.vue' /* webpackChunkName: "pages/business-entities/index" */))
const _aa18caba = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _0090c240 = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _235bfd99 = () => interopDefault(import('../pages/map/index.vue' /* webpackChunkName: "pages/map/index" */))
const _cd39ff9a = () => interopDefault(import('../pages/projects/index.vue' /* webpackChunkName: "pages/projects/index" */))
const _57104ee2 = () => interopDefault(import('../pages/reset-password.vue' /* webpackChunkName: "pages/reset-password" */))
const _6372ca1c = () => interopDefault(import('../pages/statistics/index.vue' /* webpackChunkName: "pages/statistics/index" */))
const _0d62bb7d = () => interopDefault(import('../pages/wind-parks/index.vue' /* webpackChunkName: "pages/wind-parks/index" */))
const _4f66ba26 = () => interopDefault(import('../pages/wind-turbines/index.vue' /* webpackChunkName: "pages/wind-turbines/index" */))
const _4f813b12 = () => interopDefault(import('../pages/admin/iam/index.vue' /* webpackChunkName: "pages/admin/iam/index" */))
const _30eeb930 = () => interopDefault(import('../pages/admin/system/index.vue' /* webpackChunkName: "pages/admin/system/index" */))
const _6531649e = () => interopDefault(import('../pages/bnk-system/adls-groups/index.vue' /* webpackChunkName: "pages/bnk-system/adls-groups/index" */))
const _3cb0d13e = () => interopDefault(import('../pages/bnk-system/atm/index.vue' /* webpackChunkName: "pages/bnk-system/atm/index" */))
const _5f03c7e7 = () => interopDefault(import('../pages/bnk-system/ats/index.vue' /* webpackChunkName: "pages/bnk-system/ats/index" */))
const _3c7b935a = () => interopDefault(import('../pages/bnk-system/ats-installations/index.vue' /* webpackChunkName: "pages/bnk-system/ats-installations/index" */))
const _26f1cf79 = () => interopDefault(import('../pages/business-entities/new.vue' /* webpackChunkName: "pages/business-entities/new" */))
const _4c816f7e = () => interopDefault(import('../pages/projects/new.vue' /* webpackChunkName: "pages/projects/new" */))
const _286df15c = () => interopDefault(import('../pages/users/profile.vue' /* webpackChunkName: "pages/users/profile" */))
const _81d6b74a = () => interopDefault(import('../pages/users/service-accounts.vue' /* webpackChunkName: "pages/users/service-accounts" */))
const _40294e0b = () => interopDefault(import('../pages/wind-parks/new.vue' /* webpackChunkName: "pages/wind-parks/new" */))
const _713ee2f4 = () => interopDefault(import('../pages/wind-turbines/new.vue' /* webpackChunkName: "pages/wind-turbines/new" */))
const _97bc6928 = () => interopDefault(import('../pages/bnk-system/adls-groups/new.vue' /* webpackChunkName: "pages/bnk-system/adls-groups/new" */))
const _b6e10910 = () => interopDefault(import('../pages/bnk-system/atm/change-history.vue' /* webpackChunkName: "pages/bnk-system/atm/change-history" */))
const _f294f33e = () => interopDefault(import('../pages/bnk-system/ats-installations/new.vue' /* webpackChunkName: "pages/bnk-system/ats-installations/new" */))
const _125a81b2 = () => interopDefault(import('../pages/bnk-system/ats/change-history.vue' /* webpackChunkName: "pages/bnk-system/ats/change-history" */))
const _4bc9d25e = () => interopDefault(import('../pages/certificates/enercon/scada/index.vue' /* webpackChunkName: "pages/certificates/enercon/scada/index" */))
const _226aa6fb = () => interopDefault(import('../pages/planning/drafts/wind-turbines/index.vue' /* webpackChunkName: "pages/planning/drafts/wind-turbines/index" */))
const _38bba2ae = () => interopDefault(import('../pages/bnk-system/atm/atm1/new.vue' /* webpackChunkName: "pages/bnk-system/atm/atm1/new" */))
const _77e69daa = () => interopDefault(import('../pages/bnk-system/atm/atm2/new.vue' /* webpackChunkName: "pages/bnk-system/atm/atm2/new" */))
const _bf2108a8 = () => interopDefault(import('../pages/bnk-system/atm/atm4/new.vue' /* webpackChunkName: "pages/bnk-system/atm/atm4/new" */))
const _34b3eaad = () => interopDefault(import('../pages/bnk-system/atm/atm5/new.vue' /* webpackChunkName: "pages/bnk-system/atm/atm5/new" */))
const _6e0f4ca4 = () => interopDefault(import('../pages/bnk-system/atm/atm6/new.vue' /* webpackChunkName: "pages/bnk-system/atm/atm6/new" */))
const _11166d1f = () => interopDefault(import('../pages/bnk-system/ats/ats3/new.vue' /* webpackChunkName: "pages/bnk-system/ats/ats3/new" */))
const _b54a47c0 = () => interopDefault(import('../pages/bnk-system/ats/ats4/new.vue' /* webpackChunkName: "pages/bnk-system/ats/ats4/new" */))
const _10286e5f = () => interopDefault(import('../pages/certificates/enercon/scada/new.vue' /* webpackChunkName: "pages/certificates/enercon/scada/new" */))
const _758e36cf = () => interopDefault(import('../pages/planning/drafts/wind-turbines/import/index.vue' /* webpackChunkName: "pages/planning/drafts/wind-turbines/import/index" */))
const _66dbfdee = () => interopDefault(import('../pages/planning/drafts/wind-turbines/new.vue' /* webpackChunkName: "pages/planning/drafts/wind-turbines/new" */))
const _3d6810e8 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _ce9d7bf4 = () => interopDefault(import('../pages/bnk-system/atm/atm1/_id/index.vue' /* webpackChunkName: "pages/bnk-system/atm/atm1/_id/index" */))
const _1ae6a772 = () => interopDefault(import('../pages/bnk-system/atm/atm2/_id/index.vue' /* webpackChunkName: "pages/bnk-system/atm/atm2/_id/index" */))
const _b378fe6e = () => interopDefault(import('../pages/bnk-system/atm/atm4/_id/index.vue' /* webpackChunkName: "pages/bnk-system/atm/atm4/_id/index" */))
const _001eeb0a = () => interopDefault(import('../pages/bnk-system/atm/atm5/_id/index.vue' /* webpackChunkName: "pages/bnk-system/atm/atm5/_id/index" */))
const _59fa554b = () => interopDefault(import('../pages/bnk-system/atm/atm6/_id/index.vue' /* webpackChunkName: "pages/bnk-system/atm/atm6/_id/index" */))
const _039e4ffc = () => interopDefault(import('../pages/bnk-system/ats/ats3/_id/index.vue' /* webpackChunkName: "pages/bnk-system/ats/ats3/_id/index" */))
const _5d79ba3d = () => interopDefault(import('../pages/bnk-system/ats/ats4/_id/index.vue' /* webpackChunkName: "pages/bnk-system/ats/ats4/_id/index" */))
const _0cfa613c = () => interopDefault(import('../pages/certificates/enercon/scada/_id/index.vue' /* webpackChunkName: "pages/certificates/enercon/scada/_id/index" */))
const _f55c6734 = () => interopDefault(import('../pages/planning/drafts/wind-turbines/_id/index.vue' /* webpackChunkName: "pages/planning/drafts/wind-turbines/_id/index" */))
const _b2fddaee = () => interopDefault(import('../pages/bnk-system/atm/atm1/_id/edit/index.vue' /* webpackChunkName: "pages/bnk-system/atm/atm1/_id/edit/index" */))
const _e3ba2e30 = () => interopDefault(import('../pages/bnk-system/atm/atm2/_id/edit/index.vue' /* webpackChunkName: "pages/bnk-system/atm/atm2/_id/edit/index" */))
const _5d6695a6 = () => interopDefault(import('../pages/bnk-system/atm/atm4/_id/edit/index.vue' /* webpackChunkName: "pages/bnk-system/atm/atm4/_id/edit/index" */))
const _45086c05 = () => interopDefault(import('../pages/bnk-system/atm/atm5/_id/edit/index.vue' /* webpackChunkName: "pages/bnk-system/atm/atm5/_id/edit/index" */))
const _2caa4264 = () => interopDefault(import('../pages/bnk-system/atm/atm6/_id/edit/index.vue' /* webpackChunkName: "pages/bnk-system/atm/atm6/_id/edit/index" */))
const _4b07635a = () => interopDefault(import('../pages/bnk-system/ats/ats3/_id/edit/index.vue' /* webpackChunkName: "pages/bnk-system/ats/ats3/_id/edit/index" */))
const _b2228a8a = () => interopDefault(import('../pages/bnk-system/ats/ats3/_id/status/index.vue' /* webpackChunkName: "pages/bnk-system/ats/ats3/_id/status/index" */))
const _7bc3b69c = () => interopDefault(import('../pages/bnk-system/ats/ats4/_id/edit/index.vue' /* webpackChunkName: "pages/bnk-system/ats/ats4/_id/edit/index" */))
const _2d74755a = () => interopDefault(import('../pages/bnk-system/ats/ats4/_id/status/index.vue' /* webpackChunkName: "pages/bnk-system/ats/ats4/_id/status/index" */))
const _8b8df5da = () => interopDefault(import('../pages/certificates/enercon/scada/_id/edit/index.vue' /* webpackChunkName: "pages/certificates/enercon/scada/_id/edit/index" */))
const _7a001c13 = () => interopDefault(import('../pages/planning/drafts/wind-turbines/_id/change-history.vue' /* webpackChunkName: "pages/planning/drafts/wind-turbines/_id/change-history" */))
const _830ff7ae = () => interopDefault(import('../pages/planning/drafts/wind-turbines/_id/edit/index.vue' /* webpackChunkName: "pages/planning/drafts/wind-turbines/_id/edit/index" */))
const _b5bc2084 = () => interopDefault(import('../pages/bnk-system/ats-installations/_id/index.vue' /* webpackChunkName: "pages/bnk-system/ats-installations/_id/index" */))
const _807f6bf2 = () => interopDefault(import('../pages/tmp/commissioning/_id/index.vue' /* webpackChunkName: "pages/tmp/commissioning/_id/index" */))
const _775f2a28 = () => interopDefault(import('../pages/tmp/project-information/_id/index.vue' /* webpackChunkName: "pages/tmp/project-information/_id/index" */))
const _048e2434 = () => interopDefault(import('../pages/bnk-system/adls-groups/_id/edit/index.vue' /* webpackChunkName: "pages/bnk-system/adls-groups/_id/edit/index" */))
const _27e63bbb = () => interopDefault(import('../pages/bnk-system/ats-installations/_id/change-history.vue' /* webpackChunkName: "pages/bnk-system/ats-installations/_id/change-history" */))
const _507037d1 = () => interopDefault(import('../pages/bnk-system/ats-installations/_id/edit/index.vue' /* webpackChunkName: "pages/bnk-system/ats-installations/_id/edit/index" */))
const _33e4cdc2 = () => interopDefault(import('../pages/bnk-system/ats-installations/_id/maintenance/index.vue' /* webpackChunkName: "pages/bnk-system/ats-installations/_id/maintenance/index" */))
const _1d28e304 = () => interopDefault(import('../pages/bnk-system/ats-installations/_id/maintenance/actions/new.vue' /* webpackChunkName: "pages/bnk-system/ats-installations/_id/maintenance/actions/new" */))
const _b93cf4c0 = () => interopDefault(import('../pages/bnk-system/ats-installations/_id/maintenance/actions/_maId/edit/index.vue' /* webpackChunkName: "pages/bnk-system/ats-installations/_id/maintenance/actions/_maId/edit/index" */))
const _764df254 = () => interopDefault(import('../pages/business-entities/_id/index.vue' /* webpackChunkName: "pages/business-entities/_id/index" */))
const _acea6cc4 = () => interopDefault(import('../pages/projects/_id/index.vue' /* webpackChunkName: "pages/projects/_id/index" */))
const _3b2b5bde = () => interopDefault(import('../pages/wind-turbines/_id/index.vue' /* webpackChunkName: "pages/wind-turbines/_id/index" */))
const _461b9fa3 = () => interopDefault(import('../pages/business-entities/_id/change-history.vue' /* webpackChunkName: "pages/business-entities/_id/change-history" */))
const _58f8608e = () => interopDefault(import('../pages/business-entities/_id/edit/index.vue' /* webpackChunkName: "pages/business-entities/_id/edit/index" */))
const _ccb7044a = () => interopDefault(import('../pages/projects/_id/change-history.vue' /* webpackChunkName: "pages/projects/_id/change-history" */))
const _106ea1a6 = () => interopDefault(import('../pages/projects/_id/commissioning/index.vue' /* webpackChunkName: "pages/projects/_id/commissioning/index" */))
const _81152bf2 = () => interopDefault(import('../pages/projects/_id/documents/index.vue' /* webpackChunkName: "pages/projects/_id/documents/index" */))
const _d236ec1e = () => interopDefault(import('../pages/projects/_id/edit/index.vue' /* webpackChunkName: "pages/projects/_id/edit/index" */))
const _3cf682ee = () => interopDefault(import('../pages/projects/_id/viewsheds/index.vue' /* webpackChunkName: "pages/projects/_id/viewsheds/index" */))
const _74a1e1e7 = () => interopDefault(import('../pages/wind-parks/_id/edit/index.vue' /* webpackChunkName: "pages/wind-parks/_id/edit/index" */))
const _55ad0a24 = () => interopDefault(import('../pages/wind-turbines/_id/bnk-system.vue' /* webpackChunkName: "pages/wind-turbines/_id/bnk-system" */))
const _a6887270 = () => interopDefault(import('../pages/wind-turbines/_id/change-history.vue' /* webpackChunkName: "pages/wind-turbines/_id/change-history" */))
const _2e915d44 = () => interopDefault(import('../pages/wind-turbines/_id/edit/index.vue' /* webpackChunkName: "pages/wind-turbines/_id/edit/index" */))
const _3e10b782 = () => interopDefault(import('../pages/wind-turbines/_id/edit/configuration.vue' /* webpackChunkName: "pages/wind-turbines/_id/edit/configuration" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/business-entities",
    component: _6410996b,
    name: "business-entities"
  }, {
    path: "/login",
    component: _aa18caba,
    name: "login"
  }, {
    path: "/logout",
    component: _0090c240,
    name: "logout"
  }, {
    path: "/map",
    component: _235bfd99,
    name: "map"
  }, {
    path: "/projects",
    component: _cd39ff9a,
    name: "projects"
  }, {
    path: "/reset-password",
    component: _57104ee2,
    name: "reset-password"
  }, {
    path: "/statistics",
    component: _6372ca1c,
    name: "statistics"
  }, {
    path: "/wind-parks",
    component: _0d62bb7d,
    name: "wind-parks"
  }, {
    path: "/wind-turbines",
    component: _4f66ba26,
    name: "wind-turbines"
  }, {
    path: "/admin/iam",
    component: _4f813b12,
    name: "admin-iam"
  }, {
    path: "/admin/system",
    component: _30eeb930,
    name: "admin-system"
  }, {
    path: "/bnk-system/adls-groups",
    component: _6531649e,
    name: "bnk-system-adls-groups"
  }, {
    path: "/bnk-system/atm",
    component: _3cb0d13e,
    name: "bnk-system-atm"
  }, {
    path: "/bnk-system/ats",
    component: _5f03c7e7,
    name: "bnk-system-ats"
  }, {
    path: "/bnk-system/ats-installations",
    component: _3c7b935a,
    name: "bnk-system-ats-installations"
  }, {
    path: "/business-entities/new",
    component: _26f1cf79,
    name: "business-entities-new"
  }, {
    path: "/projects/new",
    component: _4c816f7e,
    name: "projects-new"
  }, {
    path: "/users/profile",
    component: _286df15c,
    name: "users-profile"
  }, {
    path: "/users/service-accounts",
    component: _81d6b74a,
    name: "users-service-accounts"
  }, {
    path: "/wind-parks/new",
    component: _40294e0b,
    name: "wind-parks-new"
  }, {
    path: "/wind-turbines/new",
    component: _713ee2f4,
    name: "wind-turbines-new"
  }, {
    path: "/bnk-system/adls-groups/new",
    component: _97bc6928,
    name: "bnk-system-adls-groups-new"
  }, {
    path: "/bnk-system/atm/change-history",
    component: _b6e10910,
    name: "bnk-system-atm-change-history"
  }, {
    path: "/bnk-system/ats-installations/new",
    component: _f294f33e,
    name: "bnk-system-ats-installations-new"
  }, {
    path: "/bnk-system/ats/change-history",
    component: _125a81b2,
    name: "bnk-system-ats-change-history"
  }, {
    path: "/certificates/enercon/scada",
    component: _4bc9d25e,
    name: "certificates-enercon-scada"
  }, {
    path: "/planning/drafts/wind-turbines",
    component: _226aa6fb,
    name: "planning-drafts-wind-turbines"
  }, {
    path: "/bnk-system/atm/atm1/new",
    component: _38bba2ae,
    name: "bnk-system-atm-atm1-new"
  }, {
    path: "/bnk-system/atm/atm2/new",
    component: _77e69daa,
    name: "bnk-system-atm-atm2-new"
  }, {
    path: "/bnk-system/atm/atm4/new",
    component: _bf2108a8,
    name: "bnk-system-atm-atm4-new"
  }, {
    path: "/bnk-system/atm/atm5/new",
    component: _34b3eaad,
    name: "bnk-system-atm-atm5-new"
  }, {
    path: "/bnk-system/atm/atm6/new",
    component: _6e0f4ca4,
    name: "bnk-system-atm-atm6-new"
  }, {
    path: "/bnk-system/ats/ats3/new",
    component: _11166d1f,
    name: "bnk-system-ats-ats3-new"
  }, {
    path: "/bnk-system/ats/ats4/new",
    component: _b54a47c0,
    name: "bnk-system-ats-ats4-new"
  }, {
    path: "/certificates/enercon/scada/new",
    component: _10286e5f,
    name: "certificates-enercon-scada-new"
  }, {
    path: "/planning/drafts/wind-turbines/import",
    component: _758e36cf,
    name: "planning-drafts-wind-turbines-import"
  }, {
    path: "/planning/drafts/wind-turbines/new",
    component: _66dbfdee,
    name: "planning-drafts-wind-turbines-new"
  }, {
    path: "/",
    component: _3d6810e8,
    name: "index"
  }, {
    path: "/bnk-system/atm/atm1/:id",
    component: _ce9d7bf4,
    name: "bnk-system-atm-atm1-id"
  }, {
    path: "/bnk-system/atm/atm2/:id",
    component: _1ae6a772,
    name: "bnk-system-atm-atm2-id"
  }, {
    path: "/bnk-system/atm/atm4/:id",
    component: _b378fe6e,
    name: "bnk-system-atm-atm4-id"
  }, {
    path: "/bnk-system/atm/atm5/:id",
    component: _001eeb0a,
    name: "bnk-system-atm-atm5-id"
  }, {
    path: "/bnk-system/atm/atm6/:id",
    component: _59fa554b,
    name: "bnk-system-atm-atm6-id"
  }, {
    path: "/bnk-system/ats/ats3/:id",
    component: _039e4ffc,
    name: "bnk-system-ats-ats3-id"
  }, {
    path: "/bnk-system/ats/ats4/:id",
    component: _5d79ba3d,
    name: "bnk-system-ats-ats4-id"
  }, {
    path: "/certificates/enercon/scada/:id",
    component: _0cfa613c,
    name: "certificates-enercon-scada-id"
  }, {
    path: "/planning/drafts/wind-turbines/:id",
    component: _f55c6734,
    name: "planning-drafts-wind-turbines-id"
  }, {
    path: "/bnk-system/atm/atm1/:id/edit",
    component: _b2fddaee,
    name: "bnk-system-atm-atm1-id-edit"
  }, {
    path: "/bnk-system/atm/atm2/:id/edit",
    component: _e3ba2e30,
    name: "bnk-system-atm-atm2-id-edit"
  }, {
    path: "/bnk-system/atm/atm4/:id/edit",
    component: _5d6695a6,
    name: "bnk-system-atm-atm4-id-edit"
  }, {
    path: "/bnk-system/atm/atm5/:id/edit",
    component: _45086c05,
    name: "bnk-system-atm-atm5-id-edit"
  }, {
    path: "/bnk-system/atm/atm6/:id/edit",
    component: _2caa4264,
    name: "bnk-system-atm-atm6-id-edit"
  }, {
    path: "/bnk-system/ats/ats3/:id/edit",
    component: _4b07635a,
    name: "bnk-system-ats-ats3-id-edit"
  }, {
    path: "/bnk-system/ats/ats3/:id/status",
    component: _b2228a8a,
    name: "bnk-system-ats-ats3-id-status"
  }, {
    path: "/bnk-system/ats/ats4/:id/edit",
    component: _7bc3b69c,
    name: "bnk-system-ats-ats4-id-edit"
  }, {
    path: "/bnk-system/ats/ats4/:id/status",
    component: _2d74755a,
    name: "bnk-system-ats-ats4-id-status"
  }, {
    path: "/certificates/enercon/scada/:id/edit",
    component: _8b8df5da,
    name: "certificates-enercon-scada-id-edit"
  }, {
    path: "/planning/drafts/wind-turbines/:id?/change-history",
    component: _7a001c13,
    name: "planning-drafts-wind-turbines-id-change-history"
  }, {
    path: "/planning/drafts/wind-turbines/:id?/edit",
    component: _830ff7ae,
    name: "planning-drafts-wind-turbines-id-edit"
  }, {
    path: "/bnk-system/ats-installations/:id",
    component: _b5bc2084,
    name: "bnk-system-ats-installations-id"
  }, {
    path: "/tmp/commissioning/:id",
    component: _807f6bf2,
    name: "tmp-commissioning-id"
  }, {
    path: "/tmp/project-information/:id",
    component: _775f2a28,
    name: "tmp-project-information-id"
  }, {
    path: "/bnk-system/adls-groups/:id?/edit",
    component: _048e2434,
    name: "bnk-system-adls-groups-id-edit"
  }, {
    path: "/bnk-system/ats-installations/:id?/change-history",
    component: _27e63bbb,
    name: "bnk-system-ats-installations-id-change-history"
  }, {
    path: "/bnk-system/ats-installations/:id?/edit",
    component: _507037d1,
    name: "bnk-system-ats-installations-id-edit"
  }, {
    path: "/bnk-system/ats-installations/:id?/maintenance",
    component: _33e4cdc2,
    name: "bnk-system-ats-installations-id-maintenance"
  }, {
    path: "/bnk-system/ats-installations/:id?/maintenance/actions/new",
    component: _1d28e304,
    name: "bnk-system-ats-installations-id-maintenance-actions-new"
  }, {
    path: "/bnk-system/ats-installations/:id?/maintenance/actions/:maId/edit",
    component: _b93cf4c0,
    name: "bnk-system-ats-installations-id-maintenance-actions-maId-edit"
  }, {
    path: "/business-entities/:id",
    component: _764df254,
    name: "business-entities-id"
  }, {
    path: "/projects/:id",
    component: _acea6cc4,
    name: "projects-id"
  }, {
    path: "/wind-turbines/:id",
    component: _3b2b5bde,
    name: "wind-turbines-id"
  }, {
    path: "/business-entities/:id?/change-history",
    component: _461b9fa3,
    name: "business-entities-id-change-history"
  }, {
    path: "/business-entities/:id?/edit",
    component: _58f8608e,
    name: "business-entities-id-edit"
  }, {
    path: "/projects/:id/change-history",
    component: _ccb7044a,
    name: "projects-id-change-history"
  }, {
    path: "/projects/:id/commissioning",
    component: _106ea1a6,
    name: "projects-id-commissioning"
  }, {
    path: "/projects/:id/documents",
    component: _81152bf2,
    name: "projects-id-documents"
  }, {
    path: "/projects/:id/edit",
    component: _d236ec1e,
    name: "projects-id-edit"
  }, {
    path: "/projects/:id/viewsheds",
    component: _3cf682ee,
    name: "projects-id-viewsheds"
  }, {
    path: "/wind-parks/:id?/edit",
    component: _74a1e1e7,
    name: "wind-parks-id-edit"
  }, {
    path: "/wind-turbines/:id?/bnk-system",
    component: _55ad0a24,
    name: "wind-turbines-id-bnk-system"
  }, {
    path: "/wind-turbines/:id?/change-history",
    component: _a6887270,
    name: "wind-turbines-id-change-history"
  }, {
    path: "/wind-turbines/:id?/edit",
    component: _2e915d44,
    name: "wind-turbines-id-edit"
  }, {
    path: "/wind-turbines/:id?/edit/configuration",
    component: _3e10b782,
    name: "wind-turbines-id-edit-configuration"
  }, {
    path: "/bnk-system/ats/ats3/:id/change-history",
    component: _125a81b2,
    name: "ats3-change-history"
  }, {
    path: "/bnk-system/ats/ats4/:id/change-history",
    component: _125a81b2,
    name: "ats4-change-history"
  }, {
    path: "/bnk-system/atm/atm1/:id/change-history",
    component: _b6e10910,
    name: "atm1-change-history"
  }, {
    path: "/bnk-system/atm/atm2/:id/change-history",
    component: _b6e10910,
    name: "atm2-change-history"
  }, {
    path: "/bnk-system/atm/atm4/:id/change-history",
    component: _b6e10910,
    name: "atm4-change-history"
  }, {
    path: "/bnk-system/atm/atm5/:id/change-history",
    component: _b6e10910,
    name: "atm5-change-history"
  }, {
    path: "/bnk-system/atm/atm6/:id/change-history",
    component: _b6e10910,
    name: "atm6-change-history"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
