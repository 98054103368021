import { Enum } from '@/helpers/choices/enum';

export class AntennaTypeLteEnum extends Enum {}

AntennaTypeLteEnum.values = {
    GENERATION_1: 0,
};

AntennaTypeLteEnum.names = {
    0: 'Generation 1',
};

Object.freeze(AntennaTypeLteEnum);
