<template>
    <v-navigation-drawer v-model="drawer" :rail="rail" :width="rail ? 60 : 256" app clipped disable-route-watcher :permanent="$vuetify.breakpoint.smAndUp">
        <v-list id="main-navigation" expand nav>
            <v-tooltip right :disabled="!rail">
                <template v-slot:activator="{ on }">
                    <v-list-item
                        v-if="authUser.hasPermission([permissions.OPERATING_DRAFT_WINDTURBINE_READ])"
                        to="/map"
                        :nuxt="true"
                        :class="/^\/map\/.*$/g.test($route.path) ? 'v-list-item--active' : ''"
                        v-on="on"
                    >
                        <v-list-item-action>
                            <v-icon>$vuetify.icons.map</v-icon>
                        </v-list-item-action>
                        <v-list-item-title>Map</v-list-item-title>
                    </v-list-item>
                </template>
                <span>Map</span>
            </v-tooltip>
            <v-tooltip right :disabled="!rail">
                <template v-slot:activator="{ on }">
                    <v-list-item
                        v-if="authUser.hasPermission([permissions.OPERATING_DRAFT_WINDTURBINE_READ])"
                        to="/planning/drafts/wind-turbines"
                        :nuxt="true"
                        :class="/^\/planning\/.*$/g.test($route.path) ? 'v-list-item--active' : ''"
                        v-on="on"
                    >
                        <v-list-item-action>
                            <v-icon>$vuetify.icons.compassDrafting</v-icon>
                        </v-list-item-action>
                        <v-list-item-title>Planning</v-list-item-title>
                    </v-list-item>
                </template>
                <span>Planning</span>
            </v-tooltip>
            <v-tooltip right :disabled="!rail">
                <template v-slot:activator="{ on }">
                    <v-list-item
                        v-if="authUser.hasPermission([permissions.OPERATING_PROJECT_READ])"
                        to="/projects"
                        :nuxt="true"
                        :class="/^\/projects\/.*$/g.test($route.path) ? 'v-list-item--active' : ''"
                        v-on="on"
                    >
                        <v-list-item-action>
                            <v-icon>$vuetify.icons.folderOpen</v-icon>
                        </v-list-item-action>
                        <v-list-item-title>Projects</v-list-item-title>
                    </v-list-item>
                </template>
                <span>Projects</span>
            </v-tooltip>
            <v-tooltip right :disabled="!rail">
                <template v-slot:activator="{ on }">
                    <v-list-item
                        v-if="
                            authUser.hasPermission([
                                permissions.OPERATING_ATSINSTALLATION_READ,
                                permissions.OPERATING_ATS_READ,
                                permissions.OPERATING_ATM_READ,
                                permissions.OPERATING_ADLSGROUP_READ,
                            ])
                        "
                        to="/bnk-system/ats-installations"
                        :nuxt="true"
                        :class="/^\/bnk-system\/.*$/g.test($route.path) ? 'v-list-item--active' : ''"
                        v-on="on"
                    >
                        <v-list-item-action>
                            <v-icon>$vuetify.icons.signalStream</v-icon>
                        </v-list-item-action>
                        <v-list-item-title>BNK System</v-list-item-title>
                    </v-list-item>
                </template>
                <span>BNK System</span>
            </v-tooltip>
            <v-tooltip right :disabled="!rail">
                <template v-slot:activator="{ on }">
                    <v-list-item
                        v-if="authUser.hasPermission([permissions.OPERATING_WINDTURBINE_READ])"
                        to="/wind-turbines"
                        :nuxt="true"
                        :class="/^\/wind-turbines\/.*$/g.test($route.path) ? 'v-list-item--active' : ''"
                        v-on="on"
                    >
                        <v-list-item-action>
                            <v-icon>$vuetify.icons.windTurbine</v-icon>
                        </v-list-item-action>
                        <v-list-item-title>Wind Turbines</v-list-item-title>
                    </v-list-item>
                </template>
                <span>Wind Turbines</span>
            </v-tooltip>
            <v-tooltip right :disabled="!rail">
                <template v-slot:activator="{ on }">
                    <v-list-item
                        v-if="authUser.hasPermission([permissions.OPERATING_WINDPARK_READ])"
                        to="/wind-parks"
                        :nuxt="true"
                        :class="/^\/wind-parks\/.*$/g.test($route.path) ? 'v-list-item--active' : ''"
                        v-on="on"
                    >
                        <v-list-item-action>
                            <v-icon>$vuetify.icons.windTurbine</v-icon>
                        </v-list-item-action>
                        <v-list-item-title>Wind Parks</v-list-item-title>
                    </v-list-item>
                </template>
                <span>Wind Parks</span>
            </v-tooltip>
            <v-tooltip right :disabled="!rail">
                <template v-slot:activator="{ on }">
                    <v-list-item
                        v-if="authUser.hasPermission([permissions.OPERATING_BUSINESSENTITY_READ])"
                        to="/business-entities"
                        :nuxt="true"
                        :class="/^\/business-entities\/.*$/g.test($route.path) ? 'v-list-item--active' : ''"
                        v-on="on"
                    >
                        <v-list-item-action>
                            <v-icon>$vuetify.icons.building</v-icon>
                        </v-list-item-action>
                        <v-list-item-title>Business Entities</v-list-item-title>
                    </v-list-item>
                </template>
                <span>Business Entities</span>
            </v-tooltip>
            <v-tooltip right :disabled="!rail">
                <template v-slot:activator="{ on }">
                    <v-list-item
                        v-if="authUser.hasPermission([permissions.OPERATING_ENERCON_SCADA_CERTIFICATE_READ])"
                        to="/certificates/enercon/scada"
                        :nuxt="true"
                        :class="/^\/certificates\/.*$/g.test($route.path) ? 'v-list-item--active' : ''"
                        v-on="on"
                    >
                        <v-list-item-action>
                            <v-icon>$vuetify.icons.fileCertificate</v-icon>
                        </v-list-item-action>
                        <v-list-item-title>Certificate Management</v-list-item-title>
                    </v-list-item>
                </template>
                <span>Certificate Management</span>
            </v-tooltip>
            <v-tooltip right :disabled="!rail">
                <template v-slot:activator="{ on }">
                    <v-list-item to="/statistics" :nuxt="true" :class="/^\/statistics\/.*$/g.test($route.path) ? 'v-list-item--active' : ''" v-on="on">
                        <v-list-item-action>
                            <v-icon>$vuetify.icons.squarePollVertical</v-icon>
                        </v-list-item-action>
                        <v-list-item-title>Statistics</v-list-item-title>
                    </v-list-item>
                </template>
                <span>Statistics</span>
            </v-tooltip>
            <v-tooltip right :disabled="!rail">
                <template v-slot:activator="{ on }">
                    <v-list-item
                        v-if="authUser.hasPermission([permissions.OPERATING_APP_SETTINGSREAD])"
                        to="/admin/system"
                        :nuxt="true"
                        :class="/^\/admin\/.*$/g.test($route.path) ? 'v-list-item--active' : ''"
                        v-on="on"
                    >
                        <v-list-item-action>
                            <v-icon>$vuetify.icons.gears</v-icon>
                        </v-list-item-action>
                        <v-list-item-title>Administration</v-list-item-title>
                    </v-list-item>
                </template>
                <span>Administration</span>
            </v-tooltip>
        </v-list>
        <template v-slot:append>
            <v-divider />
            <v-btn plain class="p-2 mb-10 float-right" @click="rail = !rail">
                <v-icon>{{ rail ? '$vuetify.icons.arrowRightFromLine' : '$vuetify.icons.arrowLeftFromLine' }} </v-icon>
            </v-btn>
        </template>
    </v-navigation-drawer>
</template>

<script>
import rbacMeta from '@/mixins/rbac-meta';

export default {
    name: 'MainNavigation',
    mixins: [rbacMeta],
    data() {
        return {
            rail: true,
            about: [
                { title: 'Legal', to: '/about/legal' },
                { title: 'Terms Of Use', to: '/about/terms' },
                { title: 'Privacy Policy', to: '/about/privacy-policy' },
            ],
        };
    },
    computed: {
        drawer: {
            // get value from store
            get() {
                return this.$store.state.mainNavigation;
            },
            // make sure to also "commit" changes to property into store => offset clicks when in overlay mode !!!
            set(val) {
                this.$store.commit('setMainNavigation', val);
            },
        },
    },
    mounted() {
        // suppress showing overlayed drawer on small screen sizes
        // and instead show full content and user must explicitly
        // click menu icon to open drawer
        if (!this.$vuetify.breakpoint.xs && !this.$vuetify.breakpoint.sm && !this.$vuetify.breakpoint.md) {
            this.$store.commit('setMainNavigation', true);
        }
    },
};
</script>
