import { LIST_ITEMS_PER_PAGE_DEFAULT } from '@/globals';
import cleanDeep from 'clean-deep';
import * as valueTransform from '@/helpers/value-transform';
import checkTypes from 'check-types';
import { OperationModeEnum } from '@/helpers/choices/ats-installation/operation-mode-enum';
import { getFormChoices as countryChoices } from '@/helpers/choices/countries';

const choices = {
    operationMode: OperationModeEnum.getFormChoices(),
    country: countryChoices(),
};

export const state = () => ({
    choices,
    items: new Map(),
    page: 1,
    totalItems: 0,
    limit: LIST_ITEMS_PER_PAGE_DEFAULT,
    sortBy: null,
    sortDesc: null,
    filterSearch: null,
    filterSearchOptLwc: false,
    filterAts: null,
    filterAtsInstalled: null,
    filterOperationMode: null,
    filterTrafficReceiver: null,
    filterAvailableForSbv: null,
    filterOnlyConfigProblems: null,
    filterCountry: null,
    filterCoverageExtension: null,
});

export const getters = {
    getItems: (state) => {
        // IMPORTANT make sure to return an array calling values which will only return list of values and not a "keyed valued"
        return Array.from(state.items.values());
    },
    getQueryFilters: (state) => {
        return {
            page: state.page,
            limit: state.limit,
            sortBy: state.sortBy,
            sortDesc: state.sortDesc,
            search: state.filterSearch,
            searchOptLwc: state.filterSearchOptLwc,
            ats: state.filterAts,
            atsInstalled: state.filterAtsInstalled,
            operationMode: state.filterOperationMode,
            trafficReceiver: state.filterTrafficReceiver,
            availableForSbv: state.filterAvailableForSbv,
            onlyConfigProblems: state.filterOnlyConfigProblems,
            country: state.filterCountry,
            coverageExtension: state.filterCoverageExtension,
        };
    },
    hasSpecialFilters: (state) => {
        return (
            Object.values(
                cleanDeep({
                    ats: state.filterAts,
                    atsInstalled: state.filterAtsInstalled,
                    operationMode: state.filterOperationMode,
                    trafficReceiver: state.filterTrafficReceiver || undefined,
                    availableForSbv: state.filterAvailableForSbv || undefined,
                    onlyConfigProblems: state.filterOnlyConfigProblems || undefined,
                    coverageExtension: state.filterCoverageExtension || undefined,
                    country: state.filterCountry,
                })
            ).length > 0
        );
    },
};

export const mutations = {
    clearSpecialFilters(state) {
        state.filterAts = null;
        state.filterAtsInstalled = null;
        state.filterOperationMode = null;
        state.filterTrafficReceiver = null;
        state.filterAvailableForSbv = null;
        state.filterOnlyConfigProblems = null;
        state.filterCountry = null;
        state.filterCoverageExtension = null;
    },
    clear(state) {
        state.choices = choices;
        state.items = new Map();
        state.sortBy = ['name'];
        state.sortDesc = [false];
        state.filterSearch = null;
        state.filterSearchOptLwc = null;
        state.filterAts = null;
        state.filterAtsInstalled = null;
        state.filterOperationMode = null;
        state.filterTrafficReceiver = null;
        state.filterAvailableForSbv = null;
        state.filterOnlyConfigProblems = null;
        state.filterCountry = null;
        state.filterCoverageExtension = null;
    },
    setChoices(state, value) {
        checkTypes.assert.object(value);
        state.choices = value;
    },
    updateChoices(state, value) {
        checkTypes.assert.object(value);
        state.choices = { ...state.choices, ...value };
    },
    setPage(state, value) {
        state.page = valueTransform.toInteger(value, { nullable: false });
    },
    setLimit(state, value) {
        state.limit = valueTransform.toInteger(value, { nullable: false });
    },
    setSortBy(state, value) {
        state.sortBy = valueTransform.toString(value);
    },
    setSortDesc(state, value) {
        state.sortDesc = valueTransform.toBoolean(value, { nullable: true });
    },
    setFilterSearch(state, value) {
        state.filterSearch = valueTransform.toString(value);
    },
    setFilterSearchOptLwc(state, value) {
        state.filterSearchOptLwc = valueTransform.toBoolean(value, { nullable: true });
    },
    setFilterAtsInstalled(state, value) {
        state.filterAtsInstalled = valueTransform.toBoolean(value, { nullable: true });
    },
    setFilterAts(state, value) {
        state.filterAts = valueTransform.toString(value);
    },
    setFilterOperationMode(state, value) {
        state.filterOperationMode = valueTransform.toStringList(value);
    },
    setFilterTrafficReceiver(state, value) {
        state.filterTrafficReceiver = valueTransform.toBoolean(value, { nullable: true });
    },
    setFilterAvailableForSbv(state, value) {
        state.filterAvailableForSbv = valueTransform.toBoolean(value, { nullable: true });
    },
    setFilterOnlyConfigProblems(state, value) {
        state.filterOnlyConfigProblems = valueTransform.toBoolean(value, { nullable: true });
    },
    setFilterCountry(state, value) {
        if (value != null) checkTypes.assert.array.of.nonEmptyString(value);
        state.filterCountry = value;
    },
    setFilterCoverageExtension(state, value) {
        state.filterCoverageExtension = valueTransform.toBoolean(value, { nullable: true });
    },
    setTotalItems(state, value) {
        state.totalItems = valueTransform.toInteger(value, { nullable: false });
    },
    setItems(state, value) {
        value = value || [];
        state.items = new Map();
        value.forEach(function (item) {
            state.items.set(item._id, item);
        });
        // IMPORTANT change state by setting new Map since simply calling "set" directly won't propagate state change
        state.items = new Map(state.items.entries());
    },
    removeItem(state, value) {
        checkTypes.assert.nonEmptyString(value);
        state.items.delete(value);
        // IMPORTANT change state by setting new Map since simply calling "set" directly won't propagate state change
        state.items = new Map(state.items.entries());
    },
};

export const actions = {
    async load({ commit, state }) {
        // append query parameters defined by selected filter values
        const { items, totalCount } = await this.$axios.$get('/api/operating/ats-installations', {
            params: {
                page: state.page,
                limit: state.limit,
                sortBy: state.sortBy,
                sortDesc: state.sortDesc,
                search: state.filterSearch,
                searchOptLwc: state.filterSearchOptLwc,
                ats: state.filterAts,
                atsInstalled: state.filterAtsInstalled,
                operationMode: state.filterOperationMode,
                trafficReceiver: state.filterTrafficReceiver,
                availableForSbv: state.filterAvailableForSbv,
                onlyConfigProblems: state.filterOnlyConfigProblems,
                country: state.filterCountry,
                coverageExtension: state.filterCoverageExtension,
                fields: [
                    '_id',
                    'kind',
                    'name',
                    'country',
                    'readOnly',
                    'coverageExtension',
                    'atsInstalled',
                    'operationMode',
                    'trafficReceiver',
                    'availableForSbv',
                    'ats._id',
                    'ats.kind',
                    'ats.serialNumber',
                    'ats.deviceId',
                    'internetConnection.mobile.provider',
                    'internetConnection.broadband.provider',
                    'geometry',
                    'configurationProblems',
                ].join(','),
                populateRefs: true,
            },
        });

        commit('setTotalItems', totalCount);
        commit('setItems', items);
    },
    async delete({ state }, id) {
        await this.$axios.$delete(`/api/operating/ats-installations/${id}`);
    },
    async lock({ state }, ids) {
        return this.$axios.$post('/api/operating/ats-installations/lock', ids);
    },
    async unlock({ state }, ids) {
        return this.$axios.$post('/api/operating/ats-installations/unlock', ids);
    },
};
