import checkTypes from 'check-types';
import * as valueTransform from '@/helpers/value-transform';

export const state = () => ({
    choices: {},
    id: null,
    readOnly: false,
    name: null,
});

export const mutations = {
    clear(state) {
        state.choices = {};
        state.id = null;
        state.readOnly = false;
        state.name = null;
    },
    setChoices(state, choices) {
        state.choices = choices;
    },
    setId(state, value) {
        state.id = valueTransform.toString(value, { nullable: false });
    },
    setReadOnly(state, value) {
        state.readOnly = valueTransform.toBoolean(value);
    },
    setName(state, value) {
        state.name = valueTransform.toString(value);
    },
};

export const actions = {
    async load({ commit }, id) {
        checkTypes.assert.nonEmptyString(id);

        const { _id, readOnly, name } = await this.$axios.$get(`/api/operating/wind-parks/${id}`);

        commit('setId', _id);
        commit('setReadOnly', readOnly);
        commit('setName', name);
    },
    async new({ state }) {
        return this.$axios.$post('/api/operating/wind-parks', { name: state.name });
    },
    async edit({ state }) {
        return this.$axios.$patch(`/api/operating/wind-parks/${state.id}`, { name: state.name });
    },
};
