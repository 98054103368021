import * as valueTransform from '@/helpers/value-transform';

export const state = () => ({
    onlySbv: false,
    windTurbines: true,
    atsInstallations: true,
    atsCoverages: false,
    draftWindTurbines: true,
    draftAtsCoverages: false,
    mobileCoverageNr: false,
    mobileCoverageLte: false,
});

export const mutations = {
    clear(state) {
        state.onlySbv = false;
        state.windTurbines = true;
        state.atsInstallations = true;
        state.atsCoverages = false;
        state.draftWindTurbines = true;
        state.draftAtsCoverages = false;
        state.mobileCoverageNr = false;
        state.mobileCoverageLte = false;
    },
    setOnlySbv(state, value) {
        state.onlySbv = valueTransform.toBoolean(value);
    },
    setWindTurbines(state, value) {
        state.windTurbines = valueTransform.toBoolean(value);
    },
    setAtsInstallations(state, value) {
        state.atsInstallations = valueTransform.toBoolean(value);
    },
    setAtsCoverages(state, value) {
        state.atsCoverages = valueTransform.toBoolean(value);
    },
    setDraftWindTurbines(state, value) {
        state.draftWindTurbines = valueTransform.toBoolean(value);
    },
    setDraftAtsCoverages(state, value) {
        state.draftAtsCoverages = valueTransform.toBoolean(value);
    },
    setMobileCoverageNr(state, value) {
        state.mobileCoverageNr = valueTransform.toBoolean(value);
    },
    setMobileCoverageLte(state, value) {
        state.mobileCoverageLte = valueTransform.toBoolean(value);
    },
};
