import { Enum } from '@/helpers/choices/enum';

export class DigitalInputOptionsEnum extends Enum {}

DigitalInputOptionsEnum.values = {
    ACT_LOW: 0,
    ACT_HIGH: 1,
    PULSE_2S: 2,
};

DigitalInputOptionsEnum.names = {
    0: 'Active Low',
    1: 'Active High',
    2: 'Period Pulse (2 seconds)',
};

Object.freeze(DigitalInputOptionsEnum);
