import cleanDeep from 'clean-deep';
import checkTypes from 'check-types';

export const state = () => ({
    uploadItems: null,
    items: new Map(),
});

export const getters = {
    getItems: (state) => {
        // IMPORTANT make sure to return an array calling values which will only return list of values and not a "keyed valued"
        return Array.from(state.items.values());
    },
    isReadyToMigrate: (state) => {
        // checks that the items have the required parameters set and if true, returns true
        // check that required operator is set, all other values are optional
        const windParks = Array.from(state.items.values());

        let isReadyToMigrate = false;
        for (const windPark of windParks) {
            if (!windPark.name) {
                isReadyToMigrate = false;
                break;
            }
            // otherwise, go on
            isReadyToMigrate = true;
        }

        return isReadyToMigrate;
    },
};

export const mutations = {
    clear(state) {
        state.uploadItems = null;
        state.items = new Map();
    },
    setItems(state, value) {
        value = value || [];
        state.items = new Map();
        value.forEach(function (item) {
            state.items.set(item.importId, item);
        });
        // IMPORTANT change state by setting new Map since simply calling "set" directly won't propagate state change
        state.items = new Map(state.items.entries());
    },
    setItem(state, { key, value }) {
        checkTypes.assert.nonEmptyString(key);
        checkTypes.assert.nonEmptyObject(value);
        // update wind turbine in items list
        state.items.set(key, value);
        // IMPORTANT change state by setting new Map since simply calling "set" directly won't propagate state change
        state.items = new Map(state.items.entries());
    },
    removeItem(state, value) {
        checkTypes.assert.nonEmptyString(value);
        checkTypes.assert.nonEmptyString(value);
        state.items.delete(value);
        // IMPORTANT change state by setting new Map since simply calling "set" directly won't propagate state change
        state.items = new Map(state.items.entries());
    },
    setUploadItems(state, value) {
        checkTypes.assert.nonEmptyArray(value);
        // convert wind parks input lit into list of distinct wind park names
        state.uploadItems = [...new Set(value)];
    },
};

export const actions = {
    async prepareData({ state }) {
        return this.$axios.$post('/api/operating/import/wind-parks/prepare', state.uploadItems);
    },
    async migrateData({ state }) {
        const migrateDataset = Array.from(state.items.values());
        // prepare migrate dataset for insertion and post each item to the default CRUD post endpoint
        let migrateCount = 0;
        let items = [];
        for (const migrateData of migrateDataset) {
            let denormalized = {
                name: migrateData.name,
            };
            items.push(cleanDeep(denormalized));
            migrateCount++;
        }
        await this.$axios.$post('/api/operating/import/wind-parks/import', { items });

        return { totalCount: migrateDataset.length, migrateCount };
    },
};
