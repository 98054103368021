import checkTypes from 'check-types';

export const state = () => ({
    ats: null,
    status: null,
});

export const mutations = {
    clear(state) {
        state.ats = {};
        state.status = {};
    },
    setAts(state, value) {
        if (value != null) checkTypes.assert.nonEmptyObject(value);
        state.ats = value;
    },
    setStatus(state, value) {
        if (checkTypes.nonEmptyObject(value) === false) {
            value = null;
        }
        if (value != null) checkTypes.assert.nonEmptyObject(value);
        state.status = value;
    },
};

export const actions = {
    async loadAts({ commit }, id) {
        checkTypes.assert.nonEmptyString(id);

        const ats = await this.$axios.$get(`/api/operating/ats/${id}`, {
            params: {
                fields: ['_id', 'kind', 'readOnly', 'deviceId', 'partNumber', 'serialNumber'].join(','),
            },
        });

        commit('setAts', ats);
    },
    async loadStatus({ commit }, id) {
        checkTypes.assert.nonEmptyString(id);

        const status = await this.$axios.$get(`/api/bnk-mediator/mediate/ats/${id}/status`, {});

        commit('setStatus', status);
    },
};
