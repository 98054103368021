import checkTypes from 'check-types';
import cleanDeep from 'clean-deep';
import { OperationModeEnum } from '../../../helpers/choices/ats-installation/operation-mode-enum';
import { StateEnum } from '../../../helpers/choices/commissioning-process/state-enum';

export const state = () => ({
    items: [],
    project: null,
    commissioningProcesses: null,
    windTurbines: [],
    atsInstallations: [],
});

export const mutations = {
    clear(state) {
        state.items = [];
        state.project = null;
        state.project = null;
        state.windTurbines = [];
        state.atsInstallations = [];
        state.commissioningProcesses = [];
    },
    setProject(state, value) {
        if (value) checkTypes.assert.nonEmptyObject(value);
        state.project = value;
    },
    setWindTurbines(state, value) {
        checkTypes.assert.array.of.nonEmptyObject(value);
        state.windTurbines = value;
    },
    setAtsInstallations(state, value) {
        checkTypes.assert.array.of.nonEmptyObject(value);
        state.atsInstallations = value;
    },
    setCommissioningProcesses(state, value) {
        if (value) checkTypes.assert.array.of.nonEmptyObject(value);
        state.commissioningProcesses = value;
    },
    setItems(state, value) {
        if (value) checkTypes.assert.array.of.nonEmptyObject(value);
        state.items = value;
    },
};

export const actions = {
    async load({ commit }, id) {
        checkTypes.assert.nonEmptyString(id);

        const project = await this.$axios.$get(`/api/operating/projects/${id}`);
        // load linked users
        const { items: windTurbines } = await this.$axios.$get('/api/operating/wind-turbines', {
            params: {
                project: id,
                fields: [
                    '_id',
                    'serialNumber',
                    'manufacturer',
                    'operatorIdent',
                    'windPark',
                    'type',
                    'geometry',
                    'ctrlAtsInstallation._id',
                    'ctrlAtsInstallation.name',
                    'ctrlAtsInstallation.geometry',
                    'ctrlAtsInstallation.operationMode',
                    'ctrlAtsInstallation.trafficReceiver',
                    'ctrlAtsInstallation.atsInstalled',
                    'ctrlAtsInstallation.availableForSbv',
                    'ctrlAtsInstallation.ats._id',
                    'ctrlAtsInstallation.ats.kind',
                    'ctrlAtsInstallation.ats.serialNumber',
                    'sensorAtsInstallation._id',
                    'sensorAtsInstallation.name',
                    'sensorAtsInstallation.geometry',
                    'sensorAtsInstallation.operationMode',
                    'sensorAtsInstallation.trafficReceiver',
                    'sensorAtsInstallation.atsInstalled',
                    'sensorAtsInstallation.availableForSbv',
                    'sensorAtsInstallation.ats._id',
                    'sensorAtsInstallation.ats.kind',
                    'sensorAtsInstallation.ats.serialNumber',
                ].join(','),
                populateRefs: true,
            },
        });

        // extract ats installations from wind turbines
        const projectAtsInstallations = new Map();
        windTurbines.forEach((value) => {
            const { ctrlAtsInstallation, sensorAtsInstallation } = value;
            if (ctrlAtsInstallation) {
                projectAtsInstallations.set(ctrlAtsInstallation._id, ctrlAtsInstallation);
            }
            if (sensorAtsInstallation && sensorAtsInstallation?.length > 0) {
                for (const sensorAtsInst of sensorAtsInstallation) {
                    projectAtsInstallations.set(sensorAtsInst._id, sensorAtsInst);
                }
            }
        });
        const atsInstallations = Array.from(projectAtsInstallations.values());

        const { items: commissioningProcesses } = await this.$axios.$get(`/api/operating/commissioning-processes`, {
            params: {
                project: id,
                fields: [
                    '_id',
                    'project._id',
                    'atsInstallation._id',
                    'atsInstallation.atsInstalled',
                    'projectName',
                    'windParkName',
                    'atsInstallationName',
                    'atsSerialNumber',
                    'processor',
                    'issueUri',
                    'state',
                    'logs',
                    'sharedLink',
                    'createdAt',
                    'createdBy',
                    'startedAt',
                    'finishedAt',
                ],
                populateRefs: true,
            },
        });

        // merge loaded data into specific page items
        const items = [];
        for (const atsInstallation of atsInstallations) {
            let item = {};
            const {
                _id: atsInstallationId,
                ats: atsInstallationAts,
                name: atsInstallationName,
                trafficReceiver: atsInstallationTrafficReceiver,
                operationMode: atsInstallationOperationMode,
                atsInstalled: atsInstallationAtsInstalled,
            } = atsInstallation;
            // find commissioning process for ATS
            const atsCommissioningProcesses = commissioningProcesses.filter((value) => value.atsInstallation._id === atsInstallationId);
            // check if we have pending processes
            let hasPendingProcess = false;
            atsCommissioningProcesses.forEach((value) => {
                if ([StateEnum.values.PENDING, StateEnum.values.STARTED].includes(value.state)) {
                    hasPendingProcess = true;
                }
            });
            // find wind park
            let windParkName = null;
            windTurbines.forEach((value) => {
                const { ctrlAtsInstallation, sensorAtsInstallation, windPark } = value;
                const { _id: ctrlId } = ctrlAtsInstallation || {};
                const sensorIds = sensorAtsInstallation?.map((value) => value._id);

                if (sensorIds.includes(atsInstallationId) || ctrlId === atsInstallationId) {
                    windParkName = windPark?.name;
                }
            });

            items.push({
                // use ATS Installation id for object id, used to sort, order, list keys etc.
                _id: atsInstallationId,
                // add explicitly named property (_id value above is too generic and may change eventually)
                atsInstallationId,
                projectName: project.projectId,
                windParkName,
                atsInstallationName,
                atsSerialNumber: atsInstallationAts?.serialNumber,
                trafficReceiver: atsInstallationTrafficReceiver,
                operationMode: atsInstallationOperationMode,
                atsInstalled: atsInstallationAtsInstalled,
                commissioningProcesses: atsCommissioningProcesses,
                // indicates if a commissioning process can be started for this ATS Installation
                isCommissionable:
                    hasPendingProcess === false &&
                    atsInstallationOperationMode === OperationModeEnum.values.INOPERATIVE &&
                    atsInstallationAtsInstalled === false &&
                    atsInstallationAts != null,
            });
        }

        commit('setProject', project);
        commit('setCommissioningProcesses', commissioningProcesses);
        commit('setWindTurbines', windTurbines);
        commit('setAtsInstallations', atsInstallations);
        commit('setItems', items);
    },

    async changeStateCommissioningProcess({ state }, payload) {
        const { id, state: newState, comment } = payload;

        return this.$axios.$patch(`/api/operating/commissioning-processes/${id}/change-state`, cleanDeep({ state: newState, comment }));
    },
};
