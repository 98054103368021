<template>
    <v-app>
        <navbar-public />
        <v-main>
            <maintenance-mode-alert />
            <nuxt />
        </v-main>
        <page-footer />
        <scroll-to-top-button />
    </v-app>
</template>

<script>
import NavbarPublic from '@/components/NavbarPublic';
import PageFooter from '@/components/PageFooter';
import ScrollToTopButton from '@/components/ScrollToTopButton';
import MaintenanceModeAlert from '@/components/MaintenanceModeAlert';

export default {
    name: 'AppPublic',
    components: {
        NavbarPublic,
        MaintenanceModeAlert,
        ScrollToTopButton,
        PageFooter,
    },
};
</script>
