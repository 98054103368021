import checkTypes from 'check-types';
import cleanDeep from 'clean-deep';
import { DateTime } from 'luxon';
import { DATE_FORMAT } from '@/globals';
import { _denormalize, _mutations as commonMutations, _state as commonState } from '@/helpers/store-atm12-common';

export const state = () => ({ ...commonState });

export const mutations = { ...commonMutations };

export const actions = {
    async load({ commit }, id) {
        checkTypes.assert.nonEmptyString(id);

        const {
            _id,
            readOnly,
            serialNumber,
            partNumber,
            builderName,
            buildDate,
            housing,
            routerType,
            routerSerial,
            sdCard,
            macEther1,
            macEther2,
            macEther3,
            modemType,
            modemSerialNumber,
            modemImei,
            simOperator,
            simIccid,
            simIp,
            simUsesPrivateApn,
            vpnIp,
            parkNetIp,
            baseBoard,
            uflCable,
            remarks,
        } = await this.$axios.$get(`/api/operating/atm/${id}`);

        commit('setId', _id);
        commit('setReadOnly', readOnly);
        commit('setSerialNumber', serialNumber);
        commit('setPartNumber', partNumber);
        commit('setBuilderName', builderName);
        commit('setBuildDate', buildDate ? DateTime.fromISO(buildDate).toFormat(DATE_FORMAT) : null);
        commit('setHousing', housing);
        commit('setRouterType', routerType);
        commit('setRouterSerial', routerSerial);
        commit('setSdCard', sdCard);
        commit('setMacEther1', macEther1);
        commit('setMacEther2', macEther2);
        commit('setMacEther3', macEther3);
        commit('setModemType', modemType);
        commit('setModemSerialNumber', modemSerialNumber);
        commit('setModemImei', modemImei);
        commit('setSimOperator', simOperator);
        commit('setSimIccid', simIccid);
        commit('setSimIp', simIp);
        commit('setSimUsesPrivateApn', simUsesPrivateApn);
        commit('setVpnIp', vpnIp);
        commit('setParkNetIp', parkNetIp);
        commit('setBaseBoard', baseBoard);
        commit('setUflCable', uflCable);
        commit('setRemarks', remarks);
    },
    async new({ state }) {
        let denormalized = _denormalize(state);

        return this.$axios.$post('/api/operating/atm/atm1', cleanDeep(denormalized));
    },
    async edit({ state }) {
        let denormalized = _denormalize(state);
        // IMPORTANT serial number cannot be edited anymore
        delete denormalized.serialNumber;

        return this.$axios.$patch(`/api/operating/atm/atm1/${state.id}`, denormalized);
    },
};
