import checkTypes from 'check-types';

export const state = () => ({
    enerconScadaCa: {},
});

export const mutations = {
    clear(state) {
        state.enerconScadaCa = {};
    },
    setEnerconScadaCa(state, value) {
        checkTypes.assert.nonEmptyObject(value);
        state.enerconScadaCa = value;
    },
};

export const actions = {
    async load({ commit }, id) {
        checkTypes.assert.nonEmptyString(id);

        const enerconScadaCa = await this.$axios.$get(`/api/operating/certificates/enercon/scada/${id}`, {
            params: {
                fields: [
                    '_id',
                    'readOnly',
                    'scadaId',
                    'plainPkcs12Password',
                    'pkcs12Password',
                    'pkcs12File',
                    'commCaCertFile',
                    'scadaCaCertFile',
                    'createdAt',
                    'updatedAt',
                    'createdBy',
                    'updatedBy',
                ].join(','),
                populateRefs: true,
            },
        });

        commit('setEnerconScadaCa', enerconScadaCa);
    },
    async delete({ state }, id) {
        await this.$axios.$delete(`/api/operating/certificates/enercon/scada/${id}`);
    },
};
