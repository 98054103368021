<template>
    <v-app-bar app dense dark class="navbar" clipped-left>
        <v-app-bar-nav-icon class="mr-3" @click="openSidebar()" />
        <nuxt-link v-show="$vuetify.breakpoint.smAndUp" :to="propBackHomeLink" class="hidden-sm-and-down">
            <img :src="`${$config.COMMON_WEB_ASSETS_URL}/logo.png?hl=en`" alt="site-logo" height="30" :title="propBackHomeTitle" />
        </nuxt-link>
        <v-spacer />
        <app-switcher />
        <notification-list v-if="authUser.hasPermission([permissions.NOTIFICATION_NOTIFICATION_READ])" />
        <help-menu />
        <user-menu />
    </v-app-bar>
</template>

<script>
import UserMenu from '@/components/UserMenu';
import HelpMenu from '@/components/HelpMenu';
import NotificationList from '@/components/NotificationList';
import rbacMeta from '@/mixins/rbac-meta';
import AppSwitcher from '@/components/AppSwitcher';

export default {
    name: 'Navbar',
    components: { AppSwitcher, NotificationList, HelpMenu, UserMenu },
    mixins: [rbacMeta],
    props: {
        propHideToggle: {
            type: Boolean,
            required: false,
            default: false,
        },
        propBackHomeLink: {
            type: String,
            required: false,
            default: '/',
        },
        propBackHomeTitle: {
            type: String,
            required: false,
            default: 'Back to home',
        },
    },
    methods: {
        openSidebar() {
            this.$store.commit('setMainNavigation', true);
        },
    },
};
</script>

<style scoped lang="scss">
#navbar-portal-title {
    font-size: 1.2em !important;
    margin-left: 1em !important;
}
</style>
