import * as valueTransform from '@/helpers/value-transform';
import checkTypes from 'check-types';

export const state = () => ({
    id: null,
    email: null,
    // is set to true to initially hide the alert box, otherwise will show up and disappear on page load instead
    emailVerified: true,
    displayName: null,
    businessEntity: null,
    notifyBnkSystemErrors: false,
    notifyBnkSystemErrorsThreshold: 0,
});

export const mutations = {
    clear(state) {
        state.id = null;
        state.email = null;
        // is set to true to initially hide the alert box, otherwise will show up and disappear on page load instead
        state.emailVerified = true;
        state.displayName = null;
        state.businessEntity = null;
        state.notifyBnkSystemErrors = false;
        state.notifyBnkSystemErrorsThreshold = 0;
    },
    clearInfo(state) {
        state.id = null;
        state.email = null;
        // is set to true to initially hide the alert box, otherwise will show up and disappear on page load instead
        state.emailVerified = true;
        state.displayName = null;
    },
    setId(state, value) {
        state.id = valueTransform.toString(value, { nullable: false });
    },
    setEmail(state, value) {
        state.email = valueTransform.toString(value);
    },
    setEmailVerified(state, value) {
        state.emailVerified = valueTransform.toBoolean(value);
    },
    setDisplayName(state, value) {
        state.displayName = valueTransform.toString(value);
    },
    setBusinessEntity(state, value) {
        if (value != null) checkTypes.assert.nonEmptyObject(value);
        state.businessEntity = value;
    },
    setNotifyBnkSystemErrors(state, value) {
        state.notifyBnkSystemErrors = valueTransform.toBoolean(value);
    },
    setNotifyBnkSystemErrorsThreshold(state, value) {
        state.notifyBnkSystemErrorsThreshold = valueTransform.toInteger(value);
    },
};

export const actions = {
    async load({ commit }) {
        const { uid, email, emailVerified, displayName, businessEntity: businessEntityId } = await this.$axios.$get(`/api/iam/users/profile`);

        commit('setId', uid);
        commit('setEmail', email);
        commit('setEmailVerified', emailVerified);
        commit('setDisplayName', displayName);
        commit('setDisplayName', displayName);

        // load business entity metadata if user is linked
        if (businessEntityId) {
            const { _id, name } = await this.$axios.$get(`/api/operating/business-entities/${businessEntityId}`, {
                params: { fields: '_id,name' },
            });
            commit('setBusinessEntity', { _id, name });
        }
    },
    async edit({ state }) {
        const denormalized = {
            displayName: state.displayName,
        };
        await this.$axios.$patch(`/api/iam/users/profile`, denormalized);
    },
};
