<template>
    <v-app>
        <map-navbar :prop-back-home-link="homeLink" :prop-back-home-title="homeTitle" />
        <notification />
        <nuxt />
    </v-app>
</template>

<script>
import rbacMeta from '@/mixins/rbac-meta';
import Notification from '@/components/Notification';
import MapNavbar from '@/components/MapNavbar';
import { permissions } from '@/rbac';
import BaseLayout from '@/layouts/base';

export default {
    name: 'MapLayout',
    components: { MapNavbar, Notification },
    extends: BaseLayout,
    mixins: [rbacMeta],
    meta: {
        auth: { permissions: [permissions.OPERATING_IMPORT_WINDTURBINES] },
    },
    computed: {
        homeLink() {
            return this.$store.state.mapHomeLink;
        },
        homeTitle() {
            return this.$store.state.mapHomeTitle;
        },
    },
};
</script>
