import { Enum } from '@/helpers/choices/enum';

export class MobileRouterEnum extends Enum {}

MobileRouterEnum.values = {
    ROUTER_1: 0,
    ROUTER_2: 1,
};

MobileRouterEnum.names = {
    0: 'Router 1',
    1: 'Router 2',
};

Object.freeze(MobileRouterEnum);
