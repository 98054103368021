import { Enum } from '@/helpers/choices/enum';

export class AntennaTypeFlarmEnum extends Enum {}

AntennaTypeFlarmEnum.values = {
    GENERATION_1: 0,
    GENERATION_2: 1,
};

AntennaTypeFlarmEnum.names = {
    0: 'Generation 1',
    1: 'Generation 2',
};

Object.freeze(AntennaTypeFlarmEnum);
