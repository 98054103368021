import checkTypes from 'check-types';
import getWindTurbineName from '@/helpers/get-wind-turbine-name';
import { isEmpty } from 'lodash';

export const state = () => ({
    draftWindTurbine: {},
    mobileCoverage: {},
});

export const mutations = {
    clear(state) {
        state.draftWindTurbine = {};
        state.serialNumber = null;
        state.mobileCoverage = {};
    },
    setDraftWindTurbine(state, value) {
        checkTypes.assert.nonEmptyObject(value);
        state.draftWindTurbine = value;
    },
    setMobileCoverage(state, value) {
        if (value != null) checkTypes.assert.nonEmptyObject(value);
        state.mobileCoverage = value;
    },
};

export const actions = {
    async loadMetadata({ commit }, id) {
        checkTypes.assert.nonEmptyString(id);

        const draftWindTurbine = await this.$axios.$get(`/api/operating/drafts/wind-turbines/${id}`, {
            params: {
                fields: [
                    '_id',
                    'importId',
                    'offerId',
                    'projectId',
                    'manufacturer',
                    'serialNumber',
                    'type',
                    'atsInstallation',
                    'trafficReceiver',
                    'commissioning',
                    'geometry',
                    'windPark',
                    'hubHeight',
                    'rotorLength',
                    'elevation',
                    'operatorIdent',
                    'operator',
                ].join(','),
                populateRefs: true,
            },
        });

        const { id: _id, operatorIdent, manufacturer, serialNumber } = draftWindTurbine;
        draftWindTurbine.name = getWindTurbineName({ id, operatorIdent, manufacturer, serialNumber });

        const [lon, lat] = draftWindTurbine.geometry.coordinates;
        const mobileCoverage = await this.$axios.$get(`/api/operating/utils/mobile-coverage`, {
            params: {
                coordinates: `${lat},${lon}`,
            },
        });

        commit('setDraftWindTurbine', draftWindTurbine);
        commit('setMobileCoverage', isEmpty(mobileCoverage) ? null : mobileCoverage);
    },
    async patch({ commit, state, getters }, patchPayload) {
        const draftWindTurbine = await this.$axios.$patch(`/api/operating/drafts/wind-turbines/${state.draftWindTurbine._id}`, patchPayload);

        const { _id: id, operatorIdent, manufacturer, serialNumber } = draftWindTurbine;
        draftWindTurbine.name = getWindTurbineName({ id, operatorIdent, manufacturer, serialNumber });

        commit('setDraftWindTurbine', draftWindTurbine);

        // IMPORTANT returns the updated document => required to update selected features in selection store
        return draftWindTurbine;
    },
};
