import checkTypes from 'check-types';

export const state = () => ({
    businessEntity: {},
    users: [],
});

export const mutations = {
    clear(state) {
        state.businessEntity = {};
        state.users = [];
    },
    setBusinessEntity(state, value) {
        checkTypes.assert.nonEmptyObject(value);
        state.businessEntity = value;
    },
    setUsers(state, value) {
        checkTypes.assert.array.of.nonEmptyObject(value);
        state.users = value;
    },
};

export const actions = {
    async load({ commit }, id) {
        checkTypes.assert.nonEmptyString(id);

        const businessEntity = await this.$axios.$get(`/api/operating/business-entities/${id}`);
        // load linked users
        const { items: users } = await this.$axios.$get('/api/iam/users', {
            params: { businessEntity: id, fields: 'uid,email,displayName' },
        });

        commit('setBusinessEntity', businessEntity);
        commit('setUsers', users);
    },
    async delete({ state }, id) {
        await this.$axios.$delete(`/api/operating/business-entities/${id}`);
    },
};
