import checkTypes from 'check-types';
import getWindTurbineName from '@/helpers/get-wind-turbine-name';
import getDraftWindTurbineName from '@/helpers/get-draft-wind-turbine-name';
import * as valueTransform from '@/helpers/value-transform';
import * as uuid from 'uuid';

export const state = () => ({
    sidebar: false,
    poi: null,
    searchPoi: null,
    selectedPoi: null,
    searchPoiResult: new Map(),
});

export const mutations = {
    clear(state) {
        state.sidebar = false;
        state.poi = null;
        state.searchPoi = null;
        state.selectedPoi = null;
        state.searchPoiResult = new Map();
    },
    setSidebar(state, value) {
        state.sidebar = valueTransform.toBoolean(value);
    },
    setPoi(state, value) {
        if (value != null) checkTypes.assert.nonEmptyObject(value);
        state.poi = value;
    },
    setSearchPoi(state, value) {
        state.searchPoi = valueTransform.toString(value, { nullable: true });
    },
    setSelectedPoi(state, value) {
        if (value != null) checkTypes.assert.nonEmptyObject(value);
        state.selectedPoi = value;
    },
    setSearchPoiResult(state, value) {
        value = value || [];
        state.searchPoiResult = new Map();
        value.forEach(function (item) {
            state.searchPoiResult.set(item._id, item);
        });
        // IMPORTANT change state by setting new Map since simply calling "set" directly won't propagate state change
        state.searchPoiResult = new Map(state.searchPoiResult.entries());
    },
};

export const actions = {
    async searchPoi({ commit }, { search, location }) {
        if (!checkTypes.string(search) && !checkTypes.nonEmptyArray(location)) {
            return;
        }

        // Google backend -> load cities that are within 500km of the current map center that match the search input
        const locations = await this.$axios.$get('/api/operating/utils/geocode-cities', {
            params: { search: encodeURIComponent(search), location: `${location.lat},${location.lng}` },
        });
        // BNK backend
        const { items: windTurbines } = await this.$axios.$get('/api/operating/wind-turbines', {
            params: { search },
        });
        const { items: draftWindTurbines } = await this.$axios.$get('/api/operating/drafts/wind-turbines', {
            params: { search },
        });
        const { items: windParks } = await this.$axios.$get('/api/operating/wind-parks', {
            params: { search },
        });
        const { items: atsInstallations } = await this.$axios.$get('/api/operating/ats-installations', {
            params: { search },
        });

        const searchResult = [];
        for (const turbine of windTurbines) {
            const { _id, manufacturer, serialNumber, operatorIdent } = turbine;
            // crate a turbine name for viewing purposes
            const name = getWindTurbineName({ id: _id, manufacturer, serialNumber, operatorIdent });
            searchResult.push({ _id, name, typeName: 'Wind Turbine', type: 'wind_turbine' });
        }
        for (const windPark of windParks) {
            const { _id, name } = windPark;
            searchResult.push({ _id, name, typeName: 'Wind Park', type: 'wind_park' });
        }
        for (const atsInstallation of atsInstallations) {
            const { _id, name } = atsInstallation;
            searchResult.push({ _id, name, typeName: 'ATS Installation', type: 'ats_installation' });
        }
        for (const draftWindTurbine of draftWindTurbines) {
            const { _id, manufacturer, serialNumber, operatorIdent, importId } = draftWindTurbine;
            // crate a draft turbine name for viewing purposes
            const name = getDraftWindTurbineName({ id: _id, manufacturer, serialNumber, operatorIdent, importId });
            searchResult.push({ _id, name, typeName: 'Draft Wind Turbine', type: 'draft_wind_turbine' });
        }
        for (const city of locations) {
            const _id = uuid.v4();
            const { description, location } = city;
            searchResult.push({
                _id,
                name: description,
                typeName: 'City/Location',
                type: 'feature-location-search',
                location,
            });
        }

        commit('setSearchPoiResult', searchResult);
    },
};
