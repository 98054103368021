import { Enum } from '@/helpers/choices/enum';

export class PriorityEnum extends Enum {}

PriorityEnum.values = {
    LOW: 'low',
    NORMAL: 'normal',
    HIGH: 'high',
    CRITICAL: 'critical',
};

PriorityEnum.names = {
    low: 'Low',
    normal: 'Normal',
    high: 'High',
    critical: 'Critical',
};

Object.freeze(PriorityEnum);
