import checkTypes from 'check-types';
import getWindTurbineName from '@/helpers/get-wind-turbine-name';
import { isEmpty } from 'lodash';

export const state = () => ({
    windTurbine: {},
    mobileCoverage: {},
});

export const mutations = {
    clear(state) {
        state.windTurbine = {};
        state.mobileCoverage = {};
    },
    setWindTurbine(state, value) {
        checkTypes.assert.nonEmptyObject(value);
        state.windTurbine = value;
    },
    setMobileCoverage(state, value) {
        if (value != null) checkTypes.assert.nonEmptyObject(value);
        state.mobileCoverage = value;
    },
};

export const actions = {
    async loadMetadata({ commit }, id) {
        checkTypes.assert.nonEmptyString(id);

        const windTurbine = await this.$axios.$get(`/api/operating/wind-turbines/${id}`, {
            params: {
                fields: [
                    '_id',
                    'project',
                    'manufacturer',
                    'serialNumber',
                    'type',
                    'commissioning',
                    'geometry',
                    'windPark._id',
                    'windPark.name',
                    'hubHeight',
                    'rotorLength',
                    'elevation',
                    'operatorIdent',
                    'ctrlAtsInstallation._id',
                    'ctrlAtsInstallation.name',
                    'sensorAtsInstallation._id',
                    'sensorAtsInstallation.name',
                    'responsibilities.businessEntity',
                    'responsibilities.responsibility',
                ].join(','),
                populateRefs: true,
            },
        });

        const [lon, lat] = windTurbine.geometry.coordinates;
        const mobileCoverage = await this.$axios.$get(`/api/operating/utils/mobile-coverage`, {
            params: {
                coordinates: `${lat},${lon}`,
            },
        });

        const { _id, operatorIdent, manufacturer, serialNumber } = windTurbine;
        windTurbine.name = getWindTurbineName({ id: _id, operatorIdent, manufacturer, serialNumber });

        commit('setWindTurbine', windTurbine);
        commit('setMobileCoverage', isEmpty(mobileCoverage) ? null : mobileCoverage);
    },
};
