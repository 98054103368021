import checkTypes from 'check-types';
import cleanDeep from 'clean-deep';
import {
    _state as commonState,
    _mutations as commonMutations,
    _actions as commonActions,
    _denormalize as commonDenormalize,
} from '@/helpers/store-ats34-common';
import { DateTime } from 'luxon';
import { DATE_FORMAT } from '@/globals';

export const state = () => ({
    ...commonState,
    // ATS-3 specific
    choices: {
        atm1: [],
        atm2: [],
        atm3: [],
        atm4: [],
        atm5: [],
    },
});

export const getters = {};

export const mutations = {
    ...commonMutations,
    setChoices(state, value) {
        checkTypes.assert.object(value);
        state.choices = value;
    },
    updateChoices(state, value) {
        checkTypes.assert.object(value);
        state.choices = { ...state.choices, ...value };
    },
    clear(state) {
        state.choices = {
            atm1: [],
            atm2: [],
            atm3: [],
            atm4: [],
            atm5: [],
        };
        state.id = null;
        state.kind = null;
        state.readOnly = false;
        state.serialNumber = null;
        state.partNumber = null;
        state.deviceId = null;
        state.builderName = null;
        state.buildDate = new Date().toISOString().substring(0, 10);
        state.housing = null;
        state.mountingPlate = null;
        state.powerSupply = null;
        state.heating = null;
        state.relays = null;
        state.lvl2TermBlock = null;
        state.lvl3TermBlock = null;
        state.circuitBreaker = null;
        state.vpu = null;
        state.termBlockBlue = null;
        state.termBlockGrey = null;
        state.earthLeadTerm = null;
        state.connector30Pin = null;
        state.connector10Pin = null;
        state.rj45Coupler = null;
        state.antennaCableLte = null;
        state.flangePlate = null;
        state.router1 = null;
        state.router2 = null;
        state.cpuModule = null;
        state.ioModule = null;
        state.dsub3WayCable = null;
        state.dsubCableGreen = null;
        state.rj45CableOrange025m = null;
        state.rj45CableRed025m = null;
        state.rj45CableBlue025m = null;
        state.rj45CableRed05m = null;
        state.rj45CableBlue05m = null;
        state.remarks = null;
    },
};

export const actions = {
    ...commonActions,
    async load({ commit }, id) {
        checkTypes.assert.nonEmptyString(id);

        const {
            _id,
            kind,
            readOnly,
            deviceId,
            partNumber,
            serialNumber,
            builderName,
            buildDate,
            housing,
            mountingPlate,
            powerSupply,
            heating,
            relays,
            lvl2TermBlock,
            lvl3TermBlock,
            circuitBreaker,
            vpu,
            termBlockBlue,
            termBlockGrey,
            earthLeadTerm,
            connector30Pin,
            connector10Pin,
            rj45Coupler,
            antennaCableLte,
            flangePlate,
            router1,
            router2,
            cpuModule,
            ioModule,
            dsub3WayCable,
            dsubCableGreen,
            rj45CableOrange025m,
            rj45CableRed025m,
            rj45CableBlue025m,
            rj45CableRed05m,
            rj45CableBlue05m,
            remarks,
        } = await this.$axios.$get(`/api/operating/ats/${id}`);

        commit('setId', _id);
        commit('setKind', kind);
        commit('setReadOnly', readOnly);
        commit('setDeviceId', deviceId);
        commit('setSerialNumber', serialNumber);
        commit('setPartNumber', partNumber);
        commit('setBuilderName', builderName);
        commit('setBuildDate', DateTime.fromISO(buildDate).toFormat(DATE_FORMAT));
        commit('setHousing', housing);
        commit('setMountingPlate', mountingPlate);
        commit('setPowerSupply', powerSupply);
        commit('setHeating', heating);
        commit('setRelays', relays);
        commit('setLvl2TermBlock', lvl2TermBlock);
        commit('setLvl3TermBlock', lvl3TermBlock);
        commit('setCircuitBreaker', circuitBreaker);
        commit('setVpu', vpu);
        commit('setTermBlockBlue', termBlockBlue);
        commit('setTermBlockGrey', termBlockGrey);
        commit('setEarthLeadTerm', earthLeadTerm);
        commit('setConnector30Pin', connector30Pin);
        commit('setConnector10Pin', connector10Pin);
        commit('setRj45Coupler', rj45Coupler);
        commit('setAntennaCableLte', antennaCableLte);
        commit('setFlangePlate', flangePlate);
        commit('setRouter1', router1);
        commit('setRouter2', router2);
        // object required for cpuModule for deviceId calculation based on orangepiEtherMac
        commit('setCpuModule', cpuModule);
        commit('setIoModule', ioModule);
        commit('setDsub3WayCable', dsub3WayCable);
        commit('setDsubCableGreen', dsubCableGreen);
        commit('setRj45CableOrange025m', rj45CableOrange025m);
        commit('setRj45CableRed025m', rj45CableRed025m);
        commit('setRj45CableBlue025m', rj45CableBlue025m);
        commit('setRj45CableRed05m', rj45CableRed05m);
        commit('setRj45CableBlue05m', rj45CableBlue05m);
        commit('setRemarks', remarks);
    },
    async new({ state }) {
        let denormalized = _denormalize(state);

        return this.$axios.$post('/api/operating/ats/ats4', cleanDeep(denormalized));
    },
    async edit({ state }) {
        let denormalized = _denormalize(state);
        return this.$axios.$patch(`/api/operating/ats/ats4/${state.id}`, denormalized);
    },
};

function _denormalize(state) {
    return {
        ...commonDenormalize(state),
    };
}
