import { LIST_ITEMS_PER_PAGE_DEFAULT } from '@/globals';
import { IrBeaconTypeEnum } from '@/helpers/choices/wind-turbine/ir-beacon-type-enum';
import { LightingSystemInterfaceConceptEnum } from '@/helpers/choices/wind-turbine/lighting-system-interface-concept-enum';
import { ManufacturerEnum } from '@/helpers/choices/wind-turbine/manufacturer-enum';
import checkTypes from 'check-types';
import cleanDeep from 'clean-deep';
import * as valueTransform from '@/helpers/value-transform';
import { getFormChoices as countryChoices } from '@/helpers/choices/countries';

const choices = {
    country: countryChoices(),
    manufacturer: ManufacturerEnum.getFormChoices(),
    lightingSystemInterfaceConcept: LightingSystemInterfaceConceptEnum.getFormChoices(),
    irBeaconType: IrBeaconTypeEnum.getFormChoices(),
};

export const state = () => ({
    choices,
    items: new Map(),
    page: 1,
    totalItems: 0,
    limit: LIST_ITEMS_PER_PAGE_DEFAULT,
    sortBy: null,
    sortDesc: null,
    filterSearch: null,
    filterSearchOptLwc: false,
    filterPos: null,
    filterDist: null,
    filterProject: null,
    filterBusinessEntity: null,
    filterWindPark: null,
    filterAdlsGroup: null,
    filterAtsInstallation: null,
    filterCountry: null,
    filterManufacturer: null,
    filterLsiConcept: null,
    filterIrBeaconType: null,
});

export const getters = {
    getItems: (state) => {
        // IMPORTANT make sure to return an array calling values which will only return list of values and not a "keyed valued"
        return Array.from(state.items.values());
    },
    getQueryFilters: (state) => {
        return {
            page: state.page,
            limit: state.limit,
            sortBy: state.sortBy,
            sortDesc: state.sortDesc,
            search: state.filterSearch,
            searchOptLwc: state.filterSearchOptLwc,
            pos: state.filterPos,
            dist: state.filterDist,
            project: state.filterProject,
            businessEntity: state.filterBusinessEntity,
            windPark: state.filterWindPark,
            adlsGroup: state.filterAdlsGroup,
            atsInstallation: state.filterAtsInstallation,
            country: state.filterCountry,
            manufacturer: state.filterManufacturer,
            lsiConcept: state.filterLsiConcept,
            irBeaconType: state.filterIrBeaconType,
        };
    },
    hasSpecialFilters: (state) => {
        return (
            Object.values(
                cleanDeep({
                    pos: state.filterPos,
                    dist: state.filterDist,
                    project: state.filterProject,
                    businessEntity: state.filterBusinessEntity,
                    windPark: state.filterWindPark,
                    adlsGroup: state.filterAdlsGroup,
                    atsInstallation: state.filterAtsInstallation,
                    country: state.filterCountry,
                    manufacturer: state.filterManufacturer,
                    lsiConcept: state.filterLsiConcept,
                    irBeaconType: state.filterIrBeaconType,
                })
            ).length > 0
        );
    },
};

export const mutations = {
    clearSpecialFilters(state) {
        state.filterPos = null;
        state.filterDist = null;
        state.filterProject = null;
        state.filterBusinessEntity = null;
        state.filterWindPark = null;
        state.filterAdlsGroup = null;
        state.filterAtsInstallation = null;
        state.filterCountry = null;
        state.filterManufacturer = null;
        state.filterLsiConcept = null;
        state.filterIrBeaconType = null;
    },
    clear(state) {
        state.choices = choices;
        state.items = new Map();
        state.page = 1;
        state.limit = LIST_ITEMS_PER_PAGE_DEFAULT;
        state.totalItems = 0;
        state.sortBy = null;
        state.sortDesc = null;
        state.filterSearch = null;
        state.filterSearchOptLwc = null;
        state.filterPos = null;
        state.filterDist = null;
        state.filterProject = null;
        state.filterBusinessEntity = null;
        state.filterWindPark = null;
        state.filterAdlsGroup = null;
        state.filterAtsInstallation = null;
        state.filterCountry = null;
        state.filterManufacturer = null;
        state.filterLsiConcept = null;
        state.filterIrBeaconType = null;
    },
    setChoices(state, value) {
        checkTypes.assert.object(value);
        state.choices = value;
    },
    updateChoices(state, value) {
        checkTypes.assert.object(value);
        state.choices = { ...state.choices, ...value };
    },
    setPage(state, value) {
        state.page = valueTransform.toInteger(value, { nullable: false });
    },
    setLimit(state, value) {
        state.limit = valueTransform.toInteger(value, { nullable: false });
    },
    setSortBy(state, value) {
        state.sortBy = valueTransform.toString(value);
    },
    setSortDesc(state, value) {
        state.sortDesc = valueTransform.toBoolean(value, { nullable: true });
    },
    setFilterSearch(state, value) {
        state.filterSearch = valueTransform.toString(value);
    },
    setFilterSearchOptLwc(state, value) {
        state.filterSearchOptLwc = valueTransform.toBoolean(value, { nullable: true });
    },
    setFilterPos(state, value) {
        value = value == null ? null : value.replace(/\s+/, '');
        // remove all whitespaces to allow whitespaces between comma separated coordinate string
        state.filterPos = value;
    },
    setFilterDist(state, value) {
        state.filterDist = valueTransform.toString(value);
    },
    setFilterProject(state, value) {
        state.filterProject = valueTransform.toString(value);
    },
    setFilterBusinessEntity(state, value) {
        state.filterBusinessEntity = valueTransform.toString(value);
    },
    setFilterWindPark(state, value) {
        state.filterWindPark = valueTransform.toString(value);
    },
    setFilterAdlsGroup(state, value) {
        state.filterAdlsGroup = valueTransform.toString(value);
    },
    setFilterAtsInstallation(state, value) {
        state.filterAtsInstallation = valueTransform.toString(value);
    },
    setFilterCountry(state, value) {
        if (value != null) checkTypes.assert.array.of.nonEmptyString(value);
        state.filterCountry = value;
    },
    setFilterManufacturer(state, value) {
        state.filterManufacturer = valueTransform.toString(value);
    },
    setFilterLsiConcept(state, value) {
        state.filterLsiConcept = valueTransform.toString(value);
    },
    setFilterIrBeaconType(state, value) {
        state.filterIrBeaconType = valueTransform.toString(value);
    },
    setTotalItems(state, value) {
        state.totalItems = valueTransform.toInteger(value, { nullable: false });
    },
    setItems(state, value) {
        value = value || [];
        state.items = new Map();
        value.forEach(function (item) {
            state.items.set(item._id, item);
        });
        // IMPORTANT change state by setting new Map since simply calling "set" directly won't propagate state change
        state.items = new Map(state.items.entries());
    },
    removeItem(state, value) {
        checkTypes.assert.nonEmptyString(value);
        state.items.delete(value);
        // IMPORTANT change state by setting new Map since simply calling "set" directly won't propagate state change
        state.items = new Map(state.items.entries());
    },
};

export const actions = {
    async load({ commit, state }) {
        // append query parameters defined by selected filter values
        const { items, totalCount } = await this.$axios.$get('/api/operating/wind-turbines', {
            params: {
                page: state.page,
                limit: state.limit,
                sortBy: state.sortBy,
                sortDesc: state.sortDesc,
                search: state.filterSearch,
                searchOptLwc: state.filterSearchOptLwc,
                pos: state.filterPos,
                dist: state.filterDist,
                businessEntity: state.filterBusinessEntity,
                project: state.filterProject,
                windPark: state.filterWindPark,
                adlsGroup: state.filterAdlsGroup,
                atsInstallation: state.filterAtsInstallation,
                country: state.filterCountry,
                manufacturer: state.filterManufacturer,
                lsiConcept: state.filterLsiConcept,
                irBeaconType: state.filterIrBeaconType,
                fields: [
                    '_id',
                    'country',
                    'readOnly',
                    'manufacturer',
                    'serialNumber',
                    'type',
                    'operatorIdent',
                    'project._id',
                    'project.projectId',
                    'windPark._id',
                    'windPark.name',
                    'lightingSystemInterfaceConcept',
                    'irBeaconType',
                    'adlsGroups._id',
                    'adlsGroups.name',
                    'ctrlAtsInstallation._id',
                    'ctrlAtsInstallation.name',
                    'sensorAtsInstallation._id',
                    'sensorAtsInstallation.name',
                    'configuration',
                    'extendedConfig',
                    'responsibilities.responsibility',
                    'responsibilities.businessEntity.name',
                ].join(','),
                populateRefs: true,
            },
        });

        commit('setTotalItems', totalCount);
        commit('setItems', items);
    },
    async delete({ state }, id) {
        await this.$axios.$delete(`/api/operating/wind-turbines/${id}`);
    },
    async lock({ state }, ids) {
        return this.$axios.$post('/api/operating/wind-turbines/lock', ids);
    },
    async unlock({ state }, ids) {
        return this.$axios.$post('/api/operating/wind-turbines/unlock', ids);
    },
};
