import { State } from 'country-state-city';

/**
 * @param {string|null} [countryIsoCode]
 * @return {*[]}
 */
export function getFormChoices(countryIsoCode) {
    const choices = [];

    if (countryIsoCode) {
        State.getStatesOfCountry(countryIsoCode).forEach((state) => {
            const { name, isoCode } = state;
            choices.push({ text: `${name} (${isoCode})`, value: isoCode });
        });
    } else {
        State.getAllStates().forEach((state) => {
            const { name, isoCode } = state;
            choices.push({ text: `${name} (${isoCode})`, value: isoCode });
        });
    }

    return choices;
}

/**
 * @param {string} alpha2Code
 * @returns {string}
 */
export function getNameByAlpha2(alpha2Code) {
    return State.getStateByCode(alpha2Code)?.name;
}
