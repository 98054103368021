import Merkator from 'openaip-merkator';
import checkTypes from 'check-types';
import cleanDeep from 'clean-deep';
import * as valueTransform from '@/helpers/value-transform';
import { ManufacturerEnum } from '@/helpers/choices/wind-turbine/manufacturer-enum';
import { getFormChoices as countryChoices } from '@/helpers/choices/countries';

const choices = {
    manufacturer: ManufacturerEnum.getFormChoices(),
    country: countryChoices(),
};

export const state = () => ({
    choices,
    id: null,
    importId: null,
    offerId: null,
    projectId: null,
    serialNumber: null,
    manufacturer: null,
    type: null,
    atsInstallation: null,
    trafficReceiver: false,
    operatorIdent: null,
    commissioning: null,
    windPark: null,
    country: null,
    actvtnSpaceRadius: null,
    actvtnSpaceHeightAboveObstacle: null,
    geometry: null,
    coordinate: null,
    hubHeight: null,
    rotorLength: null,
    elevationMsl: null,
    elevationHae: null,
    operator: null,
    intersectsNlfs: false,
    remarks: null,
});

export const mutations = {
    clear(state) {
        state.choices = choices;
        state.id = null;
        state.projectId = null;
        state.serialNumber = null;
        state.manufacturer = null;
        state.type = null;
        state.atsInstallation = null;
        state.trafficReceiver = false;
        state.commissioning = null;
        state.operatorIdent = null;
        state.windPark = null;
        state.country = null;
        state.actvtnSpaceRadius = null;
        state.actvtnSpaceHeightAboveObstacle = null;
        state.geometry = null;
        // additional property that is required in view
        state.coordinate = null;
        state.hubHeight = null;
        state.rotorLength = null;
        state.elevationMsl = null;
        state.elevationHae = null;
        state.operator = null;
        state.intersectsNlfs = false;
        state.remarks = null;
    },
    setChoices(state, value) {
        checkTypes.assert.object(value);
        state.choices = value;
    },
    updateChoices(state, value) {
        checkTypes.assert.object(value);
        state.choices = { ...state.choices, ...value };
    },
    setId(state, value) {
        state.id = valueTransform.toString(value, { nullable: false });
    },
    setImportId(state, value) {
        state.importId = valueTransform.toString(value);
    },
    setOfferId(state, value) {
        state.offerId = valueTransform.toString(value);
    },
    setProjectId(state, value) {
        state.projectId = valueTransform.toString(value);
    },
    setSerialNumber(state, value) {
        state.serialNumber = valueTransform.toString(value);
    },
    setTrafficReceiver(state, value) {
        state.trafficReceiver = valueTransform.toBoolean(value);
    },
    setManufacturer(state, value) {
        state.manufacturer = valueTransform.toInteger(value);
    },
    setType(state, value) {
        state.type = valueTransform.toString(value);
    },
    setAtsInstallation(state, value) {
        state.atsInstallation = valueTransform.toString(value);
    },
    setOperatorIdent(state, value) {
        state.operatorIdent = valueTransform.toString(value);
    },
    setCommissioning(state, value) {
        state.commissioning = valueTransform.toInteger(value);
    },
    setWindPark(state, value) {
        state.windPark = valueTransform.toString(value);
    },
    setCountry(state, value) {
        state.country = valueTransform.toString(value);
    },
    setActvtnSpaceRadius(state, value) {
        state.actvtnSpaceRadius = valueTransform.toInteger(value);
    },
    setActvtnSpaceHeightAboveObstacle(state, value) {
        state.actvtnSpaceHeightAboveObstacle = valueTransform.toInteger(value);
    },
    setGeometry(state, value) {
        if (value != null) checkTypes.assert.nonEmptyObject(value);
        state.geometry = value;
    },
    setElevationMsl(state, value) {
        state.elevationMsl = valueTransform.toInteger(value);
    },
    setElevationHae(state, value) {
        state.elevationHae = valueTransform.toInteger(value);
    },
    setCoordinate(state, value) {
        state.coordinate = valueTransform.toString(value);
    },
    setHubHeight(state, value) {
        state.hubHeight = valueTransform.toInteger(value);
    },
    setRotorLength(state, value) {
        state.rotorLength = valueTransform.toInteger(value);
    },
    setOperator(state, value) {
        state.operator = valueTransform.toString(value);
    },
    setIntersectsNlfs(state, value) {
        state.intersectsNlfs = valueTransform.toBoolean(value);
    },
    setRemarks(state, value) {
        state.remarks = valueTransform.toString(value);
    },
};

export const actions = {
    async load({ commit }, id) {
        checkTypes.assert.nonEmptyString(id);

        const {
            _id,
            projectId,
            importId,
            offerId,
            manufacturer,
            serialNumber,
            type,
            atsInstallation,
            trafficReceiver,
            commissioning,
            operatorIdent,
            windPark,
            country,
            activationSpace: { radius: actvtnSpaceRadius, heightAboveObstacle: actvtnSpaceHeightAboveObstacle },
            geometry,
            elevation: { msl, hae },
            hubHeight,
            rotorLength,
            operator,
            intersectsNlfs,
            remarks,
        } = await this.$axios.$get(`/api/operating/drafts/wind-turbines/${id}`);

        commit('setId', _id);
        commit('setImportId', importId);
        commit('setOfferId', offerId);
        commit('setProjectId', projectId);
        commit('setManufacturer', manufacturer);
        commit('setSerialNumber', serialNumber);
        commit('setTrafficReceiver', trafficReceiver);
        commit('setManufacturer', manufacturer);
        commit('setType', type);
        commit('setAtsInstallation', atsInstallation);
        commit('setWindPark', windPark);
        commit('setOperatorIdent', operatorIdent);
        commit('setCommissioning', commissioning);
        commit('setCountry', country);
        commit('setActvtnSpaceRadius', actvtnSpaceRadius);
        commit('setActvtnSpaceHeightAboveObstacle', actvtnSpaceHeightAboveObstacle);
        // location - set store values
        commit('setGeometry', geometry);
        commit('setElevationMsl', msl);
        commit('setElevationHae', hae);
        // calculate coordinates
        // coordinates is only required in views and is not
        // related to the incoming model but must be set during normalization
        let merkator = new Merkator();
        merkator.readCoord(geometry.coordinates[0], geometry.coordinates[1]);
        commit('setCoordinate', merkator.toDecimal());
        commit('setHubHeight', hubHeight);
        commit('setRotorLength', rotorLength);
        commit('setIntersectsNlfs', intersectsNlfs);
        commit('setOperator', operator);
        commit('setRemarks', remarks);
    },
    async new({ getters, state }) {
        const denormalized = _denormalize({ state, getters });

        return this.$axios.$post('/api/operating/drafts/wind-turbines', cleanDeep(denormalized));
    },
    async edit({ getters, state }) {
        return this.$axios.$patch(`/api/operating/drafts/wind-turbines/${state.id}`, _denormalize({ state, getters }));
    },
};

function _denormalize({ state }) {
    return {
        importId: state.importId,
        projectId: state.projectId,
        offerId: state.offerId,
        manufacturer: state.manufacturer,
        serialNumber: state.serialNumber,
        type: state.type,
        atsInstallation: state.atsInstallation,
        trafficReceiver: state.trafficReceiver,
        operatorIdent: state.operatorIdent,
        commissioning: state.commissioning,
        windPark: state.windPark,
        country: state.country,
        activationSpace: {
            radius: state.actvtnSpaceRadius,
            heightAboveObstacle: state.actvtnSpaceHeightAboveObstacle,
        },
        geometry: {
            type: state.geometry.type,
            coordinates: state.geometry.coordinates,
        },
        elevation: { msl: state.elevationMsl, hae: state.elevationHae },
        hubHeight: state.hubHeight,
        rotorLength: state.rotorLength,
        operator: state.operator,
        intersectsNlfs: state.intersectsNlfs,
        remarks: state.remarks,
    };
}
