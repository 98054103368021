import checkTypes from 'check-types';
import { point as createPoint, featureCollection as createFeatureCollection } from '@turf/turf';

export const state = () => ({
    project: null,
    windTurbines: [],
    atsInstallations: [],
});

export const getters = {
    getFeatures: (state) => {
        const features = [];
        // wind-turbines layer is used from base layer - on custom ATS installation features are required
        state.atsInstallations.forEach((value) => {
            const { _id, ats } = value;
            const { serialNumber, kind } = ats || {};

            features.push(
                createPoint(value.geometry.coordinates, {
                    id: _id,
                    kind,
                    type: 'atsInstallation',
                    atsSerialNumber: serialNumber,
                })
            );
        });

        return createFeatureCollection(features);
    },
};

export const mutations = {
    clear(state) {
        state.project = null;
        state.windTurbines = [];
        state.atsInstallations = [];
    },
    setProject(state, value) {
        if (value) checkTypes.assert.nonEmptyObject(value);
        state.project = value;
    },
    setWindTurbines(state, value) {
        checkTypes.assert.array.of.nonEmptyObject(value);
        state.windTurbines = value;
    },
    setAtsInstallations(state, value) {
        checkTypes.assert.array.of.nonEmptyObject(value);
        state.atsInstallations = value;
    },
};

export const actions = {
    async load({ commit }, id) {
        checkTypes.assert.nonEmptyString(id);

        const project = await this.$axios.$get(`/api/operating/projects/${id}`);
        // load linked users
        const { items: projectWindTurbines } = await this.$axios.$get('/api/operating/wind-turbines', {
            params: {
                project: id,
                fields: [
                    '_id',
                    'serialNumber',
                    'manufacturer',
                    'operatorIdent',
                    'windPark',
                    'type',
                    'geometry',
                    'lightingSystemInterfaceConcept',
                    'irBeaconType',
                    'ctrlAtsInstallation._id',
                    'ctrlAtsInstallation.name',
                    'ctrlAtsInstallation.geometry',
                    'ctrlAtsInstallation.operationMode',
                    'ctrlAtsInstallation.trafficReceiver',
                    'ctrlAtsInstallation.atsInstalled',
                    'ctrlAtsInstallation.availableForSbv',
                    'ctrlAtsInstallation.ats._id',
                    'ctrlAtsInstallation.ats.kind',
                    'ctrlAtsInstallation.ats.serialNumber',
                    'sensorAtsInstallation._id',
                    'sensorAtsInstallation.name',
                    'sensorAtsInstallation.geometry',
                    'sensorAtsInstallation.operationMode',
                    'sensorAtsInstallation.trafficReceiver',
                    'sensorAtsInstallation.atsInstalled',
                    'sensorAtsInstallation.availableForSbv',
                    'sensorAtsInstallation.ats._id',
                    'sensorAtsInstallation.ats.kind',
                    'sensorAtsInstallation.ats.serialNumber',
                ].join(','),
                populateRefs: true,
            },
        });

        // extract ats installations from wind turbines
        const projectAtsInstallations = new Map();

        projectWindTurbines.forEach((value) => {
            const { ctrlAtsInstallation, sensorAtsInstallation } = value;
            if (ctrlAtsInstallation) {
                projectAtsInstallations.set(ctrlAtsInstallation._id, ctrlAtsInstallation);
            }
            if (sensorAtsInstallation && sensorAtsInstallation?.length > 0) {
                for (const sensorAtsInst of sensorAtsInstallation) {
                    projectAtsInstallations.set(sensorAtsInst._id, sensorAtsInst);
                }
            }
        });

        commit('setProject', project);
        commit('setWindTurbines', projectWindTurbines);
        commit('setAtsInstallations', Array.from(projectAtsInstallations.values()));
    },
    async delete({ state }, id) {
        await this.$axios.$delete(`/api/operating/projects/${id}`);
    },
};
