// ##################
// general
// ##################

export const APP_NAME = 'Operating';
export const APP_CONTEXT = 'bnk-operating';
export const PERSIST_KEY = '@bnkportal';
export const PAGE_TITLE = 'BNK Portal';
export const REQUEST_DEFAULT_TIMEOUT = process.env.NODE_ENV === 'development' ? 300 * 1000 : 30 * 1000;
export const REQUEST_POLLING_TIMEOUT = '3000';
// set a high timeout for elevation API requests
export const REQUEST_ELEVATION_TIMEOUT = '120000';
export const DEBOUNCE_TIMEOUT = 800;
export const LIST_ITEMS_PER_PAGE_DEFAULT = 50;
export const LIST_ITEMS_PER_PAGE = [50, 100, 200, 500];
export const NOT_AVAILABLE = 'N/A';
export const NO_VALUE_PLACEHOLDER = 'NIL';
export const MONTHS = [];
export const DAYS_PER_MONTH = 30.4167;
export const UNLIMITED_ITEMS = 9999999;
export const DATE_FORMAT = 'yyyy-MM-dd';
export const GENERIC_PROVIDER_PLACEHOLDER = 'Generic Provider';
export const QUERY_PARAM_ORIGIN_URL = 'originUrl';
export const TTW_BACKEND_OPS = 1500;
export const ATS_STATUS_MAX_STATS_AGE_MS = 15 * 1000;
export const CERTS_SCADA_COMM_CA_CERT_FILE_NAME = 'EC2-CommCA-1.cer';
export const CERTS_SCADA_SCADA_CA_CERT_FILE_NAME = 'EC2-SCADACA-1.cer';
export const CURRENT_BASELINE_VERSION = '4';

// ##################
// messages / hints
// ##################

export const MESSAGE_INVALID_PAYLOAD = 'Application cannot process sent payload';
export const MESSAGE_ERROR_APPLICATION = 'Application Error';
export const MESSAGE_ERROR_DATA_DELETE = 'Deleting data from backend failed';
export const MESSAGE_ERROR_DATA_LOAD = 'Loading data from backend failed';
export const MESSAGE_ERROR_SERVICE_UNAVAILABLE = 'Service unavailable';
export const MESSAGE_WARNING_ITEM_REMOVAL = 'This item will be removed from the database.';
export const MESSAGE_CRUD_CREATE_SUCCESS = 'Item created successfully';
export const MESSAGE_CRUD_EDIT_SUCCESS = 'Item updated successfully';
export const MESSAGE_CRUD_DELETE_SUCCESS = 'Item deleted successfully';
export const MESSAGE_ITEM_ADD_ERROR = 'Adding item failed';
export const MESSAGE_ITEM_ADD_SUCCESS = 'Item added successfully';
export const MESSAGE_ITEM_REMOVE_ERROR = 'Removing item failed';
export const MESSAGE_ITEM_REMOVE_SUCCESS = 'Item removed successfully';
export const MESSAGE_UTC_NOTIFY = 'All dates and times are in UTC';
export const MESSAGE_CLIPBOARD_CONTENT = 'Content copied to clipboard';
export const MESSAGE_FORM_INPUT_INVALID = 'Form input required or missing. Please review form fields.';
export const HINT_FIELD_NOT_EDITABLE = 'Field cannot be changed anymore once set. Make sure this field is correct!';

// ##################
// maps
// ##################

export const MAP_CENTER = [8.657871430057156, 49.29286733869293];
export const MAP_MIN_ZOOM = 7;
export const MAP_MAX_ZOOM = 18;
export const MAP_INITIAL_NEW_ZOOM = 7;
export const MAP_INITIAL_EDIT_ZOOM = 12;
export const MAP_INITIAL_VIEW_ZOOM = 14;

// ##################
// regex
// ##################

export const REGEX_ATS_INSTALLATION_NAME = /^[A-Z0-9_]+-[A-Z0-9]+-[345]-[A-Z0-9-_]+$/;
export const REGEX_ATS3_SERIAL_NUMBER = /^ATS[3]-[0-9]{5}$/;
export const REGEX_ATS3_PART_NUMBER = /^ATS-[3]-\([0-9A-Z]{5}\)$/;
export const REGEX_ATS4_SERIAL_NUMBER = /^ATS[4]-[0-9]{5}$/;
export const REGEX_ATS4_PART_NUMBER = /^ATS-[4]-\([0-9A-Z]{5}\)$/;
export const REGEX_ATM1_SERIAL_NUMBER = /^ATM1-[0-9]{5}$/;
export const REGEX_ATM1_PART_NUMBER = /^ATM-1-\([0-9A-Z]{5}\)$/;
export const REGEX_ATM2_SERIAL_NUMBER = /^ATM[23]-[0-9]{5}$/;
export const REGEX_ATM2_PART_NUMBER = /^ATM-[23]-\([0-9A-Z]{5}\)$/;
export const REGEX_ATM4_SERIAL_NUMBER = /^ATM4-[0-9]{5}$/;
export const REGEX_ATM4_PART_NUMBER = /^ATM-4-\([0-9A-Z]{4}\)$/;
export const REGEX_ATM5_SERIAL_NUMBER = /ATM5-[0-9]{5}$/;
export const REGEX_ATM5_PART_NUMBER = /^ATM-5-\([0-9A-Z]{2}\)$/;
export const REGEX_ATM6_SERIAL_NUMBER = /^ATM6-[0-9]{5}$/;
export const REGEX_ATM6_PART_NUMBER = /^ATM-6-\([0-9A-Z]{4}\)$/;
export const REGEX_ORANGEPI_CPU_ID = '^[0-9a-fA-F]{16}$';
export const REGEX_PROJECT_ID = /^[A-Z0-9_-]+$/;
