/**
 * @param {string} email
 * @returns {{value: string|null, [name]: string|null, [prefix]: string|null}}
 */
export default function splitServiceEmail(email) {
    if (email == null) return { value: null };

    const parts = /^(.*)@services\.([a-z0-9-]+)?\.?api.lanthan-safe-sky.com$/.exec(email);
    if (parts == null) return { value: null };

    return { value: parts[0], name: parts[1], prefix: parts[2] };
}
