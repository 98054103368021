import { permissions, roles } from '@/rbac';
import AuthUser from '@/helpers/auth-user';

export default {
    computed: {
        authUser() {
            return AuthUser.loadFromStorage();
        },
        roles() {
            return roles;
        },
        permissions() {
            return permissions;
        },
    },
};
