import cleanDeep from 'clean-deep';
import checkTypes from 'check-types';
import { isEmpty } from 'lodash';
import * as valueTransform from '@/helpers/value-transform';

export const state = () => ({
    id: null,
    extendedConfig: null,
});

export const mutations = {
    clear(state) {
        state.id = null;
        state.extendedConfig = null;
    },
    setId(state, value) {
        state.id = valueTransform.toString(value, { nullable: false });
    },
    setExtendedConfig(state, value) {
        value = value == null || value?.length === 0 ? null : value;
        state.extendedConfig = value;
    },
};

export const actions = {
    async load({ commit }, id) {
        checkTypes.assert.nonEmptyString(id);

        const { _id, extendedConfig } = await this.$axios.$get(`/api/operating/wind-turbines/${id}`);

        commit('setId', _id);
        if (extendedConfig) {
            commit('setExtendedConfig', extendedConfig == null ? extendedConfig : JSON.stringify(extendedConfig, null, 4));
        }
    },
    async edit({ state }) {
        await this.$axios.$patch(`/api/operating/wind-turbines/${state.id}`, _denormalize({ state }));
    },
    async updateMany({ state }, { windTurbineIds }) {
        for (const wtId of windTurbineIds) {
            await this.$axios.$patch(`/api/operating/wind-turbines/${wtId}`, _denormalize({ state }));
        }
    },
};

function _denormalize({ state }) {
    checkTypes.assert.object(state);

    let denormalized = {
        extendedConfig: state.extendedConfig == null ? state.extendedConfig : JSON.parse(state.extendedConfig),
    };

    // Extended Configuration

    if (isEmpty(cleanDeep(denormalized.extendedConfig))) {
        denormalized.extendedConfig = null;
    }

    // at last, check if we have a completely empty object
    if (isEmpty(cleanDeep(denormalized))) return null;

    return denormalized;
}
