import { PriorityEnum } from '@/helpers/choices/priority-enum';
import * as valueTransform from '@/helpers/value-transform';
import checkTypes from 'check-types';
import defaultViewshedLayers from '@/helpers/default-viewshed-layers';

const MIN_ELEVATION_DEFAULT = 0;
const MAX_ELEVATION_DEFAULT = 200;
const STEP = 5;

/**
 * @param {Object} [config]
 * @param {number} [config.minElevation]
 * @param {number} [config.maxElevation]
 * @param {number} [config.step]
 * @return {*[]}
 */
const createLayerChoices = function (config) {
    const defaultConfig = { minElevation: MIN_ELEVATION_DEFAULT, maxElevation: MAX_ELEVATION_DEFAULT, step: STEP };
    const { minElevation, maxElevation, step } = { ...defaultConfig, ...(config || {}) };

    const choices = [];

    for (let layer = minElevation; layer <= maxElevation; layer += step) {
        choices.push({ text: layer, value: layer });
    }

    return choices;
};

const choices = {
    priority: PriorityEnum.getFormChoices(),
    layers: createLayerChoices(),
};

export const state = () => ({
    choices,
    name: null,
    layers: defaultViewshedLayers,
    priority: PriorityEnum.values.NORMAL,
});

export const mutations = {
    clear(state) {
        state.choices = choices;
        state.name = null;
        state.layers = defaultViewshedLayers;
        state.priority = 'High';
    },
    setChoices(state, value) {
        checkTypes.assert.object(value);
        state.choices = value;
    },
    updateChoices(state, value) {
        checkTypes.assert.object(value);
        state.choices = { ...state.choices, ...value };
    },
    setName(state, value) {
        state.name = valueTransform.toString(value);
    },
    setLayers(state, value) {
        state.layers = valueTransform.toIntegerList(value);
    },
    setPriority(state, value) {
        state.priority = valueTransform.toString(value);
    },
};

export const actions = {
    async new({ state }, projectId) {
        return this.$axios.$post(`/api/operating/viewsheds`, {
            project: projectId,
            name: state.name,
            priority: state.priority,
        });
    },
};
