import { Enum } from '@/helpers/choices/enum';

export class TypeEnum extends Enum {}

TypeEnum.values = {
    ATM1: 'atm1',
    ATM2: 'atm2',
    ATM4: 'atm4',
    ATM5: 'atm5',
    ATM6: 'atm6',
};

TypeEnum.names = {
    atm1: 'ATM-1',
    atm2: 'ATM-2',
    atm4: 'ATM-4',
    atm5: 'ATM-5',
    atm6: 'ATM-6',
};

Object.freeze(TypeEnum);
