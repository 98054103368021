export default class MapSelection {
    constructor() {}

    /**
     * Updates a selected feature with the properties of the input feature if both feature IDs match.
     *
     * @param {Object} pointFeature
     * @returns {this}
     */
    // TODO must be done on server side now
    updateSelectedFeature(pointFeature) {
        if (!pointFeature?.id && !pointFeature?.geometry && !pointFeature?.type && !pointFeature?.properties) {
            return this;
        }

        for (const [index, feature] of this._selectedFeatures.entries()) {
            if (feature.properties._id === pointFeature.properties._id) {
                this._selectedFeatures[index].properties = pointFeature.properties;
            }
        }

        return this;
    }

    /**
     * @param {Point} pointFeature
     * @returns {boolean}
     */
    hasFeature(pointFeature) {
        if (!pointFeature?.id && !pointFeature?.geometry && !pointFeature?.type && !pointFeature?.properties) {
            return false;
        }

        for (const feature of this._selectedFeatures) {
            if (feature.properties._id === pointFeature.properties._id) return true;
        }

        return false;
    }

    /**
     * @returns {string}
     */
    getId() {
        return this._id;
    }

    /**
     * @returns {[{}]}
     */
    getSelectedFeatures() {
        return this._selectedFeatures || [];
    }
}
