import * as valueTransform from '@/helpers/value-transform';
import checkTypes from 'check-types';

export const state = () => ({
    /*
    Since this component is no dialog, there is no property that determines the open/close state of the component. This also results
    in no prop that a watcher can monitor and if prop changes, react on this change, i.e. load data if dialog "opens" and prop changes
    to "true".

    To have the component load data on initial opening, an internal property "initLoadData" has to be created and set to "false".
    This property is changed within the "created" hook to "true". This will only happen once on initial component showing up.

    Another watcher monitors subsequent changes to the propId and loads data accordingly.
    */
    initLoadData: false,
    initialized: false,
    selectionFeature: null,
    selectedFeatures: [],
    mobileCoverageCache: new Map(),
    currentMode: null,
    // module place traffic receiver
    mptrId: 'place-traffic-receiver',
    mptrTrafficReceiverSourceId: null,
    mptrPlaceTrafficReceiver: false,
    // feature data sources
    sourceDataSelectedFeatures: [],
    sourceDataAtsCoverage: [],
    sourceDataAtsCoverageArea: [],
    sourceDataAtsUncoveredArea: [],
    sourceDataActivationSpace: [],
    sourceDataListHover: [],
});

export const getters = {
    getMobileCoverageCache: (state) => {
        return new Map(Array.from(state.mobileCoverageCache.values()));
    },
};

export const mutations = {
    clear(state) {
        state.initLoadData = false;
        state.initialized = false;
        state.selectionFeature = null;
        state.selectedFeatures = [];
        state.mobileCoverageCache = new Map();
        state.currentMode = null;
        // module place traffic receiver
        state.mptrTrafficReceiverSourceId = null;
        state.mptrPlaceTrafficReceiver = false;
        // feature data sources
        state.sourceDataSelectedFeatures = [];
        state.sourceDataAtsCoverage = [];
        state.sourceDataAtsCoverageArea = [];
        state.sourceDataAtsUncoveredArea = [];
        state.sourceDataActivationSpace = [];
        state.sourceDataListHover = [];
    },
    setInitLoadData(state, value) {
        state.initLoadData = valueTransform.toBoolean(value);
    },
    setInitialized(state, value) {
        state.initialized = valueTransform.toBoolean(value);
    },
    setSelectionFeature(state, value) {
        if (value != null) checkTypes.assert.nonEmptyObject(value);
        state.selectionFeature = value;
    },
    setSelectedFeatures(state, value) {
        checkTypes.assert.array(value);
        state.selectedFeatures = value;
    },
    setCurrentMode(state, value) {
        state.currentMode = valueTransform.toString(value);
    },
    setMptrTrafficReceiverSourceId(state, value) {
        state.mptrTrafficReceiverSourceId = valueTransform.toString(value, { nullable: true });
    },
    setMptrPlaceTrafficReceiver(state, value) {
        state.mptrPlaceTrafficReceiver = valueTransform.toBoolean(value);
    },
    setSourceDataSelectedFeatures(state, value) {
        checkTypes.assert.array(value);
        state.sourceDataSelectedFeatures = value;
    },
    setSourceDataAtsCoverage(state, value) {
        checkTypes.assert.array(value);
        state.sourceDataAtsCoverage = value;
    },
    setSourceDataAtsCoverageArea(state, value) {
        checkTypes.assert.array(value);
        state.sourceDataAtsCoverageArea = value;
    },
    setSourceDataAtsUncoveredArea(state, value) {
        checkTypes.assert.array(value);
        state.sourceDataAtsUncoveredArea = value;
    },
    setSourceDataActivationSpace(state, value) {
        checkTypes.assert.array(value);
        state.sourceDataActivationSpace = value;
    },
    setSourceDataListHover(state, value) {
        checkTypes.assert.array(value);
        state.sourceDataListHover = value;
    },
};
