import * as valueTransform from '@/helpers/value-transform';
import { featureCollection as createFeatureCollection, point as createPoint } from '@turf/turf';
import checkTypes from 'check-types';
import cleanDeep from 'clean-deep';

export const state = () => ({
    isStarted: false,
    accessToken: null,
    commissioningProcess: null,
    atsInstallation: {},
    ats: {},
    status: {},
    processor: null,
    logs: [],
    prevStep: null,
    step: 1,
    state: null,
    userGeolocation: null,
    confirmedInstallationLocationOk: false,
    confirmedAtsComponentsOk: false,
    confirmedAntennasOk: false,
    confirmedLightingSystemInterfacesOk: false,
});

export const getters = {
    getFeatures: (state) => {
        if (state?.commissioningProcess == null) return createFeatureCollection([]);

        const { atsSerialNumber, atsInstallation } = state?.commissioningProcess;
        const { _id, trafficReceiver, geometry, ats } = atsInstallation;
        const { kind } = ats;
        const features = [];
        features.push(
            createPoint(geometry.coordinates, {
                atsSerialNumber,
                id: _id,
                kind,
                type: 'atsInstallation',
                trafficReceiver,
            })
        );

        return createFeatureCollection(features);
    },
};

export const mutations = {
    clear(state) {
        state.isStarted = false;
        state.accessToken = null;
        state.commissioningProcess = null;
        state.atsInstallation = {};
        state.ats = {};
        state.status = {};
        state.processor = null;
        state.logs = [];
        state.prevStep = null;
        state.step = 1;
        state.state = null;
        state.userGeolocation = null;
        state.confirmedInstallationLocationOk = false;
        state.confirmedAtsComponentsOk = false;
        state.confirmedAntennasOk = false;
        state.confirmedLightingSystemInterfacesOk = false;
    },
    setCommissioningProcess(state, value) {
        checkTypes.assert.nonEmptyObject(value);
        state.commissioningProcess = value;
    },
    setAccessToken(state, value) {
        if (value) checkTypes.assert.nonEmptyString(value);
        state.accessToken = value;
    },
    setState(state, value) {
        state.state = valueTransform.toString(value);
    },
    setStep(state, value) {
        state.step = valueTransform.toInteger(value);
    },
    setPrevStep(state, value) {
        state.prevStep = valueTransform.toInteger(value);
    },
    setProcessor(state, value) {
        state.processor = valueTransform.toString(value);
    },
    setUserGeolocation(state, value) {
        state.userGeolocation = value;
    },
    setConfirmedInstallationLocationOk(state, value) {
        state.confirmedInstallationLocationOk = valueTransform.toBoolean(value);
    },
    setConfirmedAtsComponentsOk(state, value) {
        state.confirmedAtsComponentsOk = valueTransform.toBoolean(value);
    },
    setConfirmedAntennasOk(state, value) {
        state.confirmedAntennasOk = valueTransform.toBoolean(value);
    },
    setConfirmedLightingSystemInterfacesOk(state, value) {
        state.confirmedLightingSystemInterfacesOk = valueTransform.toBoolean(value);
    },
    setLogs(state, value) {
        if (value != null) checkTypes.assert.array.of.nonEmptyObject(value);
        state.logs = value;
    },
    updateLogs(state, value) {
        checkTypes.assert.nonEmptyObject(value);
        const logs = state.logs;
        logs.push(value);
        state.logs = logs;
    },
    setAtsInstallation(state, value) {
        checkTypes.assert.nonEmptyObject(value);
        state.atsInstallation = value;
    },
    setAts(state, value) {
        checkTypes.assert.nonEmptyObject(value);
        state.ats = value;
    },
    setStatus(state, value) {
        if (value) checkTypes.assert.object(value);
        state.status = value;
    },
};

export const actions = {
    async load({ commit, state }, id) {
        checkTypes.assert.nonEmptyString(id);
        const commissioningProcess = await this.$axios.$get(`/api/operating/commissioning-processes/${id}/signed-access/commissioning`, {
            headers: { authorization: `Bearer ${state.accessToken}` },
        });
        const { processor, logs, atsInstallation, state: processState } = commissioningProcess;
        const { ats } = atsInstallation;

        commit('setCommissioningProcess', commissioningProcess);
        commit('setState', processState);
        commit('setAts', ats);
        commit('setAtsInstallation', atsInstallation);
        commit('setProcessor', processor);
        commit('setLogs', logs || []);
    },
    async update({ state }) {
        await this.$axios.$patch(
            `/api/operating/commissioning-processes/${state.commissioningProcess._id}/signed-access/commissioning`,
            cleanDeep({
                processor: state.processor,
                logs: state.logs,
            }),
            { headers: { authorization: `Bearer ${state.accessToken}` } }
        );
    },
    async changeState({ state }, updatedState) {
        return this.$axios.$patch(
            `/api/operating/commissioning-processes/${state.commissioningProcess._id}/signed-access/commissioning/change-state`,
            { state: updatedState },
            { headers: { authorization: `Bearer ${state.accessToken}` } }
        );
    },
    async loadAtsStatus({ commit, state }) {
        if (state.ats == null) return;

        const status = await this.$axios.$get(`/api/bnk-mediator/mediate/ats/${state.ats._id}/signed-access/status`, {
            headers: { authorization: `Bearer ${state.accessToken}` },
        });

        commit('setStatus', status);
    },
};
