import { Enum } from '@/helpers/choices/enum';

export class RelayInterfaceContactEnum extends Enum {}

RelayInterfaceContactEnum.values = {
    NO: 0,
    NC: 1,
};

RelayInterfaceContactEnum.names = {
    0: 'Normally Open',
    1: 'Normally Closed',
};

Object.freeze(RelayInterfaceContactEnum);
