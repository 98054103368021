import checkTypes from 'check-types';
import cleanDeep from 'clean-deep';
import * as valueTransform from '@/helpers/value-transform';
import { getFormChoices as countryChoices } from '@/helpers/choices/countries';

const choices = {
    country: countryChoices(),
};

export const state = () => ({
    initialLoad: true,
    choices,
    id: null,
    readOnly: false,
    name: null,
    email: null,
    phone: null,
    fax: null,
    fullAddress: null,
    locality: null,
    state: null,
    country: null,
});

export const mutations = {
    clear(state) {
        state.initialLoad = true;
        state.choices = choices;
        state.id = null;
        state.readOnly = false;
        state.name = null;
        state.email = null;
        state.phone = null;
        state.fax = null;
        state.fullAddress = null;
        state.locality = null;
        state.state = null;
        state.country = null;
    },
    setChoices(state, value) {
        state.choices = value;
    },
    setInitialLoad(state, value) {
        state.initialLoad = valueTransform.toBoolean(value);
    },
    setId(state, value) {
        state.id = valueTransform.toString(value, { nullable: false });
    },
    setReadOnly(state, value) {
        state.readOnly = valueTransform.toBoolean(value);
    },
    setName(state, value) {
        state.name = valueTransform.toString(value);
    },
    setEmail(state, value) {
        state.email = valueTransform.toString(value);
    },
    setPhone(state, value) {
        state.phone = valueTransform.toString(value);
    },
    setFax(state, value) {
        state.fax = valueTransform.toString(value);
    },
    setFullAddress(state, value) {
        state.fullAddress = valueTransform.toString(value);
    },
    setLocality(state, value) {
        state.locality = valueTransform.toString(value);
    },
    setState(state, value) {
        state.state = valueTransform.toString(value);
    },
    setCountry(state, value) {
        state.country = valueTransform.toString(value);
    },
};

export const actions = {
    async load({ commit }, id) {
        checkTypes.assert.nonEmptyString(id);

        const {
            _id,
            readOnly,
            name,
            contact: {
                email,
                phone,
                fax,
                address: { fullAddress, locality, state, country },
            },
        } = await this.$axios.$get(`/api/operating/business-entities/${id}`);

        commit('setId', _id);
        commit('setReadOnly', readOnly);
        commit('setName', name);
        commit('setEmail', email);
        commit('setPhone', phone);
        commit('setFax', fax);
        commit('setFullAddress', fullAddress);
        commit('setLocality', locality);
        commit('setState', state);
        commit('setCountry', country);
    },
    async new({ state }) {
        let denormalized = _denormalize({ state });

        return this.$axios.$post('/api/operating/business-entities', cleanDeep(denormalized));
    },
    async edit({ state }) {
        return this.$axios.$patch(`/api/operating/business-entities/${state.id}`, _denormalize({ state }));
    },
    async updateAddressFields({ state, commit }) {
        // only do request if we have an address
        if (!state.fullAddress && state.fullAddress.length === 0) {
            // on error, reset previously set fields, if any
            commit('setFullAddress', null);
            commit('setLocality', null);
            commit('setState', null);
            commit('setCountry', null);

            return;
        }

        try {
            const {
                formattedAddress,
                components: { country, locality, state: compState },
            } = await this.$axios.$get('/api/operating/utils/geocode-address', {
                params: { address: encodeURIComponent(state.fullAddress) },
            });

            commit('setFullAddress', formattedAddress);
            commit('setLocality', locality.shortName);
            commit('setState', compState.longName);
            commit('setCountry', country.shortName);
        } catch (e) {
            // on error, reset previously set fields, if any
            commit('setFullAddress', null);
            commit('setLocality', null);
            commit('setState', null);
            commit('setCountry', null);

            throw new Error('Failed to geocode address');
        }
    },
};

function _denormalize({ state }) {
    checkTypes.assert.object(state);

    return {
        name: state.name,
        contact: {
            email: state.email,
            phone: state.phone,
            fax: state.fax,
            address: {
                fullAddress: state.fullAddress,
                locality: state.locality,
                state: state.state,
                country: state.country,
            },
        },
    };
}
