import cleanDeep from 'clean-deep';
import checkTypes from 'check-types';
import { isEmpty } from 'lodash';
import * as valueTransform from '@/helpers/value-transform';
import { DigitalInputOptionsEnum } from '@/helpers/choices/wind-turbine/configuration/digital-input-options-enum';
import { DigitalInputFunctionsEnum } from '@/helpers/choices/wind-turbine/configuration/digital-input-functions-enum';
import { RelayInterfaceContactEnum } from '@/helpers/choices/wind-turbine/configuration/relay-interface-contact-enum';
import { IpInterfaceTypeEnum } from '@/helpers/choices/wind-turbine/configuration/ip-interface-type-enum';

const choices = {
    digitalInputOption: DigitalInputOptionsEnum.getFormChoices(),
    digitalInputFunction: DigitalInputFunctionsEnum.getFormChoices(),
    relayInterfaceContact: RelayInterfaceContactEnum.getFormChoices(),
    ipInterfaceType: IpInterfaceTypeEnum.getFormChoices().filter((item) => {
        // remove all choices items that are of type "IpInterfaceTypeEnum.values.ATS_MODBUS_TCP_SERVER"
        return item.value != IpInterfaceTypeEnum.values.ATS_MODBUS_TCP_SERVER;
    }),
    ipInterfaceServerType: IpInterfaceTypeEnum.getFormChoices().filter((item) => {
        // remove all choices items that are not of type "IpInterfaceTypeEnum.values.ATS_MODBUS_TCP_SERVER"
        return item.value == IpInterfaceTypeEnum.values.ATS_MODBUS_TCP_SERVER;
    }),
};

export const state = () => ({
    choices: choices,
    id: null,
    manufacturer: null,
    serialNumber: null,
    operatorIdent: null,
    relayInterfaceNoError: null,
    relayInterfaceSystemOk: null,
    relayInterfaceSuppressLights: null,
    relayInterfaceCivilDay: null,
    digitalInputDin1Option: null,
    digitalInputDin1Function: null,
    digitalInputDin2Option: null,
    digitalInputDin2Function: null,
    digitalInputDin3Option: null,
    digitalInputDin3Function: null,
    digitalInputDin4Option: null,
    digitalInputDin4Function: null,
    digitalInputDin5Option: null,
    digitalInputDin5Function: null,
    digitalInputDin6Option: null,
    digitalInputDin6Function: null,
    hasIpInterfaceServer: false,
    ipInterfaceServerType: null,
    ipInterfaceServerGateway: null,
    ipInterfaceServerDestination: null,
    // property required in view to dictate showing/hiding/resetting related form fields
    hasIpInterface: false,
    ipInterfaceType: null,
    ipInterfaceGateway: null,
    ipInterfaceIpAddress: null,
    ipInterfacePort: null,
    // property required in view to dictate showing/hiding/resetting related form fields
    hasIpInterfaceAts: false,
    ipInterfaceAtsDhcp: false,
    ipInterfaceAtsIpAddress: null,
    ipInterfaceAtsPort: null,
    ipInterfaceEnerconScadaCertificate: null,
    extendedConfig: null,
});

export const getters = {};

export const mutations = {
    clear(state) {
        state.choices = choices;
        state.id = null;
        state.manufacturer = null;
        state.serialNumber = null;
        state.operatorIdent = null;
        state.relayInterfaceNoError = null;
        state.relayInterfaceSystemOk = null;
        state.relayInterfaceSuppressLights = null;
        state.relayInterfaceCivilDay = null;
        state.digitalInputDin1Option = null;
        state.digitalInputDin1Function = null;
        state.digitalInputDin2Option = null;
        state.digitalInputDin2Function = null;
        state.digitalInputDin3Option = null;
        state.digitalInputDin3Function = null;
        state.digitalInputDin4Option = null;
        state.digitalInputDin4Function = null;
        state.digitalInputDin5Option = null;
        state.digitalInputDin5Function = null;
        state.digitalInputDin6Option = null;
        state.digitalInputDin6Function = null;
        state.hasIpInterfaceServer = false;
        state.ipInterfaceServerType = null;
        state.ipInterfaceGateway = null;
        state.ipInterfaceServerDestination = null;
        state.hasIpInterface = false;
        state.ipInterfaceType = null;
        state.ipInterfaceGateway = null;
        state.ipInterfaceIpAddress = null;
        state.ipInterfacePort = null;
        state.hasIpInterfaceAts = false;
        state.ipInterfaceAtsDhcp = false;
        state.ipInterfaceAtsIpAddress = null;
        state.ipInterfaceAtsPort = null;
        state.ipInterfaceEnerconScadaCertificate = null;
        state.extendedConfig = null;
    },
    setChoices(state, value) {
        checkTypes.assert.object(value);
        state.choices = value;
    },
    updateChoices(state, value) {
        checkTypes.assert.object(value);
        state.choices = { ...state.choices, ...value };
    },
    setId(state, value) {
        state.id = valueTransform.toString(value, { nullable: false });
    },
    setManufacturer(state, value) {
        state.manufacturer = valueTransform.toInteger(value);
    },
    setSerialNumber(state, value) {
        state.serialNumber = valueTransform.toString(value);
    },
    setOperatorIdent(state, value) {
        state.operatorIdent = valueTransform.toString(value);
    },
    setRelayInterfaceNoError(state, value) {
        state.relayInterfaceNoError = valueTransform.toInteger(value);
    },
    setRelayInterfaceSystemOk(state, value) {
        state.relayInterfaceSystemOk = valueTransform.toInteger(value);
    },
    setRelayInterfaceSuppressLights(state, value) {
        state.relayInterfaceSuppressLights = valueTransform.toInteger(value);
    },
    setRelayInterfaceCivilDay(state, value) {
        state.relayInterfaceCivilDay = valueTransform.toInteger(value);
    },
    setDigitalInputDin1Option(state, value) {
        state.digitalInputDin1Option = valueTransform.toInteger(value);
        if (state.digitalInputDin1Option == null) state.digitalInputDin1Function = null;
    },
    setDigitalInputDin1Function(state, value) {
        state.digitalInputDin1Function = valueTransform.toInteger(value);
    },
    setDigitalInputDin2Option(state, value) {
        state.digitalInputDin2Option = valueTransform.toInteger(value);
        if (state.digitalInputDin2Option == null) state.digitalInputDin2Function = null;
    },
    setDigitalInputDin2Function(state, value) {
        state.digitalInputDin2Function = valueTransform.toInteger(value);
    },
    setDigitalInputDin3Option(state, value) {
        state.digitalInputDin3Option = valueTransform.toInteger(value);
        if (state.digitalInputDin3Option == null) state.digitalInputDin3Function = null;
    },
    setDigitalInputDin3Function(state, value) {
        state.digitalInputDin3Function = valueTransform.toInteger(value);
    },
    setDigitalInputDin4Option(state, value) {
        state.digitalInputDin4Option = valueTransform.toInteger(value);
        if (state.digitalInputDin4Option == null) state.digitalInputDin4Function = null;
    },
    setDigitalInputDin4Function(state, value) {
        state.digitalInputDin4Function = valueTransform.toInteger(value);
    },
    setDigitalInputDin5Option(state, value) {
        state.digitalInputDin5Option = valueTransform.toInteger(value);
        if (state.digitalInputDin5Option == null) state.digitalInputDin5Function = null;
    },
    setDigitalInputDin5Function(state, value) {
        state.digitalInputDin5Function = valueTransform.toInteger(value);
    },
    setDigitalInputDin6Option(state, value) {
        state.digitalInputDin6Option = valueTransform.toInteger(value);
        if (state.digitalInputDin6Option == null) state.digitalInputDin6Function = null;
    },
    setDigitalInputDin6Function(state, value) {
        state.digitalInputDin6Function = valueTransform.toInteger(value);
    },
    setHasIpInterfaceServer(state, value) {
        state.hasIpInterfaceServer = valueTransform.toBoolean(value);
    },
    setIpInterfaceServerType(state, value) {
        state.ipInterfaceServerType = valueTransform.toInteger(value);
    },
    setIpInterfaceServerGateway(state, value) {
        state.ipInterfaceServerGateway = valueTransform.toString(value);
    },
    setIpInterfaceServerDestination(state, value) {
        state.ipInterfaceServerDestination = valueTransform.toString(value);
    },
    setHasIpInterface(state, value) {
        state.hasIpInterface = valueTransform.toBoolean(value);
    },
    setIpInterfaceType(state, value) {
        state.ipInterfaceType = valueTransform.toInteger(value);
    },
    setIpInterfaceGateway(state, value) {
        state.ipInterfaceGateway = valueTransform.toString(value);
    },
    setIpInterfaceIpAddress(state, value) {
        state.ipInterfaceIpAddress = valueTransform.toString(value);
    },
    setIpInterfacePort(state, value) {
        state.ipInterfacePort = valueTransform.toInteger(value);
    },
    setHasIpInterfaceAts(state, value) {
        state.hasIpInterfaceAts = valueTransform.toBoolean(value);
    },
    setIpInterfaceAtsDhcp(state, value) {
        state.ipInterfaceAtsDhcp = value;
    },
    setIpInterfaceAtsIpAddress(state, value) {
        state.ipInterfaceAtsIpAddress = valueTransform.toString(value);
    },
    setIpInterfaceAtsPort(state, value) {
        state.ipInterfaceAtsPort = valueTransform.toInteger(value);
    },
    setIpInterfaceEnerconScadaCertificate(state, value) {
        state.ipInterfaceEnerconScadaCertificate = valueTransform.toString(value, { nullable: true });
    },
    setExtendedConfig(state, value) {
        value = value == null || value?.length === 0 ? null : value;
        state.extendedConfig = value;
    },
};

export const actions = {
    async load({ commit }, id) {
        checkTypes.assert.nonEmptyString(id);

        const { _id, manufacturer, serialNumber, operatorIdent, configuration } = await this.$axios.$get(`/api/operating/wind-turbines/${id}`);

        commit('setId', _id);
        commit('setManufacturer', manufacturer);
        commit('setSerialNumber', serialNumber);
        commit('setOperatorIdent', operatorIdent);

        await _normalize({ commit }, configuration, { $axios: this.$axios });
    },
    async edit({ state }) {
        await this.$axios.$patch(`/api/operating/wind-turbines/${state.id}`, {
            configuration: _denormalize({ state }),
        });
    },
    async updateMany({ state }, { windTurbineIds }) {
        const patchPayload = { configuration: _denormalize({ state }) };
        for (const wtId of windTurbineIds) {
            await this.$axios.$patch(`/api/operating/wind-turbines/${wtId}`, patchPayload);
        }
    },
};

async function _normalize({ commit }, data, { $axios }) {
    // configuration is optional and may be undefined
    if (!data) return;

    const { relayInterface, digitalInputs, ipInterfaceServer, ipInterface, ipInterfaceAts, extendedConfig } = data;

    commit('setExtendedConfig', extendedConfig == null ? extendedConfig : JSON.stringify(extendedConfig, null, 4));

    if (relayInterface) {
        const { noError, systemOk, suppressLights, civilDay } = relayInterface;

        commit('setRelayInterfaceNoError', noError);
        commit('setRelayInterfaceSystemOk', systemOk);
        commit('setRelayInterfaceSuppressLights', suppressLights);
        commit('setRelayInterfaceCivilDay', civilDay);
    }

    if (digitalInputs) {
        const { din1, din2, din3, din4, din5, din6 } = digitalInputs;

        commit('setDigitalInputDin1Option', din1 ? din1.option : null);
        commit('setDigitalInputDin1Function', din1 ? din1.function : null);
        commit('setDigitalInputDin2Option', din2 ? din2.option : null);
        commit('setDigitalInputDin2Function', din2 ? din2.function : null);
        commit('setDigitalInputDin3Option', din3 ? din3.option : null);
        commit('setDigitalInputDin3Function', din3 ? din3.function : null);
        commit('setDigitalInputDin4Option', din4 ? din4.option : null);
        commit('setDigitalInputDin4Function', din4 ? din4.function : null);
        commit('setDigitalInputDin5Option', din5 ? din5.option : null);
        commit('setDigitalInputDin5Function', din5 ? din5.function : null);
        commit('setDigitalInputDin6Option', din6 ? din6.option : null);
        commit('setDigitalInputDin6Function', din6 ? din6.function : null);
    }

    if (ipInterfaceServer) {
        const { type, gateway, destination } = ipInterfaceServer;

        commit('setHasIpInterfaceServer', true);
        commit('setIpInterfaceServerType', type);
        commit('setIpInterfaceServerGateway', gateway);
        commit('setIpInterfaceServerDestination', destination);
    }

    if (ipInterface) {
        const { type, gateway, ipAddress, port, certificates } = ipInterface;

        commit('setHasIpInterface', true);
        commit('setIpInterfaceType', type);
        commit('setIpInterfaceGateway', gateway);
        commit('setIpInterfaceIpAddress', ipAddress);
        commit('setIpInterfacePort', port);
        commit('setIpInterfaceEnerconScadaCertificate', certificates?.enercon?.scada ? certificates.enercon.scada : null);
    } else {
        commit('setHasIpInterface', false);
    }

    if (ipInterfaceAts) {
        const { dhcp, ipAddress, port } = ipInterfaceAts;

        commit('setHasIpInterfaceAts', true);
        commit('setIpInterfaceAtsDhcp', dhcp);
        commit('setIpInterfaceAtsIpAddress', ipAddress);
        commit('setIpInterfaceAtsPort', port);
    } else {
        commit('setHasIpInterfaceAts', false);
    }
}

function _denormalize({ state }) {
    checkTypes.assert.object(state);

    let denormalized = {
        relayInterface: {
            noError: state.relayInterfaceNoError,
            systemOk: state.relayInterfaceSystemOk,
            suppressLights: state.relayInterfaceSuppressLights,
            civilDay: state.relayInterfaceCivilDay,
        },
        digitalInputs: {
            din1: {
                option: state.digitalInputDin1Option,
                function: state.digitalInputDin1Function,
            },
            din2: {
                option: state.digitalInputDin2Option,
                function: state.digitalInputDin2Function,
            },
            din3: {
                option: state.digitalInputDin3Option,
                function: state.digitalInputDin3Function,
            },
            din4: {
                option: state.digitalInputDin4Option,
                function: state.digitalInputDin4Function,
            },
            din5: {
                option: state.digitalInputDin5Option,
                function: state.digitalInputDin5Function,
            },
            din6: {
                option: state.digitalInputDin6Option,
                function: state.digitalInputDin6Function,
            },
        },
        extendedConfig: state.extendedConfig == null ? state.extendedConfig : JSON.parse(state.extendedConfig),
    };

    if (state.hasIpInterfaceServer) {
        denormalized.ipInterfaceServer = {
            type: state.ipInterfaceServerType,
            gateway: state.ipInterfaceServerGateway,
            destination: state.ipInterfaceServerDestination,
        };
    } else {
        denormalized.ipInterfaceServer = null;
    }

    if (state.hasIpInterface) {
        denormalized.ipInterface = {
            type: state.ipInterfaceType,
            gateway: state.ipInterfaceGateway,
            ipAddress: state.ipInterfaceIpAddress,
            port: state.ipInterfacePort,
            certificates: {
                enercon: {
                    scada: state.ipInterfaceEnerconScadaCertificate,
                },
            },
        };
    } else {
        denormalized.ipInterface = null;
    }

    if (state.hasIpInterfaceAts) {
        denormalized.ipInterfaceAts = {
            dhcp: state.ipInterfaceAtsDhcp,
            ipAddress: state.ipInterfaceAtsIpAddress,
            port: state.ipInterfaceAtsPort,
        };
    } else {
        denormalized.ipInterfaceAts = null;
    }

    // check if we have a completely empty object
    if (isEmpty(cleanDeep(denormalized.relayInterface))) {
        // this will the field completely from the root document (configuration) on the server
        denormalized.relayInterface = null;
    }
    if (isEmpty(cleanDeep(denormalized.digitalInputs))) {
        // this will the field completely from the root document (configuration) on the server
        denormalized.digitalInputs = null;
    } else {
        let digitalInputs = denormalized.digitalInputs;
        if (isEmpty(cleanDeep(digitalInputs.din1))) digitalInputs.din1 = null;
        if (isEmpty(cleanDeep(digitalInputs.din2))) digitalInputs.din2 = null;
        if (isEmpty(cleanDeep(digitalInputs.din3))) digitalInputs.din3 = null;
        if (isEmpty(cleanDeep(digitalInputs.din4))) digitalInputs.din4 = null;
        if (isEmpty(cleanDeep(digitalInputs.din5))) digitalInputs.din5 = null;
        if (isEmpty(cleanDeep(digitalInputs.din6))) digitalInputs.din6 = null;
    }
    // check if we have an completely empty object
    if (isEmpty(cleanDeep(denormalized.ipInterface))) {
        // this will the field completely from the root document (configuration) on the server
        denormalized.ipInterface = null;
    }

    // Extended Configuration

    if (isEmpty(cleanDeep(denormalized.extendedConfig))) {
        denormalized.extendedConfig = null;
    }

    // at last, check if we have a completely empty object
    if (isEmpty(cleanDeep(denormalized))) return null;

    return denormalized;
}
