import { extend } from 'vee-validate';
import Merkator from 'openaip-merkator';
import isIP from 'validator/lib/isIP';
import isIPRange from 'validator/lib/isIPRange';
import isMACAddress from 'validator/lib/isMACAddress';
import { required, email, regex, min, max, min_value, max_value, required_if, integer, numeric, is, is_not } from 'vee-validate/dist/rules';
import { countries } from '@/helpers/choices/countries';
import {
    REGEX_ATS_INSTALLATION_NAME,
    REGEX_ATS3_PART_NUMBER,
    REGEX_ATS3_SERIAL_NUMBER,
    REGEX_ATS4_PART_NUMBER,
    REGEX_ATS4_SERIAL_NUMBER,
    REGEX_ATM4_PART_NUMBER,
    REGEX_ATM4_SERIAL_NUMBER,
    REGEX_ATM5_PART_NUMBER,
    REGEX_ATM5_SERIAL_NUMBER,
    REGEX_ATM1_PART_NUMBER,
    REGEX_ATM1_SERIAL_NUMBER,
    REGEX_ATM2_PART_NUMBER,
    REGEX_ATM2_SERIAL_NUMBER,
    REGEX_ATM6_PART_NUMBER,
    REGEX_ATM6_SERIAL_NUMBER,
    REGEX_PROJECT_ID,
    REGEX_ORANGEPI_CPU_ID,
    CERTS_SCADA_COMM_CA_CERT_FILE_NAME,
    CERTS_SCADA_SCADA_CA_CERT_FILE_NAME,
} from '@/globals';
import { DateTime } from 'luxon';
import * as IpCidr from 'ip-cidr';

// Imported rules

extend('is', is);
extend('is_not', is_not);
extend('numeric', numeric);
extend('integer', integer);
extend('email', email);
extend('min', min);
extend('max', max);
extend('min_value', min_value);
extend('max_value', max_value);
extend('required_if', required_if);
extend('required', {
    ...required,
    message: 'This field is required',
});
extend('regex', regex);
extend('regexPassword', {
    ...regex,
    message: 'Password is required and must be more than 20 characters long and contain at least an upper case, a' + ' lower case character and a number',
});
extend('password', {
    params: ['target'],
    validate(value, { target }) {
        return value === target;
    },
    message: 'Password confirmation does not match',
});
extend('positive', {
    validate: (value) => {
        return value >= 0;
    },
    message: 'Field must be a positive number',
});
// add globally used validation rules here => specific rules can be added in each component separately
extend('coordinate', {
    validate: (value) => {
        try {
            let merkator = new Merkator();
            merkator.readString(value);

            return true;
        } catch (e) {
            return false;
        }
    },
    message: 'Field must be a valid coordinate string.',
});
extend('future_date', {
    validate(value) {
        const inputDatetime = DateTime.utc(value);
        const now = DateTime.utc();

        return inputDatetime > now;
    },
    message: 'Date must be greater than now.',
});
extend('country', {
    validate(value) {
        return countries.isValid(value);
    },
    message: '{_value_} is not a valid ISO 3166-1 Alpha-2 country code.',
});
extend('ip_or_cidr_address', {
    validate(value) {
        // must match either IPv4 or IPv6 or IPv4 with range
        if (isIP(value.toString())) return true;

        return isIPRange(value.toString());
    },
    message: '{_value_} is not a valid IPv4/IPv6 IP address or CIDR.',
});
extend('ip_address', {
    validate(value) {
        return isIP(value.toString());
    },
    message: '{_value_} is not a valid IPv4/IPv6 IP address.',
});
extend('network_destination', {
    validate(value) {
        if (value === 'default') return true;
        // must match either IPv4 or IPv6 or IPv4 with range
        if (isIP(value.toString())) return true;

        return isIPRange(value.toString());
    },
    message: '{_value_} is not a valid network destination address.',
});
extend('ip_cidr', {
    validate(value) {
        return isIPRange(value.toString());
    },
    message: '{_value_} is not a valid IPv4/IPv6 CIDR.',
});
extend('ip_in_range', {
    params: ['cidr', 'gateway'],
    validate(value, { cidr, gateway }) {
        // an existing gateway is always valid
        if (value == null || value === '' || gateway != null || IpCidr.isValidCIDR(cidr) === false) return true;

        const range = new IpCidr(cidr);

        return range.contains(value);
    },
    message: '{_value_} is not in range of specified CIDR {_cidr_}. If possible, define a gateway IP address in this range to keep this value.',
});
extend('is_not_same_ip', {
    params: ['otherIp'],
    validate(value, { otherIp }) {
        if (value == null || otherIp == null) return true;
        // convert both values into IP addresses if they are CIDRs
        const ip1 = isIPRange(value.toString()) ? value.split('/')[0] : value;
        const ip2 = isIPRange(otherIp.toString()) ? otherIp.split('/')[0] : otherIp;

        return ip1 !== ip2;
    },
    message: 'Duplicate values detected. {_value_} must not be the same as {_otherIp_}.',
});
extend('no_duplicates', {
    params: ['list'],
    validate(value, { list }) {
        return list.filter((item) => item === value).length <= 1;
    },
    message: 'No duplicate values allowed.',
});
extend('mac_address', {
    validate(value) {
        return isMACAddress(value.toString());
    },
    message: '{_value_} is not a valid MAC address.',
});
extend('ats_installation_name', {
    validate(value) {
        const regexIp = new RegExp(REGEX_ATS_INSTALLATION_NAME);

        return regexIp.test(value);
    },
    message: '{_value_} is not a valid ATS installation name.',
});
extend('ats3_serial_number', {
    validate(value) {
        const regexIp = new RegExp(REGEX_ATS3_SERIAL_NUMBER);

        return regexIp.test(value);
    },
    message: '{_value_} is not a valid ATS-3 serial number.',
});
extend('ats3_part_number', {
    validate(value) {
        const regexIp = new RegExp(REGEX_ATS3_PART_NUMBER);

        return regexIp.test(value);
    },
    message: '{_value_} is not a valid ATS-3 part number.',
});
extend('ats4_serial_number', {
    validate(value) {
        const regexIp = new RegExp(REGEX_ATS4_SERIAL_NUMBER);

        return regexIp.test(value);
    },
    message: '{_value_} is not a valid ATS-4 serial number.',
});
extend('ats4_part_number', {
    validate(value) {
        const regexIp = new RegExp(REGEX_ATS4_PART_NUMBER);

        return regexIp.test(value);
    },
    message: '{_value_} is not a valid ATS-4 part number.',
});

extend('atm1_serial_number', {
    validate(value) {
        const regexIp = new RegExp(REGEX_ATM1_SERIAL_NUMBER);

        return regexIp.test(value);
    },
    message: '{_value_} is not a valid ATM-1 serial number.',
});
extend('atm1_part_number', {
    validate(value) {
        const regexIp = new RegExp(REGEX_ATM1_PART_NUMBER);

        return regexIp.test(value);
    },
    message: '{_value_} is not a valid ATM-1 part number.',
});
extend('atm2_serial_number', {
    validate(value) {
        const regexIp = new RegExp(REGEX_ATM2_SERIAL_NUMBER);

        return regexIp.test(value);
    },
    message: '{_value_} is not a valid ATM-2 serial number.',
});
extend('atm2_part_number', {
    validate(value) {
        const regexIp = new RegExp(REGEX_ATM2_PART_NUMBER);

        return regexIp.test(value);
    },
    message: '{_value_} is not a valid ATM-2 part number.',
});
extend('atm4_serial_number', {
    validate(value) {
        const regexIp = new RegExp(REGEX_ATM4_SERIAL_NUMBER);

        return regexIp.test(value);
    },
    message: '{_value_} is not a valid ATM-4 serial number.',
});
extend('atm4_part_number', {
    validate(value) {
        const regexIp = new RegExp(REGEX_ATM4_PART_NUMBER);

        return regexIp.test(value);
    },
    message: '{_value_} is not a valid ATM-4 part number.',
});
extend('atm5_serial_number', {
    validate(value) {
        const regexIp = new RegExp(REGEX_ATM5_SERIAL_NUMBER);

        return regexIp.test(value);
    },
    message: '{_value_} is not a valid ATM-5 serial number.',
});
extend('atm5_part_number', {
    validate(value) {
        const regexIp = new RegExp(REGEX_ATM5_PART_NUMBER);

        return regexIp.test(value);
    },
    message: '{_value_} is not a valid ATM-5 part number.',
});
extend('atm6_serial_number', {
    validate(value) {
        const regexIp = new RegExp(REGEX_ATM6_SERIAL_NUMBER);

        return regexIp.test(value);
    },
    message: '{_value_} is not a valid ATM-6 serial number.',
});
extend('atm6_part_number', {
    validate(value) {
        const regexIp = new RegExp(REGEX_ATM6_PART_NUMBER);

        return regexIp.test(value);
    },
    message: '{_value_} is not a valid ATM-6 part number.',
});
extend('project_id', {
    validate(value) {
        const regexIp = new RegExp(REGEX_PROJECT_ID);

        return regexIp.test(value);
    },
    message: '{_value_} is not a valid project ID.',
});
extend('orangepi_cpu_id', {
    validate(value) {
        const regexIp = new RegExp(REGEX_ORANGEPI_CPU_ID);

        return regexIp.test(value);
    },
    message: '{_value_} is not a valid OrangePi CPU ID.',
});
extend('certs_scada_pkcs12_cert_name', {
    params: ['scadaId'],
    validate(value, { scadaId }) {
        if (scadaId == null) return true;
        const { name } = value;

        return name === `cert-nol-${scadaId}.p12`;
    },
    message: "Unexpected Enercon SCADA PKCS-12 certificate store file name. The file name is expected to be like 'cert-nol-<SCADA-ID>.p12'.",
});
extend('certs_scada_comm_ca_cert_name', {
    validate(value) {
        const { name } = value;

        return name === CERTS_SCADA_COMM_CA_CERT_FILE_NAME;
    },
    message: `Unexpected Enercon Comm CA cert file name. The file name is expected to be '${CERTS_SCADA_COMM_CA_CERT_FILE_NAME}'.`,
});
extend('certs_scada_scada_ca_cert_name', {
    validate(value) {
        const { name } = value;

        return name === CERTS_SCADA_SCADA_CA_CERT_FILE_NAME;
    },
    message: `Unexpected Enercon SCADA CA cert file name. The file name is expected to be '${CERTS_SCADA_SCADA_CA_CERT_FILE_NAME}'.`,
});
