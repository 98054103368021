import checkTypes from 'check-types';
const META_CACHE_TTL = 300 * 1000;
const DEFAULT_USER_METADATA = { uid: 'bnk-portal-user', displayName: 'BNK Portal User' };
const SYSTEM_USERS = ['bnk-portal-system'];

export const state = () => ({
    usersMetadata: new Map(),
    loadUsersMetadataPromise: new Map(),
});

export const mutations = {
    clear(state) {
        state.usersMetadata = new Map();
        state.loadUsersMetadataPromise = new Map();
    },
    setUserMetadata(state, value) {
        checkTypes.assert.nonEmptyObject(value);
        const { uid } = value;
        state.usersMetadata.set(uid, value);
    },
    addLoadUsersMetadataPromise(state, value) {
        checkTypes.assert.nonEmptyObject(value);
        const { uid, promise, expireAt } = value;
        state.loadUsersMetadataPromise.set(uid, { promise, expireAt });
    },
    removeLoadUsersMetadataPromise(state, value) {
        checkTypes.assert.nonEmptyString(value);
        state.loadUsersMetadataPromise.delete(value);
    },
    removeExpiredMetadata(state) {
        // remove expire stuff
        const storedPromises = state.loadUsersMetadataPromise.entries();
        for (const [index, promise] of storedPromises) {
            const { expireAt } = promise;
            if (expireAt < Date.now()) {
                // remove expired entry
                this.$store.commit('removeLoadUsersMetadataPromise', index);
            }
        }
    },
};

export const actions = {
    /**
     * Loads user metadata from backend. Gracefully handles multiple requests for a single UID. Caches response data
     * for a specific duration to not reduce calls to backend API.
     */
    async loadPublicMetadata({ commit, state }, uid) {
        if (uid == null) return DEFAULT_USER_METADATA;

        // hande system users
        if (SYSTEM_USERS.includes(uid.toLowerCase())) {
            return {
                uid,
                displayName: 'BNK Portal System',
                name: 'BNK Portal System',
            };
        }

        // use cached metadata if exists
        const cachedUser = state.usersMetadata.get(uid);
        if (cachedUser != null && cachedUser.expireAt > Date.now()) {
            return cachedUser;
        }

        if (state.loadUsersMetadataPromise.has(uid)) {
            return state.loadUsersMetadataPromise.get(uid);
        }

        const loadUserMetadataPromise = this.$axios
            .$get(`/api/iam/users/profile/public/${uid}`)
            .then((user) => {
                //  cache user metadata so we don't have to load it when requested again
                state.usersMetadata.set(uid, { ...user, ...{ expireAt: Date.now() + META_CACHE_TTL } });

                return user;
            })
            .catch((e) => {
                // IMPORTANT for ease of use and to not interrupt the UI, always return default user in case of an errpr
                return DEFAULT_USER_METADATA;
            })
            .finally(() => {
                // remove the resolved promise from the loading promise list
                state.loadUsersMetadataPromise.delete(uid);
            });

        // add unresolved promise to list -> this way only a single request (i.e. promise) will be kept for each user
        state.loadUsersMetadataPromise.set(uid, loadUserMetadataPromise);

        return loadUserMetadataPromise;
    },
};
