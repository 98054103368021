import checkTypes from 'check-types';

export const state = () => ({
    project: null,
});

export const mutations = {
    clear(state) {
        state.project = null;
    },
    setProject(state, value) {
        if (value) checkTypes.assert.nonEmptyObject(value);
        state.project = value;
    },
};

export const actions = {
    async loadProject({ commit }, id) {
        checkTypes.assert.nonEmptyString(id);

        const project = await this.$axios.$get(`/api/operating/projects/${id}`);
        commit('setProject', project);
    },
};
