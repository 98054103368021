import { DateTime } from 'luxon';
import { DATE_FORMAT } from '@/globals';
import * as valueTransform from '@/helpers/value-transform';

export const _state = {
    id: null,
    kind: null,
    readOnly: false,
    serialNumber: null,
    partNumber: null,
    builderName: null,
    buildDate: new Date().toISOString().substring(0, 10),
    housing: null,
    routerType: null,
    routerSerial: null,
    sdCard: null,
    macEther1: null,
    macEther2: null,
    macEther3: null,
    modemType: null,
    modemSerialNumber: null,
    modemImei: null,
    simOperator: null,
    simIccid: null,
    simIp: null,
    simUsesPrivateApn: false,
    vpnIp: null,
    parkNetIp: null,
    baseBoard: null,
    uflCable: null,
    remarks: null,
};

export const _mutations = {
    clear(state) {
        state.id = null;
        state.kind = null;
        state.readOnly = false;
        state.serialNumber = null;
        state.partNumber = null;
        state.builderName = null;
        state.buildDate = new Date().toISOString().substring(0, 10);
        state.housing = null;
        state.routerType = null;
        state.routerSerial = null;
        state.sdCard = null;
        state.macEther1 = null;
        state.macEther2 = null;
        state.macEther3 = null;
        state.modemType = null;
        state.modemSerialNumber = null;
        state.modemImei = null;
        state.simOperator = null;
        state.simIccid = null;
        state.simIp = null;
        state.simUsesPrivateApn = false;
        state.vpnIp = null;
        state.parkNetIp = null;
        state.baseBoard = null;
        state.uflCable = null;
        state.remarks = null;
    },
    setId(state, value) {
        state.id = valueTransform.toString(value, { nullable: false });
    },
    setKind(state, value) {
        state.kind = valueTransform.toString(value, { nullable: false });
    },
    setReadOnly(state, value) {
        state.readOnly = valueTransform.toBoolean(value);
    },
    setBuilderName(state, value) {
        state.builderName = valueTransform.toString(value);
    },
    setBuildDate(state, value) {
        state.buildDate = valueTransform.toString(value);
    },
    setSerialNumber(state, value) {
        state.serialNumber = valueTransform.toString(value, {
            postProcess: (value) => {
                return value.toUpperCase();
            },
        });
    },
    setPartNumber(state, value) {
        state.partNumber = valueTransform.toString(value, {
            postProcess: (value) => {
                return value.toUpperCase();
            },
        });
    },
    setHousing(state, value) {
        state.housing = valueTransform.toString(value);
    },
    setRouterType(state, value) {
        state.routerType = valueTransform.toString(value);
    },
    setRouterSerial(state, value) {
        state.routerSerial = valueTransform.toString(value);
    },
    setSdCard(state, value) {
        state.sdCard = valueTransform.toString(value);
    },
    setMacEther1(state, value) {
        state.macEther1 = valueTransform.toString(value);
    },
    setMacEther2(state, value) {
        state.macEther2 = valueTransform.toString(value);
    },
    setMacEther3(state, value) {
        state.macEther3 = valueTransform.toString(value);
    },
    setModemType(state, value) {
        state.modemType = valueTransform.toString(value);
    },
    setModemSerialNumber(state, value) {
        state.modemSerialNumber = valueTransform.toString(value);
    },
    setModemImei(state, value) {
        state.modemImei = valueTransform.toString(value);
    },
    setSimOperator(state, value) {
        state.simOperator = valueTransform.toString(value);
    },
    setSimIccid(state, value) {
        state.simIccid = valueTransform.toString(value);
    },
    setSimIp(state, value) {
        state.simIp = valueTransform.toString(value);
    },
    setSimUsesPrivateApn(state, value) {
        state.simUsesPrivateApn = valueTransform.toBoolean(value);
    },
    setVpnIp(state, value) {
        state.vpnIp = valueTransform.toString(value);
    },
    setParkNetIp(state, value) {
        state.parkNetIp = valueTransform.toString(value);
    },
    setBaseBoard(state, value) {
        state.baseBoard = valueTransform.toString(value);
    },
    setUflCable(state, value) {
        state.uflCable = valueTransform.toString(value);
    },
    setRemarks(state, value) {
        state.remarks = valueTransform.toString(value);
    },
};

export function _denormalize(state) {
    // IMPORTANT  field "simUsesPrivateApn" must be read-only in UI
    return {
        serialNumber: state.serialNumber,
        partNumber: state.partNumber,
        builderName: state.builderName,
        buildDate: state.buildDate ? DateTime.fromFormat(state.buildDate, DATE_FORMAT, { zone: 'UTC' }).toJSDate().toISOString() : null,
        housing: state.housing,
        routerType: state.routerType,
        routerSerial: state.routerSerial,
        sdCard: state.sdCard,
        macEther1: state.macEther1,
        macEther2: state.macEther2,
        macEther3: state.macEther3,
        modemType: state.modemType,
        modemSerialNumber: state.modemSerialNumber,
        modemImei: state.modemImei,
        simOperator: state.simOperator,
        simIccid: state.simIccid,
        simIp: state.simIp,
        simUsesPrivateApn: state.simUsesPrivateApn,
        vpnIp: state.vpnIp,
        parkNetIp: state.parkNetIp,
        baseBoard: state.baseBoard,
        uflCable: state.uflCable,
        remarks: state.remarks,
    };
}
