import checkTypes from 'check-types';
import { RolesEnum } from '@/helpers/choices/roles-enum';
import { roles } from '@/rbac';
import DevLogger from '@/helpers/dev-logger';
import { PERSIST_KEY } from '@/globals';

const STORAGE_PERSIST_KEY = `${PERSIST_KEY}.authUser`;

/**
 * @typedef UserMetadata
 *
 * @property {string} uid
 * @property {string} email
 * @property {boolean} emailVerified
 * @property {string[]} roles
 * @property {string[]} permissions
 * @property {string} [token]
 * @property {string} [displayName]
 * @property {Object} [businessEntity]
 */

/**
 * Wraps user authentication metadata for easier access and permission checks. User metadata is persisted in localStorage
 * to persist during page reloads.
 */
export default class AuthUser {
    /**
     * @param {UserMetadata} userMetadata
     */
    constructor(userMetadata) {
        const { uid, email, emailVerified, roles, permissions, displayName, businessEntity, token } = userMetadata || {};

        // enforce required parameters are set
        checkTypes.assert.nonEmptyString(uid);
        checkTypes.assert.nonEmptyString(email);
        checkTypes.assert.boolean(emailVerified);
        checkTypes.assert.array.of.nonEmptyString(roles);
        checkTypes.assert.array.of.nonEmptyString(permissions);
        // check optional parameters
        if (displayName) checkTypes.assert.nonEmptyString(displayName);
        if (businessEntity) checkTypes.assert.nonEmptyObject(businessEntity);
        if (token) checkTypes.assert.nonEmptyString(token);

        // required
        this.uid = uid;
        this.email = email;
        this.emailVerified = emailVerified;
        this.displayName = displayName;
        this.roles = roles;
        this.permissions = permissions;
        // optional
        this.businessEntity = businessEntity ?? null;
        // if token is not set, user is considered to be "not logged in"
        this.token = token;
    }

    /**
     * Persists the current AuthUser instance to local storage to persist during page reloads.
     */
    persistToStorage() {
        const { uid, email, emailVerified, roles, permissions, businessEntity, displayName, token } = this;
        window.localStorage.setItem(
            STORAGE_PERSIST_KEY,
            JSON.stringify({
                uid,
                email,
                emailVerified,
                roles,
                permissions,
                displayName,
                businessEntity,
                token,
            })
        );
    }

    /**
     * Must be called when user logs out. This will remove the auth user metadata from localStorage.
     */
    static logout() {
        window.localStorage.removeItem(STORAGE_PERSIST_KEY);
    }

    /**
     * Will ALWAYS return either a AuthUser instance or unprivileged user if not possible to initialize.
     *
     * @returns {AuthUser}
     */
    static loadFromStorage() {
        try {
            const userMetadata = JSON.parse(window.localStorage.getItem(STORAGE_PERSIST_KEY));
            return new AuthUser(userMetadata);
        } catch (e) {
            DevLogger.error('Failed to initialize AuthUser. User is not logged in. Fallback to default user.');

            return new AuthUser({
                uid: 'default',
                email: 'defaut-user@lanthan-safe-sky.com',
                emailVerified: false,
                roles: [],
                permissions: [],
            });
        }
    }

    /**
     * @returns {boolean}
     */
    isLoggedIn() {
        return this.token != null;
    }

    /**
     * @returns {string}
     */
    getUid() {
        return this.uid;
    }

    /**
     * @returns {string}
     */
    getDisplayName() {
        // essentially this is the email
        return this.displayName == null ? this.email : this.displayName;
    }

    /**
     * @returns {string}
     */
    getEmail() {
        // essentially this is the email
        return this.email;
    }

    getEmailVerified() {
        // essentially this is the email
        return this.emailVerified;
    }

    getBusinessEntity() {
        return this.businessEntity;
    }

    getRoles() {
        return this.roles;
    }

    setRoles(roles) {
        this.roles = roles;

        return this;
    }

    /**
     * Returns true if the current user has AT LEAST one of the requested
     * roles assigned.
     *
     * @param {string|string[]} roles
     * @returns {boolean}
     */
    hasRole(roles) {
        // takes either a string or an array of strings but operates on array
        const allowedRoles = Array.isArray(roles) ? roles : [roles];
        // get roles from access token
        const userRoles = this.roles;
        if (!userRoles) {
            return false;
        }

        let hasAtLeastOneRole = false;
        userRoles.forEach((value) => {
            if (allowedRoles.includes(value)) hasAtLeastOneRole = true;
        });

        return hasAtLeastOneRole;
    }

    /**
     * Returns a list of human readable names instead of the default const values
     * for viewing purposes.
     *
     * @param {string} separator - Separator used to separate the names
     * @returns {string}
     */
    getRolesList(separator = ', ') {
        const roles = this.roles;
        const roleNames = roles.map((value) => RolesEnum.getName(value));

        return roleNames.join(separator);
    }

    /**
     * @return {string[]}
     */
    getPermissions() {
        return this.permissions;
    }

    /**
     * @param {string[]} permissions
     * @return {AuthUser}
     */
    setPermissions(permissions) {
        checkTypes.assert.array(permissions);

        this.permissions = permissions;

        return this;
    }

    /**
     * Checks if the current user has the requested permissions. To satisfy requested permission
     * grants, the current must have at least ONE of the defined permissions. If requireAll is set to true,
     * the user must have ALL defined permissions!
     *
     * @param {string|string[]} reqPermissions
     * @param {boolean} requireAll - If true, user must have ALL defined permissions
     * @returns {boolean}
     */
    hasPermission(reqPermissions, requireAll = false) {
        // takes either a string or an array of strings but operates on array
        const requiredPermissions = Array.isArray(reqPermissions) ? reqPermissions : [reqPermissions];

        const required = requiredPermissions.length;
        let granted = 0;

        // no required permissions => directly return true
        if (required === 0) return true;

        let userPermissions = this.permissions;
        requiredPermissions.forEach((value) => {
            if (userPermissions.includes(value)) granted++;
        });

        return requireAll ? granted === required : granted >= 1;
    }

    isAdmin() {
        return this.hasRole(roles.ADMINISTRATOR);
    }

    isPrivileged() {
        return this.hasRole([roles.ADMINISTRATOR, roles.BNK_PORTAL_CONTENT_ADMIN, roles.BNK_PORTAL_EDITOR, roles.BNK_PORTAL_VIEWER]);
    }

    /**
     * @param {string} token
     */
    updateToken(token) {
        this.token = token;
    }

    getToken() {
        return this.token;
    }

    toJSON() {
        return {
            uid: this.uid,
            email: this.email,
            emailVerified: this.emailVerified,
            roles: this.roles,
            permissions: this.permissions,
            businessEntity: this.businessEntity,
            displayName: this.displayName,
            token: this.token,
        };
    }
}
