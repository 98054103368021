import * as valueTransform from '@/helpers/value-transform';
import checkTypes from 'check-types';

export const state = () => ({
    selectedStyle: 'monochromatic',
    enableDem: false,
    enableHillshade: false,
    toggleFeatures: [],
});

export const mutations = {
    clear(state) {
        state.selectedStyle = 'monochromatic';
        state.enableDem = false;
        state.enableHillshade = false;
        state.toggleFeatures = [];
    },
    setSelectedStyle(state, value) {
        state.selectedStyle = valueTransform.toString(value);
    },
    setEnableDem(state, value) {
        state.enableDem = valueTransform.toBoolean(value);
    },
    setEnableHillshade(state, value) {
        state.enableHillshade = valueTransform.toBoolean(value);
    },
    setToggleFeatures(state, value) {
        checkTypes.assert.array(value);
        state.toggleFeatures = value;
    },
};
