import checkTypes from 'check-types';
import { isEmpty } from 'lodash';

export const state = () => ({
    atsInstallation: {},
    mobileCoverage: {},
});

export const mutations = {
    clear(state) {
        state.atsInstallation = {};
    },
    setAtsInstallation(state, value) {
        checkTypes.assert.nonEmptyObject(value);
        state.atsInstallation = value;
    },
    setMobileCoverage(state, value) {
        if (value != null) checkTypes.assert.nonEmptyObject(value);
        state.mobileCoverage = value;
    },
};

export const actions = {
    async load({ commit }, id) {
        checkTypes.assert.nonEmptyString(id);

        const atsInstallation = await this.$axios.$get(`/api/operating/ats-installations/${id}`, {
            params: { populateRefs: true },
        });

        const [lon, lat] = atsInstallation.geometry.coordinates;
        const mobileCoverage = await this.$axios.$get(`/api/operating/utils/mobile-coverage`, {
            params: {
                coordinates: `${lat},${lon}`,
            },
        });

        commit('setAtsInstallation', atsInstallation);
        commit('setMobileCoverage', isEmpty(mobileCoverage) ? null : mobileCoverage);
    },
    async delete({ state }, id) {
        await this.$axios.$delete(`/api/operating/ats-installations/${id}`);
    },
};
