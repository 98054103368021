import { ManufacturerEnum } from '@/helpers/choices/wind-turbine/manufacturer-enum';

/**
 * @param {{id: string, manufacturer: string, serialNumber: string, operatorIdent: string}} meta
 * @return {string|null}
 */

export default function getDraftWindTurbineName(meta) {
    const { id, manufacturer, serialNumber, operatorIdent, importId } = meta || {};

    let manufacturerName = ManufacturerEnum.getName(manufacturer);
    let name = 'Unknown';

    if (manufacturerName != null && serialNumber != null) {
        name = manufacturerName + '-' + serialNumber + ' (' + importId + ')';
    } else if (operatorIdent) {
        name = operatorIdent + ' (' + importId + ')';
    } else if (id) {
        name = id.substring(0, 6) + ' (' + importId + ')';
    }

    return name;
}
