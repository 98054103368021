import checkTypes from 'check-types';

export const state = () => ({
    sourceMeta: {},
    items: new Map(),
});

export const getters = {
    getItems: (state) => {
        // IMPORTANT make sure to return an array calling values which will only return list of values and not a "keyed valued"
        return Array.from(state.items.values());
    },
};

export const mutations = {
    clear(state) {
        state.sourceMeta = {};
        state.items = new Map();
    },
    setItems(state, value) {
        value = value || [];
        state.items = new Map();
        value.forEach(function (item) {
            state.items.set(item._id, item);
        });
        // IMPORTANT change state by setting new Map since simply calling "set" directly won't propagate state change
        state.items = new Map(state.items.entries());
    },
    removeItem(state, value) {
        checkTypes.assert.nonEmptyString(value);
        state.items.delete(value);
        // IMPORTANT change state by setting new Map since simply calling "set" directly won't propagate state change
        state.items = new Map(state.items.entries());
    },
    setSourceMeta(state, value) {
        checkTypes.assert.nonEmptyObject(value);
        state.sourceMeta = value;
    },
};
