import { Enum } from '@/helpers/choices/enum';

export class IpInterfaceTypeEnum extends Enum {}

IpInterfaceTypeEnum.values = {
    ENERCON_SCADA: 0,
    QUANTEC_MODBUS: 1,
    ENERTRAG_ALI_MODBUS: 2,
    REETEC_MODBUS: 3,
    VESTAS_MODBUS: 4,
    SIEMENS_SALC: 5,
    QUANTEC_CENTRAL_MODBUS: 6,
    ORGA_AEI402_MODBUS: 7,
    ORGA_CIP400_MODBUS: 8,
    NORDEX_LCU: 9,
    VESTAS_LCU: 10,
    SABIK_PMS: 11,
    LANTHAN_OLS_MODBUS: 12,
    OBELUX_COMV3_MODBUS: 13,
    DWT_MODBUS: 14,
    ATS_MODBUS_TCP_SERVER: 15,
};

IpInterfaceTypeEnum.names = {
    0: 'Enercon SCADA',
    1: 'Quantec Modbus/TCP',
    2: 'Enertrag ALI Modbus/TCP',
    3: 'Reetec Modbus/TCP',
    4: 'Vestas Modbus/TCP',
    5: 'Siemens SALC',
    6: 'Quantec Central Modbus/TCP',
    7: 'Orga AEI402 Modbus',
    8: 'Orga CIP400 Modbus',
    9: 'Nordex LCU',
    10: 'Vestas LCU',
    11: 'Sabik PMS',
    12: 'Lanthan OLS Modbus',
    13: 'Obelux COMv3 Modbus',
    14: 'DWT Modbus',
    15: 'ATS Modbus/TCP Server',
};

Object.freeze(IpInterfaceTypeEnum);
